import { getCMSPages } from "api/cmsApi";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";

const Cmspage = (props) => {
  const [pageData, setPageData] = useState(null);
  const params = useParams();
  useEffect(() => {
    var myParam = window.location.search.split('pageType=')[1]
    let data = {pageType:myParam};
    getCMSPages(data)
      .then((res) => {
        if (res.data.success) {
          setPageData(res.data.data);
        } else {
          console.log(res?.data?.message, "");
        }
      })
      .catch((err) => {
        console.log(err?.message, "");
      });
  }, []);

  return (
    <>
    <style
    dangerouslySetInnerHTML={{ __html: "\nbody {\n  height:auto;\n}\n" }}
  />
      <main className="content-row mt-5 ">
        <div className="container">
          <div className="row">
            <center>
              <h1 style={{ fontSize: "30px" }}>{pageData?.name}</h1>
            </center>
          </div>
          <hr />
          <div className="row">
            <div
              dangerouslySetInnerHTML={{ __html: pageData?.content }}
            />
          </div>
        </div>
      </main>
    </>
  );
};

export default Cmspage;
