import React from "react";

const FooterLink = ({ title, image, link, handleClick }) => {
  return (
    <li>
      <a href={'javascript:void(0)'} className="me-2" onClick={()=> handleClick()} data-bs-target="#privacyPolicyModal"
                        data-bs-toggle="modal">
        <img src={image} alt="dot" width="" height=""/>
        {title}
      </a>
    </li>
  );
};

export default FooterLink;
