import { isUserLoggedIn } from "helpers/common_helper";
import config from "../configurations/config";
import { getMethodCall, postMethodCall } from "./apiHandler";

export const loginApi = async (payload) => {
  return await postMethodCall(`${config.backendApiUrl}/api/user/login`, payload);
};

export const registerApi = async (payload) => {
  return await postMethodCall(`${config.backendApiUrl}/api/user/register`, payload);
};

export const forgotPasswordApi = async (payload) => {
  return await postMethodCall(`${config.backendApiUrl}/api/user/forgot-password`, payload);
};

export const verifyTokenApi = async (payload) => {
  return await postMethodCall(`${config.backendApiUrl}/api/user/verify-otp`, payload);
};

export const resetPasswordApi = async (payload) => {
  return await postMethodCall(`${config.backendApiUrl}/api/user/send-password-email`, payload);
};

export const getAccessTokenApi = async (payload) => {
  if(isUserLoggedIn()){
    return await getMethodCall(`${config.backendApiUrl}/api/user/get-access-token`, payload);
  }
  return await getMethodCall(`${config.backendApiUrl}/api/user/open/get-access-token`, payload);
};

export const changePasswordApi = async (payload) => {
  return await postMethodCall(`${config.backendApiUrl}/api/user/change-password`, payload);
};

export const verifyEmailApi = async (payload) => {
  return await getMethodCall(`${config.backendApiUrl}/api/user/verify-email/${payload.token}`, payload);
};

export const resendVerificationEmailApi = async (payload) => {
  return await postMethodCall(`${config.backendApiUrl}/api/user/resend-verification-email`, payload);
};