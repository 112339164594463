import Layout from "layouts/Index";
import React, { useEffect, useState } from "react";
import ProfileHeader from "./ProfileHeader";
import ProfileInfo from "../../components/ui/Common/ProfileInfo";
import SidebarMenu from "../../layouts/SidebarMenu";
import CategoryTab from "./CategoryTab";
import FilterIcon from "../../assets/images/filter.png";
import SessionCard from "./SessionCard";
import InterestModal from "./Modal/InterestModel";
import FilterModal from "./Modal/FilterModal";
import EditProfileModal from "./Modal/EditProfileModal";
import SessionDetailModal from "./Modal/SessionDetailModal";
import CreateSessionModal from "./Modal/CreateSessionModal";
import RequestSessionModal from "./RequestSessionModal";
import { getCategoriesApi } from "api/categoryApi";
import { getSessionsApi } from "api/sessionApi";
import moment from "moment";
import { connect } from "react-redux";
import { fetchProfile } from "actions/profileActions";
import InfiniteScroll from "react-infinite-scroll-component";
import { UTCDateFormat } from "helpers/common_helper";
import { UTCTimeFormat } from "helpers/common_helper";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { isUserLoggedIn } from "helpers/common_helper";
import AvatarImage from "../../assets/images/profile-placeholder.png";
import { logEvent } from "firebase/analytics";
import analytics from "index";
import NoDataImage from "../../assets/images/nodatafound.gif";
import SessionCardV2 from "./SessionCardV2";
import { useMediaQuery } from "react-responsive";
import { formatGlobalDateTime } from "helpers/common_helper";
import { getSystemTimezoneAbbr } from "helpers/common_helper";
import SessionDetailModalV2 from "./Modal/SessionDetailModalV2";

const Home = ({
  profile,
  error,
  fetchProfile,
  searchValue,
  isSearchValueSubmitted,
  filterValues,
  isFormSubmitted,
}) => {
  const params = useParams();
  const triggerId = params?.session_id;
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [sessionsList, setSessionsList] = useState([]);
  const [selectedSession, setSelectedSession] = useState(0);
  const [isAllActive, setIsAllActive] = useState(true);
  const [activeData, setActiveData] = useState({
    page: 1,
    limit: 10,
    search: "",
  });
  const [loading, setLoading] = useState(false);

  const isDashboardApiTriggered = useSelector(
    (state) => state.dashboard.isDashboardApiTriggered
  );

  const isLoggedIn = useSelector((state) => state.auth.isLogged);

  const isMobileView = useMediaQuery({ query: "(max-width: 767px)" });

  let userLanguages = profile?.UserLanguage?.map((item) => item.id);


  const [activeSessionList, setActiveSessionList] = useState({
    category: 1,
    page: 1,
    limit: 10,
    search: "",
    filter: {
      languageFilter: "",
      categoryFilter: "",
      start_date: "",
      end_date: "",
    },
  });

  const profileTopList = [
    {
      title: "Sessions",
      value: profile?.sessionCount,
    },
    {
      title: "Followers",
      value: profile?.followerCount,
    },
    {
      title: "Following",
      value: profile?.followingCount,
    },
  ];

  const profileTopListUnknown = [
    {
      title: "Sessions",
      value: 0,
    },
    {
      title: "Followers",
      value: 0,
    },
    {
      title: "Following",
      value: 0,
    },
  ];

  const getCategoryList = (data) => {
    getCategoriesApi(data)
      .then((res) => {
        setCategoryList(res?.data?.data);
        setActiveSessionList({
          ...activeSessionList,
          category: res?.data?.data?.rows[0]?.id,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllSessionsList = (data) => {
    if (activeSessionList.limit <= 10) {
      setLoading(true);
    }

    getSessionsApi(data)
      .then((res) => {
        setSessionsList(res?.data?.data?.sessions);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCategoryChange = (id) => {
    if (activeSessionList.filter.categoryFilter.includes(id)) {
      setActiveSessionList({
        ...activeSessionList,
        filter: {
          ...activeSessionList.filter,
          categoryFilter: activeSessionList.filter.categoryFilter.filter(
            (categoryId) => categoryId !== id
          ),
        },
      });
    } else {
      setActiveSessionList({
        ...activeSessionList,
        filter: {
          ...activeSessionList.filter,
          categoryFilter: [...activeSessionList.filter.categoryFilter, id],
        },
      });
    }

    const index = selectedCategory.indexOf(id);
    if (index !== -1) {
      const updatedSelectedCategories = [...selectedCategory];
      updatedSelectedCategories.splice(index, 1);
      setSelectedCategory(updatedSelectedCategories);
    } else {
      setSelectedCategory([...selectedCategory, id]);
    }
    getAllSessionsList(activeSessionList);
    setIsAllActive(false);
  };

  useEffect(() => {
    getCategoryList(activeData);
    // getAllSessionsList(activeSessionList);
    fetchProfile();
    setActiveSessionList({
      ...activeSessionList,
      filter: {
        ...activeSessionList.filter,
        languageFilter: userLanguages,
      },
    });

    
    logEvent(analytics, "dashboard_opened");
  }, []);

  useEffect(() => {
    getAllSessionsList(activeSessionList);
  }, [activeSessionList, selectedCategory, isDashboardApiTriggered]);

  const handleSessionClick = (sessionId) => {
    setSelectedSession(sessionId);
  };

  const handleFetchMoreData = () => {
    setActiveSessionList({
      ...activeSessionList,
      limit: activeSessionList.limit + 6,
    });
  };

  const handleClearCategory = () => {
    setSelectedCategory([]);
    setActiveSessionList({
      ...activeSessionList,
      filter: {
        ...activeSessionList.filter,
        categoryFilter: [],
      },
    });
    setIsAllActive(true);
  };

  useEffect(() => {
    if (isSearchValueSubmitted) {
      setActiveSessionList({
        ...activeSessionList,
        search: searchValue,
      });
    } else if (isFormSubmitted) {
      setActiveSessionList({
        ...activeSessionList,
        filter: {
          categoryFilter: filterValues?.categoryFilter,
          languageFilter: filterValues?.languageFilter,
          start_date: filterValues?.start_date,
          end_date: filterValues?.end_date,
        },
      });
    } else if (!isFormSubmitted) {
      setActiveSessionList({
        ...activeSessionList,
        filter: {
          categoryFilter: [],
          languageFilter: "",
          start_date: "",
          end_date: "",
        },
      });
    }
  }, [
    isSearchValueSubmitted,
    searchValue,
    isFormSubmitted,
    filterValues,
    isDashboardApiTriggered,
  ]);

  


  return (
    <>
      <Layout>
        <section className={`${isMobileView ? "main" : "main mt-5 py-5"}`}>
          <div className="container-fluid container-fluid-p">
            <div className="row">
              {!isMobileView && (
                <div
                className={`col-md-2 pt-5 left-block sticky-sidebar ${
                  isUserLoggedIn() ? "" : "locked"
                }`}
              >
                <SidebarMenu />
              </div>
              )}
              
              <div className="col-md-10 pl-25 right-block">
                <div className="filter-custom mb-4">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CategoryTab
                      categories={categoryList}
                      handleCategoryChange={handleCategoryChange}
                      selectedCategory={selectedCategory}
                      handleClearCategory={handleClearCategory}
                      isAllActive={isAllActive}
                    />
                  </div>

                  {!isMobileView && (
                    <div>
                    <ul className="filter-icon-custom">
                      <li>
                        <a
                          href="!#"
                          data-bs-target="#filterModal"
                          data-bs-toggle="modal"
                        >
                          <img
                            src={FilterIcon}
                            alt="filter-icon"
                            width=""
                            height=""
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                  )}

                  
                </div>

                <div className="content-list">
                  <InfiniteScroll
                    dataLength={sessionsList?.length}
                    next={handleFetchMoreData}
                    hasMore={true}
                  >
                    <div className="row">
                      {/* {loading ? (
                        <>
                          <div className="justify-content-center d-flex align-items-center">
                            <div className="loadingio-spinner-rolling-26k4myxj9b9">
                              <div className="ldio-y9y7x49yybs">
                                <div></div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {" "}
                          {sessionsList?.length === 0 ? (
                            <>
                              <div className="col-md-12 d-flex justify-content-center">
                                <div className="no-data">
                                  <img
                                    src={NoDataImage}
                                    alt="no-data"
                                    width=""
                                    height=""
                                  />
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {sessionsList?.map((session, index) => (
                                <SessionCard
                                  key={index}
                                  user_name={session?.user?.user_name}
                                  backgroundImage={session?.bannerImageUrl}
                                  userImage={session?.user?.profileImageUrl}
                                  userName={`${session?.user?.first_name} ${session?.user?.last_name}`}
                                  title={session?.title}
                                  time={`${UTCDateFormat(
                                    session?.start_date_time
                                  )} ${UTCTimeFormat(
                                    session?.start_date_time
                                  )}`}
                                  handleSessionClick={() =>
                                    handleSessionClick(session.id)
                                  }
                                  languages={
                                    session?.languages[0]?.languageName
                                  }
                                  sessionType={session?.sessionTypeName}
                                  sessionTypeColor={session?.sessionTypeColor}
                                />
                              ))}
                            </>
                          )}
                        </>
                      )} */}
                      {/* <SessionCardV2/> */}
                      {loading ? (
                        <>
                          <div className="justify-content-center d-flex align-items-center">
                            <div className="loadingio-spinner-rolling-26k4myxj9b9">
                              <div className="ldio-y9y7x49yybs">
                                <div></div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {sessionsList?.length === 0 ? (
                            <>
                              <div className="col-md-12 d-flex justify-content-center">
                                <div className="no-data">
                                  <img
                                    src={NoDataImage}
                                    alt="no-data"
                                    width=""
                                    height=""
                                  />
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {sessionsList?.map((session, index) => (
                                <SessionCardV2
                                  title={session?.title}
                                  // datetime={`${UTCDateFormat(
                                  //   session?.start_date_time
                                  // )} ${UTCTimeFormat(
                                  //   session?.start_date_time
                                  // )}`}
                                  datetime={formatGlobalDateTime(session?.start_date_time)}
                                  language={session?.languages[0]?.languageName}
                                  backgroundImage={session?.bannerImageUrl}
                                  userImage={session?.user?.profileImageUrl}
                                  userName={`${session?.user?.first_name} ${session?.user?.last_name}`}
                                  sessionType={session?.sessionTypeName}
                                  sessionVisibility={session?.type}
                                  
                                  user_name={session?.user?.user_name}
                                  session_id={session?.id}
                                  sessionTypeColor={session?.sessionTypeColor}
                                  participantLimit={session?.participate_limit}
                                  participantCount={session?.participant_count}
                                  timezoneAbbrevation={getSystemTimezoneAbbr()}
                                  
                                />
                              ))}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          </div>
        </section>
        <InterestModal />
        <FilterModal />
        <EditProfileModal />
        <SessionDetailModalV2 session_id={selectedSession} />
        {/* <CreateSessionModal /> */}
        <RequestSessionModal />
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile.profile,
    loading: state.profile.loading,
    error: state.profile.error,
    searchValue: state.search.searchValue,
    isSearchValueSubmitted: state.search.isSearchValueSubmitted,
    filterValues: state.filter,
    isFormSubmitted: state.filter.isFormSubmitted,
  };
};

const mapDispatchToProps = {
  fetchProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
