import React from 'react';

function HashtagList({ hashtags }) {
  return (
    <ul className="hashtags d-flex align-items-center justify-content-center my-4">
      {hashtags?.map((tag, index) => (
        <li key={index}>#{tag}</li>
      ))}
      {/* <li>{hashtags}</li> */}
    </ul>
  );
}

export default HashtagList;
