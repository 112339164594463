import React, { useEffect, useState } from "react";
import CloseIconImage from "../../../assets/images/icon_close.png";
import AvatarImage from "../../../assets/images/profile-placeholder.png";
import { getRequestSessionDetailApi } from "api/sessionApi";
import { sendTakeSessionStatusApi } from "api/sessionApi";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { UTCTimeFormat } from "helpers/common_helper";
import { connect } from 'react-redux';
import { takeSessionApiTrigger } from "actions/userRequestActions";
import { DualDateFormat } from "helpers/common_helper";
import { useDispatch } from "react-redux";
import { userRequestCountApiTrigger } from "actions/userRequestActions";

const TakeSessionModal = ({ sessionId,takeSessionApiTrigger }) => {
  const dispatch = useDispatch();
  const [takeSessionDetails, setTakeSessionDetails] = useState({});
  const [loading, setLoading] = useState(true);

  const getTakeSessionDetails = (sessionId) => {
    getRequestSessionDetailApi({ session_id: sessionId })
      .then((res) => {
        setTakeSessionDetails(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("getTakeSessionsApi", err);
      });
  };

  // const handleCreateSession = () => {
  //   const data = {
  //     title: takeSessionDetails?.title,
  //     date: takeSessionDetails?.start_date,
  //     start_time: takeSessionDetails?.start_time,
  //     end_time: takeSessionDetails?.end_time,
  //     timezone_id: takeSessionDetails?.timezone_id
  //       ? takeSessionDetails?.timezone_id
  //       : 1,
  //     participate_limit: takeSessionDetails?.participate_limit,
  //     meeting_link: takeSessionDetails?.meeting_link,
  //     about: takeSessionDetails?.description,
  //     banner_image: takeSessionDetails?.banner_image
  //       ? takeSessionDetails?.banner_image
  //       : null,
  //     languageIds: [takeSessionDetails?.language?.id],
  //     categoryIds: [takeSessionDetails?.category?.id],
  //   };

    
  // };


  const handleStatusUpdate = (status) => {
    let requestStatus;
    if (status === "MayBeLater") {
      requestStatus = "MayBeLater";
    } else if (status === "Ignored") {
      requestStatus = "Ignored";
    }

    sendTakeSessionStatusApi({ requestId: sessionId, status: requestStatus })
      .then((res) => {
        if (res?.data?.success) {
          toast.success(res?.data?.message, {
            position: "top-right",
              autoClose: 700,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light",
          });
          takeSessionApiTrigger();
          dispatch(userRequestCountApiTrigger(true));
          setTimeout(() => {
            window.$("#takeSession").modal("hide");
          }, 2000);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
              autoClose: 700,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light",
          });
        }
      })
      .catch((err) => {
        console.log("sendTakeSessionStatusApi", err);
      });
  };

  useEffect(() => {
    window.$("#takeSession").on("shown.bs.modal", function () {
      setLoading(true);
      getTakeSessionDetails(sessionId);
    });
  }, [sessionId]);

  return (
    <div className="modal fade newmodal" id="takeSession">
      <div className="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header text-center">
            <button type="button" className="btn-close" data-bs-dismiss="modal">
              <img src={CloseIconImage} alt="" width="" height="" />
            </button>
            <h4 className="text-center">Session Request Details</h4>
          </div>
          {loading ? (
            <>
              <div className="justify-content-center d-flex align-items-center">
                <div className="loadingio-spinner-rolling-26k4myxj9b9">
                  <div className="ldio-y9y7x49yybs">
                    <div></div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="modal-body px-5 py-5">
                <div className="sessin-info">
                  <div className="text-center mb-5">
                    <img
                      src={
                        takeSessionDetails?.fromUser?.profileImageUrl === null
                          ? AvatarImage
                          : takeSessionDetails?.fromUser?.profileImageUrl
                      }
                      className="author-img rounded-circle img-property-fit"
                      alt="userimage"
                      width="50px"
                      height="50px"
                      
                    />
                    <h5 className="mb-0">{`${takeSessionDetails?.fromUser?.first_name} ${takeSessionDetails?.fromUser?.last_name}`}</h5>
                    {/* <span className="desig">None</span> */}
                  </div>
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <div className="bg-gray">
                        <span>Session Title</span>
                        <p className="mb-0">{takeSessionDetails?.title}</p>
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <div className="bg-gray">
                        <span>Language for the session</span>
                        <p className="mb-0">
                          {takeSessionDetails?.language?.name}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="bg-gray">
                        <span>Preffered date</span>
                        <p className="mb-0">
                          {`${DualDateFormat(takeSessionDetails?.start_date)} to ${DualDateFormat(takeSessionDetails?.optional_date)}`}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="bg-gray">
                        <span>Preffered Time</span>
                        <p className="mb-0">
                          {`${UTCTimeFormat(
                            takeSessionDetails?.start_date_time
                          )} to ${UTCTimeFormat(
                            takeSessionDetails?.end_date_time
                          )}`}
                        </p>
                      </div>
                    </div>
                  
                    <div className="col-md-12">
                      <div className="bg-gray">
                        <span>Session Details</span>
                        <p className="mb-0">
                          {takeSessionDetails?.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <div className="col-md-12 mt-2">
                  <div className="d-flex align-items-center justify-content-center">
                    {takeSessionDetails?.status === "Pending" && (
                      <>
                        <div>
                          <Link className="btn btn-create2">
                            Create Session
                          </Link>
                        </div>
                        <div>
                          <Link
                            className="btn btn-later2"
                            onClick={() => {
                              handleStatusUpdate("MayBeLater");
                            }}
                          >
                            May be later
                          </Link>
                        </div>
                        <div>
                          <Link
                            className="btn btn-ignore2"
                            onClick={() => {
                              handleStatusUpdate("Ignored");
                            }}
                          >
                            Ignore
                          </Link>
                        </div>
                      </>
                    )}

                    {takeSessionDetails?.status == "MayBeLater" && (
                      <>
                        <div>
                          <Link className="btn btn-later2">
                            Scheduled For Later
                          </Link>
                        </div>
                        <div>
                          <Link className="btn btn-create2">
                            Create Session
                          </Link>
                        </div>
                      </>
                    )}

                    {takeSessionDetails?.status === "Ignored" && (
                      <>
                        <div>
                          <Link className="btn btn-ignore2">
                            Session was ignored
                          </Link>
                        </div>
                      </>
                    )}

                    {takeSessionDetails?.status === "Accepted" && (
                      <>
                        <div>
                          <Link className="btn btn-create2">
                            Session Created
                          </Link>
                        </div>
                      </>
                    )}

                    {takeSessionDetails?.status === "Rejected" && (
                      <>
                        <div>
                          <Link className="btn btn-ignore2">
                            Session Rejected
                          </Link>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  takesessionId: state.takeSession.takesessionId,
});


const mapDispatchToProps = (dispatch) => ({
  takeSessionApiTrigger: () => dispatch(takeSessionApiTrigger()),
});


export default connect(mapStateToProps, mapDispatchToProps)(TakeSessionModal);
