import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import HeroBanner from "./components/HeroBanner";
import SectionHeader from "./components/SectionHeader";
import { getStoriesApi } from "api/storyApi";
import StoryCard from "./components/StoryCard";
import FacebookIcon from "../../assets/images/footer-facebook.svg";
import TwitterIcon from "../../assets/images/footer-twitter.svg";
import LinkedinIcon from "../../assets/images/footer-linkedin.svg";
import YoutubeIcon from "../../assets/images/footer-youtube.svg";

const HomeIndex = () => {
  const [activeData, setActiveData] = useState({
    page: 1,
    limit: 3,
  });

  const [storiesList, setStoriesList] = useState([]);
  useEffect(() => {
    const existingLinks = document.querySelectorAll('link[rel="stylesheet"]');
    existingLinks.forEach((link) => link.remove());

    const cssFiles = [
      "/assets/css/home.css",
      "/assets/css/bootstrap-latest/css/bootstrap.min.css",
      "https://use.fontawesome.com/releases/v5.8.1/css/all.css",
    ];
    cssFiles.forEach((cssFile) => {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = cssFile;
      document.head.appendChild(link);
    });

    return () => {
      cssFiles.forEach((cssFile) => {
        const link = document.querySelector(`link[href="${cssFile}"]`);
        if (link) {
          link.remove();
        }
      });
    };
  }, []);

  const getStoriesList = (data) => {
    getStoriesApi(data)
      .then((res) => {
        setStoriesList(res?.data?.data?.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getStoriesList(activeData);
  }, []);

  return (
    <>
      <div className="container-fluid main-header">
        <Header />
      </div>
      <hr className="m-0 p-0" />

      <section className="container-fluid color-container">
        <div className="row flex-lg-row-reverse align-items-center m-auto g-5 pb-5 hero-row">
          <div className="col-10 col-sm-8 col-lg-6 mt-3">
            <img
              src="assets/images/image_right.svg"
              className="d-block ms-lg-auto banner-img"
              alt="Bootstrap Themes"
              width="522"
              height="473"
              loading="lazy"
            />
          </div>
          <HeroBanner />
        </div>
      </section>

      <section className="container-fluid mb-5">
        <SectionHeader title={"About Us"} />
        <div className="row justify-content-around">
          <div className="col-md-4 col-sm-2 d-flex justify-content-center">
            <div className="globe-img">
              <img
                src="assets/images/photo-album-globe.png"
                alt="globe"
                height="240px"
                width="240px"
              />
            </div>
          </div>
          <div className="col-md-5 col-sm-4 col-8">
            <div className="first-row">
              <p>
                Universal Manch is a place where people from all over the world
                can share what they know and love with others. It doesn't matter
                if you're an actor, doctor, singer, engineer, or have any
                valuable skills – you can become a mentor and teach others what
                you're passionate about. Through live sessions online or
                in-person, you can connect with people who want to learn from
                you. The goal is to create a community where knowledge is freely
                shared and everyone can inspire and support each other. So, if
                you enjoy helping others and want to make a positive impact,
                come and join Universal Manch!
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid pb-5 color-container">
        <div className="title-header text-center">
          <h2 className="title mt-5">Goal of our platform</h2>
        </div>
        <div className="platform-section-text">
          <p className="justify-content-center">
            Universal Manch's main goal is to create a global platform where
            people from anywhere can share and learn without any barriers. We
            offer live interactions that allow learners to connect with mentors,
            ask questions, and get immediate answers, making learning dynamic
            and meaningful. Our focus is on bringing people together through
            knowledge and personal connections.
          </p>
        </div>

        <div>
          <div className="row justify-content-center">
            <div className="col-md-5 col-sm-2 col-10 order-md-1">
              <div className="card text-bg-dark bgcard-1">
                <img
                  src="assets/images/cardbg1.png"
                  className="card-img card-image"
                  alt="bg1"
                />
                <div className="card-img-overlay">
                  <h5 className="card-title">Global Reach</h5>
                  <p className="card-text">
                    Universal Manch allows speakers to connect with a diverse
                    global audience, sharing skills and knowledge in various
                    fields. Embrace the joy of connecting with people from
                    different backgrounds, cultures, and experiences, fostering
                    a rich exchange of perspectives and ideas.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-5 col-sm-2 col-10">
              <div className="card text-bg-dark bgcard-2">
                <img
                  src="assets/images/cardbg2.png"
                  className="card-img card-image"
                  alt="bg2"
                />
                <div className="card-img-overlay">
                  <h5 className="card-title">Global Reach</h5>
                  <p className="card-text">
                    Universal Manch allows speakers to connect with a diverse
                    global audience, sharing skills and knowledge in various
                    fields. Embrace the joy of connecting with people from
                    different backgrounds, cultures, and experiences, fostering
                    a rich exchange of perspectives and ideas.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid">
        <div className="container pb-5">
          <div className="title-header text-center">
            <h2 className="title mt-4">How you can use universal manch</h2>
          </div>
          <div className="row justify-content-center">
            <div className="col-6 d-flex justify-content-center">
              <img
                src="assets/images/section-img-1.svg"
                className="section-img-1"
              />
            </div>
            <div className="col-md-6 col-sm-3 col-10">
              <div className="mt-4">
                <h4>Become a Knowledge Giver</h4>
                <p className="mt-4">
                  Universal Manch is an ideal platform for those passionate
                  about teaching and sharing knowledge without any monetary
                  expectations. Whether you're an expert in a specific field or
                  just love enlightening others, you can become a knowledge
                  giver, creating captivating sessions for a diverse global
                  audience eager to learn from you. Here, your main goal is to
                  share knowledge, hone your teaching skills, receive
                  thought-provoking questions, and build meaningful connections
                  with learners worldwide.
                </p>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-6 col-sm-3 col-11 order-2 order-md-1 justify-content-center">
              <div className="mt-5 group-discussion-col">
                <h4>Engage in Group Discussions</h4>
                <p className="mt-4">
                  Universal Manch offers a perfect space for meaningful group
                  discussions with like-minded individuals. Join or create
                  groups on various subjects, engaging in thought-provoking
                  conversations that expand your perspectives and understanding
                  of different topics. Connect with a diverse audience and
                  exchange ideas that inspire and enrich your knowledge.
                </p>
              </div>
            </div>

            <div className="col-md-5 col-sm-2 order-1 order-md-2 d-flex justify-content-center">
              <img
                src="assets/images/section-img-2.svg"
                className="section-img-2"
              />
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-6 col-sm-3 d-flex order-1 order-md-1 justify-content-center">
              <img
                src="assets/images/section-img-3.svg"
                className="section-img-3"
              />
            </div>
            <div className="col-md-6 col-sm-3 col-10 order-2 order-md-2 d-flex align-items-center">
              <div className="mt-4">
                <h4>Language Practice and Learning</h4>
                <p className="mt-4">
                  Universal Manch provides a platform for language learners to
                  practice speaking with others. Create language practice
                  sessions and connect with native speakers and fellow learners
                  to accelerate language proficiency through real-life
                  conversations. Whether it's English, Thai, Japanese, or any
                  other language, you can find like-minded individuals to
                  improve your language skills.
                </p>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-6 col-sm-3 col-10 order-2 order-md-1 d-flex justify-content-center align-items-center">
              <div className="mt-4 interview-col">
                <h4>Conduct Live Interviews</h4>
                <p className="mt-4">
                  Universal Manch offers a perfect stage for hosting live
                  interviews with speakers or subject matter experts. Conduct
                  interviews with interested participants or make them public
                  for a global audience. This opportunity expands your network,
                  expertise, and allows people worldwide to gain insights from
                  your engaging conversations.
                </p>
              </div>
            </div>

            <div className="col-md-5 col-sm-2 order-1 order-md-2 d-flex justify-content-center">
              <img
                src="assets/images/section-img-4.svg"
                className="section-img-4"
              />
            </div>
          </div>

          <div className="row justify-content-center my-4">
            <div className="col-md-6 col-sm-3 d-flex order-1 order-md-1 justify-content-center">
              <img
                src="assets/images/section-img-5.svg"
                className="section-img-5"
              />
            </div>
            <div className="col-md-6 col-sm-3 col-10 order-2 order-md-2">
              <div className="mt-4">
                <h4>Showcase Your Products</h4>
                <p className="mt-4">
                  Universal Manch offers a marketing podium for showcasing your
                  product or service. Organize closed-group sessions to present
                  and demonstrate your offering, interact with potential
                  customers in real-time, answer questions, and gather feedback.
                  This interactive platform strengthens your brand presence and
                  credibility by connecting directly with your audience.
                </p>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-6 col-sm-3 col-10 d-flex order-2 order-md-1 justify-content-center align-items-center">
              <div className="mt-4 enthusiast-col">
                <h4>Debate Enthusiasts Unite</h4>
                <p className="mt-4">
                  Universal Manch is an ideal platform for hosting intriguing
                  debates with participants from around the world. Create debate
                  sessions on subjects you're passionate about and engage in
                  intellectually stimulating discussions. The diverse audience
                  ensures a variety of perspectives, making your debates
                  enriching and enjoyable.
                </p>
              </div>
            </div>

            <div className="col-md-5 col-sm-2 order-1 order-md-2 d-flex justify-content-center">
              <img
                src="assets/images/section-img-6.svg"
                className="section-img-6"
              />
            </div>
          </div>
        </div>
      </section>

      {storiesList && storiesList?.length > 0 && (
        <section className="container-fluid color-container">
          <div className="container pb-5">
            <div className="title-header text-center">
              <h2 className="title mt-5">Stories Around the World</h2>
            </div>

            <div className="row justify-content-center">
              {storiesList &&
                storiesList?.map((story, index) => (
                  <StoryCard
                    image={story.bannerImageUrl}
                    title={story.title}
                    detail={story.detail_normal}
                    storyId={story.id}
                  />
                ))}

              <div className="d-flex justify-content-center">
                <a href="/stories" className="btn more-stories-btn mt-5">
                  More Stories
                </a>
              </div>
            </div>
          </div>
        </section>
      )}

      <section
        className="container-fluid"
        style={{ backgroundColor: "#FF385C" }}
      >
        <div className="layer-1">
          <img src="assets/images/bottom-layer-1.svg" alt="" />
        </div>
        <div className="container last-section-container">
          <div className="row last-section-row">
            <div className="col-md-9 col-sm-4 col-10">
              <div className="last-section-heading">
                <h2 className="title text-white mt-5 last-section-heading-text">
                  What are you waiting for?
                </h2>
              </div>
              <div className="mt-4">
                <p className="text-white">
                  Universal Manch is the stage where you can unleash your
                  potential and create extraordinary stories, whether you're a
                  traveler, mentor, language enthusiast, interviewer, product
                  owner, or debate enthusiast. Connect with like-minded
                  individuals, share unique experiences, and inspire others
                  through live, interactive sessions that transcend borders.
                  Join now and be part of a vibrant community where stories are
                  made, connections are forged, and knowledge knows no limits.
                </p>
              </div>
              <div className="join-our-manch-btn">
                <a href="/login" className="btn join-us-btn mt-4">
                  Join Our Manch
                </a>
              </div>
            </div>
            <div className="col-3 question-col">
              <div className="man-img mt-3 mb-2">
                <span className="question-1">
                  <img src="assets/images/question_mark.svg" alt="" />
                </span>
                <img src="assets/images/man_img.svg" alt="manimg" />
                <span className="question-2">
                  <img src="assets/images/question_mark.svg" alt="" />
                </span>
                <span className="layer-2">
                  <img src="assets/images/bottom-layer-2.svg" alt="" />
                </span>
              </div>
            </div>

            <div className="d-inline-flex layer-3">
              <span className="layer-3-none">
                <img src="assets/images/bottom-layer-3.svg" alt="" />
              </span>
            </div>
          </div>
        </div>
      </section>

      <footer className="d-flex flex-wrap justify-content-between align-items-center footer-section">
        <div className="col-md-6 d-flex align-items-center">
          <a
            href="/"
            className="mb-3 me-2 mb-md-0 text-body-secondary text-decoration-none lh-1"
          >
            {/* <svg className="bi" width="30" height="24">
              <use xlink:href="#bootstrap"></use>
            </svg> */}
          </a>
          <span className="mb-3 mb-md-0 text-white">
            © Copyright 2023{" "}
            <span className="footer-text ps-2">iLoma Technology Pvt. Ltd.</span>
            All Rights Reserved
          </span>
        </div>

        {/* <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
          <li className="ms-3">
            <a className="text-body-secondary" href="javascript:void(0)">
              <img
                src={FacebookIcon}
                alt="facebookicon"
                height="20px"
                width="20px"
              />
            </a>
          </li>
          <li className="ms-3">
            <a className="text-body-secondary" href="javascript:void(0)">
              <img
                src={TwitterIcon}
                alt="twittericon"
                height="20px"
                width="20px"
              />
            </a>
          </li>
          <li className="ms-3">
            <a className="text-body-secondary" href="javascript:void(0)">
              <img
                src={LinkedinIcon}
                alt="linkedinicon"
                height="22px"
                width="22px"
              />
            </a>
          </li>

          <li className="ms-3 me-5">
            <a className="text-body-secondary" href="javascript:void(0)">
              <img
                src={YoutubeIcon}
                alt="youtubeicon"
                height="22px"
                width="22px"
              />
            </a>
          </li>
        </ul> */}
      </footer>
    </>
  );
};

export default HomeIndex;
