import React from 'react';
import EditMenu from './EditMenu';
import { Link } from 'react-router-dom';
import AvatarImage from "../../../assets/images/profile-placeholder.png";
import { handleUserImageError } from 'helpers/common_helper';

function SessionParticipants({ participantId, data, onDelete, isDisabled }) {

const handleAccept = () => {
onDelete(participantId);
};


  const participantData = data.participantUser;
  return (
    <div className="card mb-4">
          <div className="card-body py-3 px-4">
              <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                      <div className="me-4">
                        <Link to={`/user-profile/${participantData.user_name}`}>
                          <img src={participantData.profileImageUrl === null ? AvatarImage : participantData.profileImageUrl}
                          className="rounded-circle img-property-fit" alt="user" width="68" height="68" onError={handleUserImageError}/>
                          </Link>
                      </div>
                      <div>
                        <Link to={`/user-profile/${participantData.user_name}`}>
                          <h5 className="mb-0">{participantData.first_name} {participantData.last_name}</h5>
                          </Link>
                          <span>{participantData.Role.name}</span>
                      </div>
                  </div>
                  <div className="d-flex">
                      <div>
                        {isDisabled && (
                            <>
                            <a href="#" className="btn btn-remove d-flex align-items-center" 
                          onClick={handleAccept}>
                              <i className="fa fa-remove me-2"></i> Remove
                          </a>
                            </>
                        )}
                          
                      </div>
                  </div>
              </div>
          </div>
      </div>
  );
}

export default SessionParticipants;
