import React, { useState } from "react";
import { connect } from "react-redux";
import { setSearchValue, setSearchValueSubmitted } from "actions/searchActions";
import FilterIcon from "../../../assets/images/filter.png";
import { useMediaQuery } from "react-responsive";

const SearchBar = ({
  placeholder,
  onSubmit,
  searchValue,
  setSearchValue,
  setSearchValueSubmitted,
}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    setSearchValueSubmitted(true);
  };

  const handleChange = (event) => {
    setSearchValue(event.target.value);
    setSearchValueSubmitted(true);
  };

  const isMobileView = useMediaQuery({ query: "(max-width: 767px)" });

  return (
    <form
      className="mx-auto d-flex p-relative search-form align-items-center"
      onSubmit={handleSubmit}
    >
      <input
        className="form-control"
        type="search"
        placeholder={placeholder}
        aria-label="Search"
        value={searchValue}
        onChange={handleChange}
      />
      <button
        className="btn btn-search bg-primary1 d-flex align-items-center justify-content-center"
        type="submit"
      >
        <i className="fa fa-search"></i>
      </button>

      {/* Filter icon */}

      {isMobileView && (
         <div className="filter-icon">
         <a href="!#" data-bs-target="#filterModal" data-bs-toggle="modal">
           <img src={FilterIcon} alt="filter-icon" width="" height="" />
         </a>
       </div>
      )}

     
    </form>
  );
};

const mapStateToProps = (state) => ({
  searchValue: state.search.searchValue,
});

const mapDispatchToProps = (dispatch) => ({
  setSearchValue: (value) => dispatch(setSearchValue(value)),
  setSearchValueSubmitted: (isSubmitted) =>
    dispatch(setSearchValueSubmitted(isSubmitted)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
