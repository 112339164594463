import config from "../configurations/config";
import { postMethodCall } from "./apiHandler";

export const getStoryApi = async (payload) => {
  return await postMethodCall(`${config.backendApiUrl}/api/user/get-story`, payload);
};

export const getStoriesApi = async (payload) => {
    return await postMethodCall(`${config.backendApiUrl}/api/user/get-stories`, payload);
  };
