import React, { useEffect, useState } from "react";
import CloseIcon from "../../assets/images/icon_close.png";

const ShareModal = ({ shareableLink }) => {
  useEffect(() => {
    const linkedin = document.getElementById("linkedin-share");
    const twitter = document.getElementById("twitter-share");
    const instagram = document.getElementById("instagram-share");
    const facebook = document.getElementById("facebook-share");
    const discord = document.getElementById("discord-share");
    const whatsapp = document.getElementById("whatsapp-share");
    const messenger = document.getElementById("messenger-share");
    const telegram = document.getElementById("telegram-share");
    const line = document.getElementById("line-share");
    const wechat = document.getElementById("wechat-share");

    linkedin.setAttribute(
      "href",
      `https://linkedin.com/shareArticle?url=${shareableLink}&title=UniversalManch`
    );
    twitter.setAttribute(
      "href",
      `https://twitter.com/intent/tweet?url=${shareableLink}&text=UniversalManch`
    );
    instagram.setAttribute(
      "href",
      `https://www.instagram.com/?url=${shareableLink}`
    );
    facebook.setAttribute(
      "href",
      `https://www.facebook.com/sharer/sharer.php?u=${shareableLink}`
    );
    discord.setAttribute("href", `https://discord.com/channels/@me`);
    whatsapp.setAttribute(
      "href",
      `https://api.whatsapp.com/send?text=${shareableLink}`
    );
    messenger.setAttribute(
      "href",
      `https://www.facebook.com/dialog/send?app_id=521270401588372&link=${shareableLink}&redirect_uri=${shareableLink}`
    );
    telegram.setAttribute(
      "href",
      `https://telegram.me/share/url?url=${shareableLink}`
    );
    line.setAttribute(
      "href",
      `https://social-plugins.line.me/lineit/share?url=${shareableLink}`
    );
    wechat.setAttribute(
      "href",
      `https://www.addtoany.com/share/wechat?linkurl=${shareableLink}`
    );

    document.getElementById("myLinkBox").value = shareableLink;
  }, [shareableLink]);

  const copyLink = () => {
    const copyText = document.getElementById("myLinkBox");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    setIsCopied(true);
  };

  const [isCopied, setIsCopied] = useState(false);

  return (
    <div className="modal fade newmodal" id="shareModal">
      <div
        className="modal-dialog sharemodal-sm modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header text-center">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              onClick={() => setIsCopied(false)}
            >
              <img src={CloseIcon} alt="" width="" height="" />
            </button>

            <h4 className="text-center">Share</h4>
          </div>
          <div className="modal-body px-5 py-4">
            <div className="row">
              {/* COL 1 */}
              <div className="col-12 d-flex mb-4">
                <a id="twitter-share" href="" target="_blank">
                  <div className="sharecontainer me-5">
                    <i
                      className="fab fa-twitter share-icons"
                      style={{
                        fontSize: "30px",
                        color: "rgb(29, 155, 240)",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    ></i>
                  </div>
                </a>

                <a id="instagram-share" href="" target="_blank">
                  <div className="sharecontainer me-5">
                    <i
                      className="fab fa-instagram share-icons"
                      style={{
                        fontSize: "30px",
                        color: "#E4405F",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    ></i>
                  </div>
                </a>

                <a id="facebook-share" href="" target="_blank">
                  <div className="sharecontainer me-5">
                    <i
                      className="fab fa-facebook share-icons"
                      style={{
                        fontSize: "30px",
                        color: "#4267B2",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    ></i>
                  </div>
                </a>

                <a id="discord-share" href="" target="_blank">
                  <div className="sharecontainer me-5">
                    <i
                      className="fab fa-discord share-icons"
                      style={{
                        fontSize: "30px",
                        color: "#5865F2",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    ></i>
                  </div>
                </a>

                <a href="" id="linkedin-share" target="_blank">
                  <div className="sharecontainer me-5">
                    <i
                      className="fab fa-linkedin share-icons"
                      style={{
                        fontSize: "30px",
                        color: "#0A66C2",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    ></i>
                  </div>
                </a>
              </div>
              {/* COL 1 */}

              {/* COL 2 */}
              <div className="col-12 d-flex">
                <a id="whatsapp-share" href="" target="_blank">
                  <div className="sharecontainer me-5">
                    <i
                      className="fab fa-whatsapp share-icons"
                      style={{
                        fontSize: "30px",
                        color: "#25D366",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    ></i>
                  </div>
                </a>

                <a id="messenger-share" href="" target="_blank">
                  <div className="sharecontainer me-5">
                    <i
                      className="fab fa-facebook-messenger share-icons"
                      style={{
                        fontSize: "30px",
                        color: "#0099FF",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    ></i>
                  </div>
                </a>

                <a id="telegram-share" href="" target="_blank">
                  <div className="sharecontainer me-5">
                    <i
                      className="fab fa-telegram share-icons"
                      style={{
                        fontSize: "30px",
                        color: "#0088cc",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    ></i>
                  </div>
                </a>

                <a id="line-share" href="" target="_blank">
                  <div className="sharecontainer me-5">
                    <i
                      className="fab fa-line share-icons"
                      style={{
                        fontSize: "30px",
                        color: "#00b900",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    ></i>
                  </div>
                </a>

                <a id="wechat-share" href="" target="_blank">
                  <div className="sharecontainer me-5">
                    <i
                      className="fab fa-wechat share-icons"
                      style={{
                        fontSize: "30px",
                        color: "#09B83E",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    ></i>
                  </div>
                </a>
              </div>
              {/* COL 2 */}
            </div>

            {/* Copy to clipboard */}
            <div className="input-group mb-3 mt-3">
              <input
                type="text"
                className="form-control"
                id="myLinkBox"
                readOnly
                style={{ fontSize: "16px" }}
              />
              <button
                className="btn btn-outline-secondary"
                type="button"
                id="button-addon2"
                onClick={copyLink}
              >
                {isCopied ? "Copied" : "Copy"}
              </button>
            </div>
          </div>
          <div className="modal-footer justify-content-center">
            <button
              type="button"
              className="btn btn-login"
              data-bs-dismiss="modal"
              onClick={() => setIsCopied(false)}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;
