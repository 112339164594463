export const newParticipantRequestStatus = {
    All : "",
    Pending: "pending",
    Accepted: "accepted",
    Rejected: "rejected",
}

export const takeSessionRequestStatus = {
    All : "All",
    pending: "Pending",
    ignored: "Ignored",
    MayBeLater: "May be later",

}

export const sessionVisibilityType = {
    PUBLIC: "Public",
    // PRIVATE: "Private",
    INVITE_ONLY: "Invite Only",
}

export const sessionVenueType = {
    Online: "Online",
    Offline: "Offline",
}
