import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { useMediaQuery } from 'react-responsive'

const Layout = ({ children }) => {

  const isMobileView = useMediaQuery({ query: "(max-width: 767px)" });
  return (
    <>
    <Header />
    {children}
    {!isMobileView && <Footer/>}
    
    </>
  )
}

export default Layout