import React, { useState } from "react";
import CloseIcon from "../../assets/images/icon_close.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createSessionFeedbackApi } from "api/sessionFeebackApi";

const FeedbackModal = ({ session_id }) => {
  const [rating, setRating] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleRatingClick = (selectedRating) => {
    setRating(selectedRating);
    formik.setFieldValue("rating", selectedRating);
  };

  const formik = useFormik({
    initialValues: {
      sessionId: "",
      rating: "",
      comment: "",
    },
    validationSchema: Yup.object({
      comment: Yup.string().required("Comment is required"),
    }),
    onSubmit: (values) => {
      values.sessionId = session_id;

      createSessionFeedbackApi(values)
        .then((res) => {
          if (res?.data?.success) {
            setIsSubmitted(true);
          } else {
            setIsSubmitted(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });

  const handleClose = () => {
    setIsSubmitted(false);
    setRating(0);
    formik.resetForm();
  }

  return (
    <div className="modal fade newmodal" id="feedbackModal">
      <div className="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header text-center">
            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleClose}>
              <img src={CloseIcon} alt="" width="" height="" />
            </button>
            <h4 className="text-center">Give Feedback</h4>
          </div>

          <div className="modal-body px-5 py-4">
            {isSubmitted ? (
              <>
                <div className="text-center">
                  <h4>Thank you for your feedback!</h4>
                </div>
              </>
            ) : (
              <>
                <form onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-md-12 mb-2">
                      <label className="form-label mb-0">Rating</label>
                      <div className="star-rating">
                        {[1, 2, 3, 4, 5].map((star) => (
                          <span
                            key={star}
                            className={star <= rating ? "star active" : "star"}
                            onClick={() => handleRatingClick(star)}
                          >
                            &#9733;
                          </span>
                        ))}
                      </div>
                    </div>

                    <div className="col-md-12 mt-4">
                      <label className="form-label mb-0">Comment</label>
                      <textarea
                        className="form-control"
                        rows="3"
                        name="comment"
                        value={formik.values.comment || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      ></textarea>
                      {formik.touched.comment && formik.errors.comment ? (
                        <div className="text-danger">
                          {formik.errors.comment}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </form>
              </>
            )}
          </div>

          <div className="modal-footer justify-content-center">
            {isSubmitted ? (
              <>
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  className="btn btn-login btn-lg btn-block w-100 btn-submit"
                >
                  Close
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={() => formik.handleSubmit()}
                  className="btn btn-login btn-lg btn-block w-100 btn-submit"
                >
                  Submit
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackModal;
