import { combineReducers } from 'redux';
import profileReducer from './profileReducer';
import searchReducer from './searchReducer';
import filterReducer from './filterReducer';
import userRequestReducer from './userRequestReducer';
import takeSessionReducer from './takeSessionReducer';
import dashboardReducer from './dashboardReducer';
import authReducer from './authReducer';

const rootReducer = combineReducers({
  profile: profileReducer,
  search: searchReducer,
  filter: filterReducer,
  userRequest: userRequestReducer,
  takeSession: takeSessionReducer,
  dashboard: dashboardReducer,
  auth: authReducer,
});

export default rootReducer;
