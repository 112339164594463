import React from 'react'
import { Link, NavLink } from 'react-router-dom';

const EditMenu = ({ menuImage, sessionId, onDelete}) => {


  

  const handleAccept = () => {
    onDelete(sessionId);
  };



  return (
    <div className="menu">
    <div className="dropstart">
      <button
        type="button"
        className="btn-menu dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <img
          src={menuImage}
          className=""
          alt="menu"
          width=""
          height=""
        />
      </button>
      <ul className="dropdown-menu dropdown-menu-left">
        <li>
           <NavLink className="dropdown-item" to={"/session-details/"+sessionId}>
            <i className="fa fa-edit text-main"></i>{" "}
            Edit
           </NavLink>
        </li>
        <li>
          <a className="dropdown-item" href="#" 
          onClick={handleAccept}>
            <i className="fa fa-trash text-main"></i>{" "}Delete
          </a>
        </li>
      </ul>
    </div>
  </div>
  )
}

export default EditMenu;