import React from 'react';

function TopVerticalList({ statistics }) {
  return (
    <ul className="top-list d-flex justify-content-center mt-4">
    {statistics.map((statistic, index) => (
      <li
        key={index}
        className={`d-flex flex-column justify-content-center align-items-center ${
          index < statistics.length - 1 ? 'border-right' : ''
        }`}
      >
        <strong>{statistic.value}</strong>
        <span>{statistic.label}</span>
      </li>
    ))}
  </ul>
  );
}

export default TopVerticalList;
