import config from "../configurations/config";
import { getMethodCall, postMethodCall } from "./apiHandler";

export const getNotificationsApi = async (payload) => {
  return await postMethodCall(`${config.backendApiUrl}/api/user/notifications`, payload);
};

export const readNotificationApi = async (payload) => {
  return await getMethodCall(`${config.backendApiUrl}/api/user/readnotification`, payload);
};

export const unreadNotificationCountApi = async (payload) => {
  return await getMethodCall(`${config.backendApiUrl}/api/user/get-unread-notification-count`, payload);
};
