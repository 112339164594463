import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import MoreStoryImage from "../../../assets/images/more-story-image.svg";
import FacebookIcon from "../../../assets/images/footer-facebook.svg";
import TwitterIcon from "../../../assets/images/footer-twitter.svg";
import LinkedinIcon from "../../../assets/images/footer-linkedin.svg";
import YoutubeIcon from "../../../assets/images/footer-youtube.svg";
import { getStoriesApi } from "api/storyApi";
import { Link } from "react-router-dom";

const StoryList = () => {
  const [storyList, setStoryList] = useState([]);
  const [activeData, setActiveData] = useState({
    page: 1,
    limit: 8,
  });

  useEffect(() => {
    const existingLinks = document.querySelectorAll('link[rel="stylesheet"]');
    existingLinks.forEach((link) => link.remove());

    const cssFiles = [
      "/assets/css/home.css",
      "/assets/css/bootstrap-latest/css/bootstrap.min.css",
      "https://use.fontawesome.com/releases/v5.8.1/css/all.css",
      "/assets/css/semantic.min.css",
    ];
    cssFiles.forEach((cssFile) => {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = cssFile;
      document.head.appendChild(link);
    });

    return () => {
      cssFiles.forEach((cssFile) => {
        const link = document.querySelector(`link[href="${cssFile}"]`);
        if (link) {
          link.remove();
        }
      });
    };
  }, []);

  const getStoryList = (data) => {
    getStoriesApi(data)
      .then((res) => {
        setStoryList(res?.data?.data?.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getStoryList(activeData);
  }, []);

 
  return (
    <>
      <div className="container-fluid main-header">
        <Header />
      </div>
      <hr className="m-0 p-0" />

      <section className="container-fluid">
        <div className="container-lg mt-5">
          <div className="row">
            {storyList &&
              storyList.map((item, index) => (
                <div className="col-md-3 mb-4" key={index}>
                  <Link to={`/story-details/${item?.id}`}>
                    <div className="card more-stories-card">
                      <img
                        src={
                          item?.bannerImageUrl === (null || undefined)
                            ? MoreStoryImage
                            : item?.bannerImageUrl
                        }
                        alt="storyimages"
                        className="card-img-top more-story-images img-property-fit"
                      />
                      <div className="card-body">
                        <p className="card-title text-center">{item?.title}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </section>

      <footer className="d-flex flex-wrap justify-content-between align-items-center footer-section fixed-bottom">
        <div className="col-md-6 d-flex align-items-center">
          <a
            href="/"
            className="mb-3 me-2 mb-md-0 text-body-secondary text-decoration-none lh-1"
          >
            {/* <svg className="bi" width="30" height="24">
              <use xlink:href="#bootstrap"></use>
            </svg> */}
          </a>
          <span className="mb-3 mb-md-0 text-white">
            © Copyright 2023{" "}
            <span className="footer-text ps-2">iLoma Technology Pvt. Ltd.</span>
            All Rights Reserved
          </span>
        </div>

        {/* <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
          <li className="ms-3">
            <a className="text-body-secondary" href="javascript:void(0)">
              <img
                src={FacebookIcon}
                alt="facebookicon"
                height="20px"
                width="20px"
              />
            </a>
          </li>
          <li className="ms-3">
            <a className="text-body-secondary" href="javascript:void(0)">
              <img
                src={TwitterIcon}
                alt="twittericon"
                height="20px"
                width="20px"
              />
            </a>
          </li>
          <li className="ms-3">
            <a className="text-body-secondary" href="javascript:void(0)">
              <img
                src={LinkedinIcon}
                alt="linkedinicon"
                height="22px"
                width="22px"
              />
            </a>
          </li>

          <li className="ms-3 me-5">
            <a className="text-body-secondary" href="javascript:void(0)">
              <img
                src={YoutubeIcon}
                alt="youtubeicon"
                height="22px"
                width="22px"
              />
            </a>
          </li>
        </ul> */}
      </footer>
    </>
  );
};

export default StoryList;
