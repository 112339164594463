import { profileDetailsApi } from 'api/profileApi';
import axios from 'axios';

export const fetchProfileRequest = () => ({
  type: 'FETCH_PROFILE_REQUEST',
});

export const fetchProfileSuccess = (profile) => ({
  type: 'FETCH_PROFILE_SUCCESS',
  payload: profile,
});

export const fetchProfileFailure = (error) => ({
  type: 'FETCH_PROFILE_FAILURE',
  payload: error,
});

const userProfile = localStorage.getItem('_lluser');
const username = JSON.parse(userProfile)?.user_name;



export const fetchProfile = () => {
  return async (dispatch) => {
    dispatch(fetchProfileRequest());

    try {
      const response = await profileDetailsApi({ username });
      dispatch(fetchProfileSuccess(response?.data?.data));
    } catch (error) {
      dispatch(fetchProfileFailure(error?.message));
    }
  };
};
