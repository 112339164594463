import React, { useEffect, useState } from "react";
import BookmarkIcon from "../../../assets/images/icon-bookmark.svg";
import ShareIcon from "../../../assets/images/shareicon.svg";
import BackgroundImage from "../../../assets/images/colorbg.jpg";
import { handleImageError } from "helpers/common_helper";
import AvatarImage from "../../../assets/images/profile-placeholder.png";
import { handleUserImageError } from "helpers/common_helper";
import createShortDynamicLink from "services/DynamicLink/shortLink";
import ShareModal from "components/Utilities/ShareModal";
import BookmarkIconFilled from "../../../assets/images/icon-bookmark-filled.svg";
import { createBookmarkApi } from "api/bookmarkApi";
import { getBookmarkApi } from "api/bookmarkApi";
import { getUserInfo } from "helpers/common_helper";

const StorySessionCard = ({
  userName,
  language,
  datetime,
  sessionType,
  userImage,
  sessionVisibility,
  title,
  backgroundImage,
  handleSessionClick,
  user_name,
  session_id,
  sessionTypeColor,
  participantLimit,
  participantCount,
  timezoneAbbrevation,
}) => {
  const [shortLink, setShortLink] = useState("");

  const userNameOfUser = getUserInfo()?.user_name;

  const handleShortLink = () => {
    createShortDynamicLink("session-info", session_id)
      .then((res) => {
        setShortLink(res?.shortLink);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const slotsAvailable =
    parseInt(participantLimit) - parseInt(participantCount);

  const slotsStyle = {
    color: slotsAvailable <= 5 ? "red" : "black",
  };

  return (
    <>
      <div className="col-12 col-sm-3 col-md-6 col-lg-6 col-xl-3">
        <div className="card mb-4">
          <div className="banner-image">
            <img
              src={
                backgroundImage === null || backgroundImage === undefined
                  ? BackgroundImage
                  : backgroundImage
              }
              className="card-img-top img-property-fit"
              height="143"
              width="293"
              onError={handleImageError}
            />
          </div>

          {userNameOfUser === user_name ? (
            <>
              <a href={`/session-details/${session_id}`}>
                <div className="card-img-overlay" style={{ bottom: "unset" }}>
                  <div className="d-flex justify-content-end">
                    <span
                      className="badge text-white"
                      style={{ backgroundColor: "black" }}
                    >
                      {language}
                    </span>
                  </div>

                  <div className="row" style={{ marginTop: "63px" }}>
                    <div className="col-md-12">
                      <h4 className="text-white m-0">{title}</h4>
                      <div className="d-flex">
                        <p className="m-0" style={{ color: "#c8c7c7" }}>
                          {`${datetime} ${timezoneAbbrevation}`}
                        </p>
                        {/* <p
                      className="text-white m-0 ms-auto session-type-badge d-flex align-items-center"
                      style={{ background: "#FFC107" }}
                    >
                      {sessionVisibility}
                    </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </>
          ) : (
            <>
              <a
                data-bs-target="#sessionDetailsModal"
                data-bs-toggle="modal"
                onClick={() => handleSessionClick()}
              >
                <div className="card-img-overlay" style={{ bottom: "unset" }}>
                  <div className="d-flex justify-content-end">
                    <span
                      className="badge text-white"
                      style={{ backgroundColor: "black" }}
                    >
                      {language}
                    </span>
                  </div>

                  <div className="row" style={{ marginTop: "63px" }}>
                    <div className="col-md-12">
                      <h4 className="text-white m-0">{title}</h4>
                      <div className="d-flex">
                        <p className="m-0" style={{ color: "#c8c7c7" }}>
                          {`${datetime} ${timezoneAbbrevation}`}
                        </p>
                        {/* <p
                      className="text-white m-0 ms-auto session-type-badge d-flex align-items-center"
                      style={{ background: "#FFC107" }}
                    >
                      {sessionVisibility}
                    </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </>
          )}

          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex align-items-center">
                  <a
                    href={`/user-profile/${user_name}`}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={
                        userImage === null || userImage === undefined
                          ? AvatarImage
                          : userImage
                      }
                      className="rounded-circle img-property-fit"
                      width="25px"
                      height="25px"
                      onError={handleUserImageError}
                    />
                  </a>

                  <a
                    href={`/user-profile/${user_name}`}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="ms-2">
                      <h5 className="m-0">{userName}</h5>
                    </div>
                  </a>

                  <div className="ms-auto">
                    <span className="me-2">
                      <img
                        src={BookmarkIcon}
                        alt="bookmark"
                        height="18px"
                        width="18px"
                        style={{ cursor: "pointer" }}
                      />
                    </span>
                    <span>
                      <a
                        data-bs-target="#shareModal"
                        data-bs-toggle="modal"
                        onClick={handleShortLink}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={ShareIcon}
                          alt="shareicon"
                          height="15px"
                          width="15px"
                        />
                      </a>
                    </span>
                  </div>
                </div>

                <div className="d-flex align-items-center mt-2">
                  <div className="ms-2">
                    <p style={slotsStyle}>{slotsAvailable} Slots Available</p>
                  </div>

                  <div className="ms-auto">
                    <span
                      className="badge"
                      style={{ backgroundColor: sessionTypeColor }}
                    >
                      {sessionType?.length > 8
                        ? sessionType?.substring(0, 8) + "..."
                        : sessionType}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ShareModal shareableLink={shortLink} />
    </>
  );
};

export default StorySessionCard;
