import config from "../configurations/config";
import { postMethodCall } from "./apiHandler";

export const createBookmarkApi = async (payload) => {
    return await postMethodCall(`${config.backendApiUrl}/api/user/create-bookmark`, payload);
  };

export const getBookmarkApi = async (payload) => {
    return await postMethodCall(`${config.backendApiUrl}/api/user/get-bookmark`, payload);
  };

  export const getBookmarkListApi = async (payload) => {
    return await postMethodCall(`${config.backendApiUrl}/api/user/get-bookmark-list`, payload);
  };