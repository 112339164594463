import React from "react";

const OutlinedButton = ({ path, image, title, handleClick }) => {
  return (
    <a href={path} className="btn btn-google btn-lg btn-block w-100" onClick={()=> handleClick()}>
      <img
        src={image}
        alt="google-icon"
        width=""
        height=""
        className="me-2"
      />{" "}
      {title}
    </a>
  );
};

export default OutlinedButton;
