const initialState = {
  searchValue: "",
  isSearchValueSubmitted: false,
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SEARCH_VALUE":
      return {
        ...state,
        searchValue: action.payload,
      };
    case "SET_SEARCH_VALUE_SUBMITTED":
      return {
        ...state,
        isSearchValueSubmitted: action.payload,
      };
    default:
      return state;
  }
};

export default searchReducer;
