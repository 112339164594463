import React from "react";
import moment from "moment";
import { getTimezoneDetailApi } from "api/sessionApi";

export const dateFormat = (date) => {
  return moment(date).format("MMMM DD, YYYY");
};

export const dateFormatTH = (date) => {
  var options = { hour: "numeric", minute: "numeric", second: "numeric" };
  return moment(date).add(543, "years").format("DD MMM YYYY");
};

export const currentDateTH = (date) => {
  return moment().add(543, "years").format("DD/MMM/YYYY");
};

export const dateTimeFormat = (datetime) => {
  return moment(datetime).format("DD MMMM YYYY");
};

export const TimeFormat = (datetime) => {
  return moment(datetime, "hh:mm").format("LT"); //moment(datetime).format("h:mm A UTC");
};

export const UTCDateFormat = (datetime) => {
  return moment.utc(datetime).format("DD MMMM YYYY");
};

export const UTCDateFormatWithSlash = (datetime) => {
  return moment.utc(datetime).format("DD/MM/YYYY");
};

export const UTCTimeFormat = (datetime) => {
  return moment.utc(datetime).format("h:mm A");
};

export const UTCDateFormatWithDay = (datetime) => {
  return moment.utc(datetime).format("DD MMMM YYYY, dddd  hh:mm A");
};

export const UTCDateTimeFormatWithUnderscore = (datetime) => {
  return moment.utc(datetime).format("DD MMMM YYYY, hh:mm A");
};

export const capitalizeFirstWord = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const handleImageError = (e) => {
  e.target.src = "/assets/defaultimg/colorbg.jpg";
};

export const handleUserImageError = (e) => {
  e.target.src = "/assets/defaultimg/profile-placeholder.png";
};

export const handleStoryImageError = (e) => {
  e.target.src = "/assets/images/cardimg3.svg";
};

export const adjustStringGrammer = (str) => {
  str = str.toLowerCase();

  var words = str.split("");
  words[0] = words[0].toUpperCase();

  for (var i = 1; i < words.length; i++) {
    if (words[i] === words[i].toUpperCase()) {
      words[i] = " " + words[i];
    }
  }
  var capitalizedStr = words.join("");

  return capitalizedStr;
};

export const formattedFilterDateTime = (datetime) => {
  return moment(datetime).format("YYYY-MM-DD");
};

export const DualDateFormat = (datetime) => {
  return moment.utc(datetime).format("MMMM DD, YYYY");
};

export const timePeriodArr = [
  {
    type: "TODAY",
    name: "Today",
  },
  {
    type: "YESTERDAY",
    name: "Yesterday",
  },
  {
    type: "LAST_7_DAYS",
    name: "Last 7 Days",
  },
  {
    type: "CURRENT_MONTH",
    name: "This Month",
  },
  {
    type: "LAST_MONTH",
    name: "Last Month",
  },
  {
    type: "CHOOSE_PERIOD",
    name: "Choose Period",
  },
];

export const getTodaysDate = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return today;
};

export const isUserLoggedIn = () => {
  if (localStorage.getItem("_lluser")) {
    return true;
  } else {
    return false;
  }
};

export const convertDateToFromNow = (date) => {
  return moment(date).fromNow();
};

export const getUserInfo = () => {
  return JSON.parse(localStorage.getItem("_lluser"));
};

export const isBrand = () => {
  const user = getUserInfo();
  if (user && user.is_brand === 1 && user.brand_name !== null) {
    return true;
  } else {
    return false;
  }
};

export const getCurrenGMTOffset = () => {
  const now = new Date();
  const offsetMinutes = now.getTimezoneOffset();
  const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
  const offsetMinutesRemainder = Math.abs(offsetMinutes) % 60;
  const sign = offsetMinutes < 0 ? "+" : "-";

  const formattedOffsetHours = offsetHours.toString().padStart(2, "0");
  const formattedOffsetMinutes = offsetMinutesRemainder
    .toString()
    .padStart(2, "0");

  return `${sign}${formattedOffsetHours}:${formattedOffsetMinutes}`;
};

export const getCurrentTimeZone = () => {
  const options = Intl.DateTimeFormat().resolvedOptions();
  return options.timeZone;
};

const userTimezone = localStorage.getItem("_lltimezone") || getCurrentTimeZone();

export const formatGlobalDateTime = (datetime) => {
  const date = new Date(datetime);

  const options = {
    timeZone: userTimezone,
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  const utcDate = date.toLocaleString("en-US", options);

  const formatString = date.toISOString().slice(0, 10) + ", " + utcDate;
  const formatRep = moment(formatString).format("DD MMMM YYYY, hh:mm A");
  return formatRep;
};

export const formatGlobalTime = (time) => {
  const date = new Date(time);

  const options = {
    timeZone: userTimezone,
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  const utcTime = date.toLocaleString("en-US", options);

  const formattedTime = moment(utcTime, "HH:mm:ss").format("hh:mm A");
  return formattedTime;
};

export const getTimezoneAbbr = (timezone) => {
  getTimezoneDetailApi({ timezone: timezone })
    .then((res) => {
      localStorage.setItem("_lltimezoneabbr", res?.data?.data?.abbreviation);
    }
    )
    .catch((err) => {
      console.log(err);
    }
    );
}

getTimezoneAbbr(userTimezone);

export const formatGlobalDate = (date) => {
  const globalDate = new Date(date);
  const utcDate = globalDate.toLocaleDateString("en-US", {
    timeZone: userTimezone,
  });

  const formattedDate = moment(utcDate, "MM/DD/YYYY").format("DD MMMM YYYY");
  return formattedDate;
};

export const getTimezoneAbbreviation = (timezone) => {
  const date = new Date();
  const options = { timeZone: timezone, timeZoneName: "short" };
  const timeZoneName = new Intl.DateTimeFormat("en-US", options)
    .formatToParts(date)
    .find((part) => part.type === "timeZoneName").value;

  const abbreviation = timeZoneName.split(" ").pop();
  return abbreviation;
};

export const editTimezoneTimeFormat = (datetime, timezone) => {
  const date = new Date(datetime);

  const options = {
    timeZone: timezone,
    timeZoneName: "long",
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    day: "2-digit",
    month: "short",
    year: "numeric",
  };

  const formattedEditDateTime = date.toLocaleString("en-US", options);

  return formattedEditDateTime;
};

export const getSystemTimezoneAbbr = () => {
  const abbr = localStorage.getItem("_lltimezoneabbr");
  return abbr;
}

export const formatGlobalDateTimeOwn = (datetime, timezone) => {
  const date = new Date(datetime);

  const options = {
    timeZone: timezone,
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  const utcDate = date.toLocaleString("en-US", options);

  const formatString = date.toISOString().slice(0, 10) + ", " + utcDate;
  const formatRep = moment(formatString).format("DD MMMM YYYY, hh:mm A");
  return formatRep;
};
