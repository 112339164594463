import { getSessionDetailApi } from "api/sessionApi";
import moment from "moment";
import React, { useEffect, useState } from "react";
import CloseImage from "../../../assets/images/icon_close.png";
import AvatarImage from "../../../assets/images/profile-placeholder.png";
import BannerImage from "../../../assets/images/colorbg.jpg";
import { sendSessionRequestApi } from "api/sessionApi";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import { UTCDateFormat } from "helpers/common_helper";
import { UTCTimeFormat } from "helpers/common_helper";
import { handleImageError } from "helpers/common_helper";
import { handleUserImageError } from "helpers/common_helper";
import { cancelJoinRequestApi } from "api/sessionApi";
import ConfirmationAlert from "components/Utilities/ConfirmationAlert";
import { useDispatch } from "react-redux";
import { setDashboardApiTriggered } from "actions/DashboardActions";
import createShortDynamicLink from "services/DynamicLink/shortLink";
import ShareModal from "components/Utilities/ShareModal";
import { isUserLoggedIn } from "helpers/common_helper";
import FeedbackModal from "components/Utilities/FeedbackModal";
import { acceptRejectRequestApi } from "api/sessionApi";
import { formatGlobalDate } from "helpers/common_helper";
import { formatGlobalTime } from "helpers/common_helper";

const SessionDetailModal = ({ session_id }) => {
  const dispatch = useDispatch();
  const [sessionDetails, setSessionDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [requestStatus, setRequestStatus] = useState({});
  const [isShowJoinButton, setIsShowJoinButton] = useState(false);
  const [language, setLanguage] = useState("");
  const [dynamicLink, setDynamicLink] = useState("");
  const isLogged = isUserLoggedIn();

  const getSessionDetails = () => {
    setLoading(true);
    getSessionDetailApi({ session_id: session_id })
      .then((res) => {
        setSessionDetails(res?.data?.data?.session);
        setLanguage(res?.data?.data?.session?.languages[0]?.languageName);
        setRequestStatus(res?.data?.data?.requestStatus);
        const sessionStartDateTime = res?.data?.data?.session?.start_date_time;
        // const sessionEndDateTime = res?.data?.data?.session?.end_date_time;
        const currentDateTime = moment.utc().format("YYYY-MM-DD HH:mm:ss");
        const currenDate = moment.utc().format("YYYY-MM-DD");

        const formattedStartDate = moment
          .utc(sessionStartDateTime)
          .format("YYYY-MM-DD");

        // const formattedDate = moment
        //   .utc(sessionEndDateTime)
        //   .format("YYYY-MM-DD HH:mm:ss");
        // const before15Minutes = moment
        //   .utc(formattedDate)
        //   .subtract(15, "minutes")
        //   .format("YYYY-MM-DD HH:mm:ss");
        // const showBefore15Minutes = moment
        //   .utc(currentDateTime)
        //   .isSameOrAfter(before15Minutes);

        // setIsShowJoinButton(showBefore15Minutes);

        if (currenDate == formattedStartDate) {
          setIsShowJoinButton(true);
        } else {
          setIsShowJoinButton(false);
        }

        // const sessionEndDateTime = res?.data?.data?.session?.end_date_time;
        // const formattedEndDate = moment
        //   .utc(sessionEndDateTime)
        //   .format("YYYY-MM-DD HH:mm:ss");
        //   const isSessionTimeOver = moment.utc(currentDateTime).isAfter(formattedEndDate);
        //   if(isSessionTimeOver){
        //     setIsShowJoinButton(false);
        //   }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sendSessionJoinRequest = () => {
    sendSessionRequestApi({ session_id: session_id })
      .then((res) => {
        if (res?.data?.success) {
          toast.success(res?.data?.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setIsSubmitted(true);
          dispatch(setDashboardApiTriggered(true));
          getSessionDetails();
        } else {
          toast.warning(res?.data?.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancelJoinRequest = () => {
    cancelJoinRequestApi({ requestId: requestStatus?.id })
      .then((res) => {
        if (res?.data?.success) {
          toast.success(res?.data?.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setIsSubmitted(true);
          getSessionDetails();
        } else {
          toast.warning(res?.data?.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showConfirmationAlert = () => {
    toast(
      <ConfirmationAlert
        message="Are you sure you want to delete this session?"
        onConfirm={handleCancelJoinRequest}
      />,
      {
        position: "top-center",
        autoClose: false,
        closeOnClick: false,
        closeButton: false,
      }
    );
  };

  useEffect(() => {
    getSessionDetails();
  }, [session_id, isSubmitted]);

  useEffect(() => {
    if (isSubmitted) {
      const timeoutId = setTimeout(() => {
        window.$("#sessionDetailsModal").modal("hide");
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [isSubmitted]);

  const handleShareLink = () => {
    window.$("#sessionDetailsModal").modal("hide");

    createShortDynamicLink("session-info", session_id)
      .then((res) => {
        setDynamicLink(res?.shortLink);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const acceptInviteSessionRequest = (data) => {
    acceptRejectRequestApi(data).then((res) => {
      if (res?.data?.success) {
        toast.success(res?.data?.message, {
          position: "top-right",
          autoClose: 700,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setIsSubmitted(true);
        dispatch(setDashboardApiTriggered(true));
        getSessionDetails();
      } else {
        toast.warning(res?.data?.message, {
          position: "top-right",
          autoClose: 700,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    });
  };

  return (
    <>
      <div className="modal fade sessionModal" id="sessionDetailsModal">
        <div className="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            {loading ? (
              <div className="justify-content-center d-flex align-items-center">
                <div className="loadingio-spinner-rolling-26k4myxj9b9">
                  <div className="ldio-y9y7x49yybs">
                    <div></div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="modal-header justify-content-center text-center border-bottom-0 mb-2">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                  >
                    <img src={CloseImage} alt="" width="" height="" />
                  </button>
                  <h4 className="text-center">Session Details</h4>
                </div>
                <div className="modal-body px-5 pb-4 pt-0">
                  <h6 className="mb-0">{sessionDetails?.title}</h6>
                  <div className="row">
                    <div className="col-md-9">
                      <span className="me-4">
                        {formatGlobalDate(sessionDetails?.start_date_time)}
                      </span>
                      <span className="me-5">
                        {formatGlobalTime(sessionDetails?.start_date_time, sessionDetails?.timezone) +
                          " to " +
                          formatGlobalTime(sessionDetails?.end_date_time, sessionDetails?.timezone)}
                      </span>
                      <span>{language}</span>
                    </div>

                    <div className="col-md-3 d-flex justify-content-between">
                      <span
                        className="session-type-badge-detail"
                        style={{
                          backgroundColor: sessionDetails?.sessionTypeColor,
                        }}
                      >
                        {sessionDetails?.sessionTypeName?.length > 8
                          ? `${sessionDetails?.sessionTypeName?.substring(
                              0,
                              9
                            )}...`
                          : sessionDetails?.sessionTypeName}
                      </span>
                      <span className="session-detail-share">
                        <a
                          data-bs-target="#shareModal"
                          data-bs-toggle="modal"
                          onClick={handleShareLink}
                          style={{ cursor: "pointer" }}
                        >
                          <i className="fa fa-share-alt"> Share</i>
                        </a>
                      </span>
                    </div>
                  </div>

                  <div className="m-img-wrapper mt-3">
                    <img
                      src={
                        sessionDetails?.bannerImageUrl === null
                          ? BannerImage
                          : sessionDetails?.bannerImageUrl
                      }
                      className="b-img img-property-fit"
                      alt="BannerImage"
                      width=""
                      height=""
                      onError={handleImageError}
                    />
                    <div className="about">
                      <div className="d-flex px-5">
                        <img
                          src={
                            sessionDetails?.user?.profileImageUrl === null
                              ? AvatarImage
                              : sessionDetails?.user?.profileImageUrl
                          }
                          alt="Userimage"
                          width=""
                          height=""
                          className="img-property-fit"
                          onError={handleUserImageError}
                        />
                        <h5>{`By ${sessionDetails?.user?.first_name} ${sessionDetails?.user?.last_name}`}</h5>
                      </div>
                      <div className="about-details">
                        <h4 className="text-center">About the Session</h4>
                        <div className="border-bottom-1"></div>
                        <p className="text-justify mb-0">
                          {sessionDetails?.about}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-footer justify-content-center">
                  <div className="me-4">
                    {isLogged ? (
                      <>
                        {sessionDetails?.status === "past" && (
                          <>
                            <div className="d-flex">
                              <Link className="session-detail-label">
                                Session Completed
                              </Link>

                              {requestStatus?.status === "Accepted" && (
                                <Link
                                  className="btn btn-send3"
                                  data-bs-target="#feedbackModal"
                                  data-bs-toggle="modal"
                                >
                                  Give Feedback
                                </Link>
                              )}
                            </div>
                          </>
                        )}
                        {sessionDetails?.participateCount ==
                          sessionDetails?.participate_limit && (
                          <Link className="session-detail-label">
                            Session Full
                          </Link>
                        )}
                        {sessionDetails?.status === "upcoming" &&
                          requestStatus &&
                          requestStatus?.status === "Pending" && (
                            <>
                              <div className="d-flex">
                                <Link className="session-detail-label">
                                  Request Sent
                                </Link>
                                <Link
                                  className="btn btn-send3"
                                  onClick={() => showConfirmationAlert()}
                                >
                                  Cancel Request
                                </Link>
                              </div>
                            </>
                          )}

                        {sessionDetails?.status === "upcoming" &&
                          requestStatus &&
                          requestStatus?.status === "Invited" && (
                            <>
                              <div className="d-flex">
                                <Link
                                  className="session-detail-label"
                                  onClick={() =>
                                    acceptInviteSessionRequest({
                                      requestId: requestStatus?.id,
                                      type: "Accepted",
                                    })
                                  }
                                >
                                  Accept Request
                                </Link>
                                <Link
                                  className="btn btn-send3"
                                  onClick={() => showConfirmationAlert()}
                                >
                                  Cancel Request
                                </Link>
                              </div>
                            </>
                          )}
                        {sessionDetails?.status === "upcoming" &&
                          requestStatus &&
                          requestStatus?.status === "Accepted" && (
                            <div className="session-label-container">
                              <Link className="session-detail-label">
                                Request Accepted
                              </Link>{" "}
                              {isShowJoinButton ? (
                                <Link
                                  className="btn btn-detail"
                                  to={sessionDetails?.meeting_link}
                                  target="_blank"
                                >
                                  Join
                                </Link>
                              ) : (
                                ""
                              )}
                            </div>
                          )}
                        {sessionDetails?.status === "upcoming" &&
                          requestStatus &&
                          requestStatus?.status === "Rejected" && (
                            <Link className="session-detail-label-rejected">
                              Request Rejected
                            </Link>
                          )}
                        {((sessionDetails?.status === "upcoming" &&
                          requestStatus === null) ||
                          requestStatus === undefined) && (
                          <Link
                            onClick={() => sendSessionJoinRequest()}
                            className="btn btn-send2"
                          >
                            Send Join Request
                          </Link>
                        )}
                      </>
                    ) : (
                      <>
                        <Link
                          className="btn btn-send2"
                          onClick={() => {
                            window.location.href = "/login";
                          }}
                        >
                          Login to send request
                        </Link>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <ToastContainer />
      </div>
      <ShareModal shareableLink={dynamicLink} />
      <FeedbackModal session_id={session_id} />
    </>
  );
};

export default SessionDetailModal;
