import CustomTab from "components/ui/Common/CustomTab";
import Layout from "layouts/Index";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormInput from "components/ui/Common/FormInput";
import DatePickerField from "components/ui/Common/DatePickerField";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import CloseIcon from "../../assets/images/icon_close.png";
import uploadIcon from "../../assets/images/img.png";
import {
  updateSessionApi,
  getSessionDetailApi,
  getSessionParticipantApi,
  removeParticipant,
} from "api/sessionApi";
import * as url from "../../helpers/common_helper";
import { getTimezoneApi } from "api/sessionApi";
import { getLanguageApi } from "api/sessionApi";
import { getCategoriesApi } from "api/categoryApi";
import { useParams, useNavigate, Link } from "react-router-dom";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import SessionParticipants from "components/ui/Common/SessionParticipants";
import InfiniteScroll from "react-infinite-scroll-component";
import Swal from "sweetalert2";
import BannerImage from "../../assets/images/colorbg.jpg";
import InviteSessionModal from "./Modal/InviteSessionModal";
import { removeSessionApi } from "api/sessionApi";
import { getSessionTypesApi } from "api/sessionApi";
import { sessionVisibilityType } from "data/selectoptions";
import { sendSessionStartNotificationApi } from "api/sessionApi";
import createShortDynamicLink from "services/DynamicLink/shortLink";
import ShareModal from "components/Utilities/ShareModal";
import DatePicker from "react-datepicker";
import NoDataImage from "../../assets/images/nodatafound.gif";

const SessionDetail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("session-details");
  const [file, setFile] = useState(null);
  const [activeData, setActiveData] = useState({
    page: 1,
    limit: 10,
    search: "",
  });
  const [activeParticipantData, setActiveParticipantData] = useState({
    page: 1,
    limit: 10,
    sessionId: params.id,
  });

  const [sessionDetails, setSessionDetails] = useState({});
  const [timezoneList, setTimezoneList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState();
  const [selectedLanguageIds, setlanguageIdsOptions] = useState();
  const [selectedCategoryIds, setcategoryIdsOptions] = useState();
  const [isButtonDisable, setButtonDisable] = useState(false);
  const [isUpdateButtonDisable, setIsUpdateButtonDisable] = useState(false);
  const [sessionTypeList, setSessionTypeList] = useState([]);
  const [selectedSessionType, setSelectedSessionType] = useState();
  const [selectedVisibility, setSelectedVisibility] = useState();

  const [startTime, setStartTime] = useState(new Date("2023-08-21T15:00:00"));
  const [endTime, setEndTime] = useState(new Date("2023-08-21T15:00:00"));

  const [isStartButtonVisible, setIsStartButtonVisible] = useState(false);

  const [isAllButtonsVisible, setIsAllButtonsVisible] = useState(false);
  const [shortLink, setShortLink] = useState("");

  const [sessionParticipants, setSessionParticipants] = useState([]);
  const [totalParticipants, setTotalParticipants] = useState(0);
  const [hasMore, sethasMore] = useState(true);

  const getTimezoneList = (data) => {
    getTimezoneApi(data)
      .then((res) => {
        setTimezoneList(res?.data?.data?.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLanguageList = (data) => {
    getLanguageApi(data)
      .then((res) => {
        setLanguageList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCategoryList = (data) => {
    getCategoriesApi(data)
      .then((res) => {
        setCategoryList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSessionParticipants = (activeParticipantData, is_push_data) => {
    getSessionParticipantApi(activeParticipantData)
      .then((res) => {
        // const pageNo = activeParticipantData.page;
        if (is_push_data) {
          setSessionParticipants([
            ...sessionParticipants,
            ...res.data.data.rows,
          ]);
        } else {
          setSessionParticipants([...res.data.data.rows]);
        }

        setTotalParticipants(res.data.data.count);
        // setSessionParticipants([...sessionParticipants, ...res.data.data.rows]);
        if (res.data.data.rows.length == 0) {
          sethasMore(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFetchMoreData = () => {
    const data = {
      page: activeParticipantData.page + 1,
      limit: 10,
      sessionId: params.id,
    };
    getSessionParticipants(data, true);
    setActiveParticipantData(data);
  };

  const deleteParticipant = (participantId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "Warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        removeParticipant({ participantId: participantId })
          .then((res) => {
            Swal.fire({
              icon: "success",
              text: res.data.message,
              imageAlt: "success image",
            }).then(() => {
              const data = {
                page: 1,
                limit: 10,
                sessionId: params.id,
              };
              getSessionParticipants(data, false);
              setActiveParticipantData(data);
              sethasMore(true);
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const getSessionDetails = () => {
    getSessionDetailApi({ session_id: params.id })
      .then((res) => {
        setSessionDetails(res?.data?.data?.session);
        setFile(
          res?.data?.data?.session.bannerImageUrl === null
            ? BannerImage
            : res?.data?.data?.session.bannerImageUrl
        );

        const start_time = moment(
          res?.data?.data?.session.start_date_edit_format
        ).format("HH:mm:ss");
        const start_date = moment(
          res?.data?.data?.session.start_date_edit_format
        ).format("YYYY-MM-DD");
        const startDateTime = moment(`${start_date} ${start_time}`).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        const isUpdateButtonDisable = moment(startDateTime).isSameOrBefore(
          moment().format("YYYY-MM-DD HH:mm:ss")
        );
        setIsUpdateButtonDisable(isUpdateButtonDisable);
        const lang = res?.data?.data?.session?.languages.map((item) => {
          return {
            value: item.language_id,
            label: item.languageName,
          };
        });
        setlanguageIdsOptions(lang);

        const category = res?.data?.data?.session?.categories.map((item) => {
          return {
            value: item.category_id,
            label: item.categoryName,
          };
        });
        setcategoryIdsOptions(category);

        setSelectedTimeZone({
          value: res?.data?.data?.session?.timezone_id,
          label: res?.data?.data?.session?.timeZoneName,
        });

        const sessionType = res?.data?.data?.session?.sessionType?.map(
          (item) => {
            setFieldValue("session_type_id", item.id);
            return {
              value: item.id,
              label: item.name,
            };
          }
        );

        setSelectedSessionType(sessionType);

        const visibility = res?.data?.data?.session?.visibility;
        setSelectedVisibility({
          value: visibility,
          label: sessionVisibilityType[visibility],
        });

        const sessionStartDateTime = res?.data?.data?.session?.start_date_time;

        const currentDateTime = moment.utc().format("YYYY-MM-DD HH:mm:ss");
        const formattedDate = moment
          .utc(sessionStartDateTime)
          .format("YYYY-MM-DD HH:mm:ss");
        const before15Minutes = moment
          .utc(formattedDate)
          .subtract(15, "minutes")
          .format("YYYY-MM-DD HH:mm:ss");
        const showBefore15Minutes = moment
          .utc(currentDateTime)
          .isSameOrAfter(before15Minutes);
        setIsStartButtonVisible(showBefore15Minutes);

        const sessionStatus = res?.data?.data?.session?.status;

        if (sessionStatus == "past") {
          setIsAllButtonsVisible(false);
        } else if (sessionStatus == "upcoming") {
          setIsAllButtonsVisible(true);
        }

        const startEditTime = res?.data?.data?.session?.start_date_edit_format;
        const startEditTimeFormat = new Date(`2023-08-21T${startEditTime}`);
        setStartTime(startEditTimeFormat);

        const endEditTime = res?.data?.data?.session?.end_date_edit_format;
        const endEditTimeFormat = new Date(`2023-08-21T${endEditTime}`);
        setEndTime(endEditTimeFormat);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSessionTypesList = () => {
    getSessionTypesApi()
      .then((res) => {
        setSessionTypeList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTimezoneList(activeData);
    getLanguageList(activeData);
    getCategoryList(activeData);
    getSessionDetails();
    getSessionParticipants(activeParticipantData, false);
    getSessionTypesList();
  }, []);
  const handleTabClick = (tabContent) => {
    setActiveTab(tabContent);
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Session Title is required"),
    // date: Yup.string().required("Session Date is required"),
    // start_time: Yup.string().required("Start Time is required"),
    // end_time: Yup.string().required("End Time is required"),
    date: Yup.date().required("Session date is required"),
    start_time: Yup.string()
      .required("Start Time is required")
      .test(
        "is-greater",
        "Start time is must be greater than 15 minutes of current time",
        function (value) {
          const { date } = this.parent;

          const fifteenMinuteBefore = moment()
            .add(15, "minutes")
            .format("HH:mm:ss");
          const formattedValue = moment(value).format("HH:mm:ss");

          if (moment(date).isSame(moment(), "day")) {
            // return moment(value, "HH:mm:ss").isAfter(fifteenMinuteBefore);
            // return moment(formattedValue, "HH:mm:ss").isAfter(fifteenMinuteBefore, "HH:mm:ss");
            return formattedValue >= fifteenMinuteBefore;
          }

          return true;
        }
      ),
    end_time: Yup.string()
      .required("End Time is required")
      .test(
        "is-greater",
        "End Time is must be greater than Start Time",
        function (value) {
          const start = this.parent.start_time;

          return start < value;
        }
      ),
    timezone_id: Yup.string().required("Timezone is required"),
    participate_limit: Yup.number()
      .typeError("Enter number only")
      .required("Max Participate Limit is required")
      .min(1, "Max Participate Limit must be at least 1")
      .max(100, "Max Participate Limit must be at most 100"),
    languageIds: Yup.array().min(1, "Select at least one Language"),
    about: Yup.string().required("About Session is required"),
    meeting_link: Yup.string()
      .required("Meeting Link is required")
      .url("Enter valid url"),
    categoryIds: Yup.array()
      .min(1, "Select at least one Category")
      .required("Category is required"),
    // banner_image: Yup.mixed()
    //   .required("Please select an image")
    //   .test(
    //     "fileType",
    //     "Invalid image file type. Please choose a JPEG, PNG, or GIF file.",
    //     (value) => {
    //       if (value) {
    //         const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    //         return allowedTypes.includes(value.type);
    //       }
    //       return true;
    //     }
    //   ),
    session_type_id: Yup.string().required("Session Type is required"),
    visibility: Yup.string().required("Visibility is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: sessionDetails ? sessionDetails?.title : "",
      date: sessionDetails
        ? new Date(
            moment.utc(sessionDetails?.start_date_time).format("YYYY-MM-DD")
          )
        : "",
      start_time: sessionDetails ? startTime : "",
      end_time: sessionDetails ? endTime : "",
      timezone_id: sessionDetails ? sessionDetails?.timezone_id : "",
      participate_limit: sessionDetails
        ? sessionDetails?.participate_limit
        : "",
      languageIds: sessionDetails?.languages?.map((item, index) => {
        return item.language_id;
      }),
      about: sessionDetails ? sessionDetails?.about : "",
      meeting_link: sessionDetails ? sessionDetails?.meeting_link : "",
      categoryIds: sessionDetails?.categories?.map((item, index) => {
        return item.category_id;
      }),
      banner_image: null,
      session_type_id: sessionDetails ? sessionDetails?.session_type_id : "",
      visibility: sessionDetails ? sessionDetails?.visibility : "",
    },
    validationSchema,
    onSubmit: (values) => {
      setButtonDisable(true);
      const data = new FormData();

      const start_date = moment(values.date).format("YYYY-MM-DD");
      const start_time = moment(values.start_time).format("HH:mm:ss");
      const end_time = moment(values.end_time).format("HH:mm:ss");

      data.append("session_id", params.id);
      data.append("title", values.title);
      data.append("date", start_date);
      data.append("start_time", start_time);
      data.append("end_time", end_time);
      data.append("timezone_id", values.timezone_id);
      data.append("participate_limit", values.participate_limit);
      for (let i = 0; i < values.languageIds.length; i++) {
        data.append("languageIds", values.languageIds[i]);
      }
      for (let i = 0; i < values.categoryIds.length; i++) {
        data.append("categoryIds", values.categoryIds[i]);
      }
      data.append("about", values.about);
      data.append("meeting_link", values.meeting_link);
      data.append("banner_image", values.banner_image);
      data.append("session_type_id", values.session_type_id);
      data.append("visibility", values.visibility);

      updateSessionApi(data)
        .then((res) => {
          if (res?.data?.success) {
            formik.resetForm();
            toast.success(res?.data?.message, {
              position: "top-right",
              autoClose: 700,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });

            setTimeout(() => {
              window.location.href = "/my-sessions";
            }, 1500);
          } else {
            toast.error(res?.data?.message, {
              position: "top-right",
              autoClose: 700,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
          setButtonDisable(false);
        })
        .catch((err) => {
          setButtonDisable(false);
          console.log(err);
        });
    },
  });

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = formik;

  const handleFileChange = (e) => {
    e.preventDefault();
    setFile(URL.createObjectURL(e.target.files[0]));
    setFieldValue("banner_image", e.target.files[0]);
  };

  const handleRemoveFile = () => {
    setFile(null);
    setFieldValue("banner_image", null);
  };

  const handleTextAreaChange = (event) => {
    setFieldValue("about", event.target.value);
    adjustTextAreaHeight(event.target);
  };

  const adjustTextAreaHeight = (textarea) => {
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const deleteSession = () => {
    removeSessionApi({ session_id: params.id })
      .then((res) => {
        if (res?.data?.success) {
          toast.success(res?.data?.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setTimeout(() => {
            navigate("/my-sessions");
          }, 1500);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSessionStartNotification = () => {
    sendSessionStartNotificationApi({ session_id: params.id }).then((res) => {
      if (res?.data?.success) {
        toast.success(res?.data?.message, {
          position: "top-right",
          autoClose: 700,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(res?.data?.message, {
          position: "top-right",
          autoClose: 700,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    });
  };

  const handleShortLink = () => {
    createShortDynamicLink("session-info", params.id)
      .then((res) => {
        setShortLink(res?.shortLink);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Layout>
      <section className="main mt-5 py-5">
        <ToastContainer />
        <div className="container px-8">
          <div className="row">
            <div className="inner-block">
              <a href="/my-sessions">
                <div className="icon-back mb-3">
                  {" "}
                  <i className="fa fa-arrow-left"></i>
                </div>
              </a>
              <ul className="nav nav-tabs d-flex align-items-center justify-content-center mx-auto">
                <CustomTab
                  active={activeTab === "session-details"}
                  tabContent="Session Details"
                  onClick={() => handleTabClick("session-details")}
                />
                <CustomTab
                  active={activeTab === "participants"}
                  tabContent="Participants "
                  onClick={() => handleTabClick("participants")}
                  count={totalParticipants === 0 ? "" : totalParticipants}
                />
              </ul>

              <div className="clearfix"></div>
              <br />

              <div className="tab-content">
                {activeTab === "session-details" && (
                  <div
                    className="tab-pane container active  p-0"
                    id="session-details"
                  >
                    {sessionDetails &&
                      Object.keys(sessionDetails)?.length > 0 && (
                        <div className="card bg-white px-5 py-4">
                          <form
                            className="session-form"
                            onSubmit={formik.handleSubmit}
                          >
                            <div className="row">
                              <div className="col-md-6">
                                <FormInput
                                  name="title"
                                  type="text"
                                  label="Session Title"
                                  placeholder="Enter Session Title"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.title}
                                  error={errors.title}
                                  touched={touched.title}
                                  isDisabled={!isAllButtonsVisible}
                                />
                              </div>
                              <div className="col-md-6">
                                <DatePickerField
                                  name="date"
                                  label="Session Date"
                                  value={values.date}
                                  onChange={(date) =>
                                    setFieldValue("date", date)
                                  }
                                  //error={errors.date}
                                  touched={touched.date}
                                  placeholder="Select Date"
                                  onBlur={handleBlur}
                                  minDate={url.getTodaysDate()}
                                  isDisabled={!isAllButtonsVisible}
                                />
                                {errors.date && touched.date && (
                                  <div
                                    className="text-danger"
                                    style={{ marginTop: "-20px" }}
                                  >
                                    {errors.date}
                                  </div>
                                )}
                              </div>
                              <div className="col-md-3">
                                <div className="form-outline mb-4">
                                  <label className="form-label mb-0" htmlFor="">
                                    Start Time
                                  </label>
                                  <div className="ui calendar timepicker">
                                    <div className="d-flex align-items-center time right icon">
                                      <DatePicker
                                        name="start_time"
                                        className="form-control"
                                        selected={values.start_time}
                                        onChange={(date) =>
                                          setFieldValue("start_time", date)
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        dateFormat="h:mm aa"
                                        placeholderText="Select Time"
                                        disabled={!isAllButtonsVisible}
                                      />

                                      <i className="fa fa-clock-o"></i>
                                    </div>
                                    {errors.start_time &&
                                      touched.start_time && (
                                        <div className="text-danger">
                                          {errors.start_time}
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-outline mb-4">
                                  <label className="form-label mb-0" htmlFor="">
                                    End Time
                                  </label>
                                  <div className="ui calendar timepicker">
                                    <div className="d-flex align-items-center time right icon">
                                      <DatePicker
                                        name="end_time"
                                        className="form-control"
                                        selected={values.end_time}
                                        onChange={(date) =>
                                          setFieldValue("end_time", date)
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        dateFormat="h:mm aa"
                                        placeholderText="Select Time"
                                        disabled={!isAllButtonsVisible}
                                      />

                                      <i className="fa fa-clock-o"></i>
                                    </div>
                                    {errors.end_time && touched.end_time && (
                                      <div className="text-danger">
                                        {errors.end_time}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-outline mb-4">
                                  <label className="form-label mb-0" htmlFor="">
                                    Select Time Zone
                                  </label>
                                  <Select
                                    name="timezone_id"
                                    className=""
                                    id="multiple-select-field5"
                                    placeholder="Select Time Zone"
                                    options={timezoneList?.map((item) => ({
                                      value: item.id,
                                      label: item.timezone,
                                    }))}
                                    onChange={(e) => {
                                      setFieldValue("timezone_id", e.value);
                                      setSelectedTimeZone(e);
                                    }}
                                    value={selectedTimeZone}
                                    //value={values.timezone_id}
                                    isDisabled={!isAllButtonsVisible}
                                  />
                                  {errors.timezone_id &&
                                    touched.timezone_id && (
                                      <div className="text-danger">
                                        {errors.timezone_id}
                                      </div>
                                    )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <FormInput
                                  name="participate_limit"
                                  type="text"
                                  label="Max Participate Limit(1-100)"
                                  placeholder="Enter Max Participate Limit"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.participate_limit}
                                  error={errors.participate_limit}
                                  touched={touched.participate_limit}
                                  isDisabled={!isAllButtonsVisible}
                                />
                              </div>
                              <div className="col-md-6">
                                <div className="form-outline mb-4">
                                  <label className="form-label mb-0" htmlFor="">
                                    Language for the session (Choose from list)
                                  </label>
                                  <Select
                                    name="languageIds"
                                    className=""
                                    id="multiple-select-field6"
                                    placeholder="Select Languages"
                                    isMulti
                                    options={languageList?.map((item) => ({
                                      value: item.id,
                                      label: item.name,
                                    }))}
                                    isDisabled={!isAllButtonsVisible}
                                    // onChange={(selectedOption) =>
                                    //   setFieldValue("languages", selectedOption)
                                    // }
                                    onChange={(e) => {
                                      const f = e.map((item, index) => {
                                        return item.value;
                                      });
                                      setFieldValue("languageIds", f);
                                      setlanguageIdsOptions(e);
                                    }}
                                    value={selectedLanguageIds}
                                  />
                                  {errors.languageIds &&
                                    touched.languageIds && (
                                      <div className="text-danger">
                                        {errors.languageIds}
                                      </div>
                                    )}
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-outline mb-4">
                                  <label className="form-label mb-0" htmlFor="">
                                    Session Type (Choose from list)
                                  </label>
                                  <Select
                                    name="session_type_id"
                                    className=""
                                    id="multiple-select-field6"
                                    placeholder="Select Session Type"
                                    options={sessionTypeList?.map((item) => ({
                                      value: item.id,
                                      label: item.name,
                                    }))}
                                    onChange={(selectedOption) => {
                                      console.log(selectedOption);
                                      const selectedValue =
                                        selectedOption?.value;
                                      setSelectedSessionType(selectedOption);
                                      setFieldValue(
                                        "session_type_id",
                                        selectedValue
                                      );
                                    }}
                                    value={selectedSessionType || []}
                                  />
                                  {errors.session_type_id &&
                                    touched.session_type_id && (
                                      <div className="text-danger">
                                        {errors.session_type_id}
                                      </div>
                                    )}
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-outline mb-4">
                                  <label className="form-label mb-0" htmlFor="">
                                    Visibility
                                  </label>
                                  <Select
                                    name="visibility"
                                    className=""
                                    id="multiple-select-field6"
                                    placeholder="Select Session Visibility"
                                    options={Object.keys(
                                      sessionVisibilityType
                                    ).map((item) => ({
                                      value: item,
                                      label: sessionVisibilityType[item],
                                    }))}
                                    onChange={(selectedOption) => {
                                      console.log(selectedOption);
                                      const selectedValue =
                                        selectedOption?.value;
                                      setSelectedVisibility(selectedOption);
                                      setFieldValue(
                                        "visibility",
                                        selectedValue
                                      );
                                    }}
                                    value={selectedVisibility || []}
                                  />
                                  {errors.visibility && touched.visibility && (
                                    <div className="text-danger">
                                      {errors.visibility}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-12">
                                <label className="form-label mb-0" htmlFor="">
                                  About the Session
                                </label>
                                <textarea
                                  className="form-control"
                                  name="about"
                                  type="textarea"
                                  label="About the Session"
                                  placeholder="Enter About the Session"
                                  onChange={handleTextAreaChange}
                                  onBlur={handleBlur}
                                  value={values.about}
                                  // error={errors.about}
                                  touched={touched.about}
                                  disabled={!isAllButtonsVisible}
                                />
                                {errors.about && touched.about && (
                                  <div className="text-danger">
                                    {errors.about}
                                  </div>
                                )}
                                {/* <FormInput
                            name="about"
                            type="textarea"
                            label="About the Session"
                            placeholder="Enter About the Session"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.about}
                            error={errors.about}
                            touched={touched.about}
                          /> */}
                              </div>
                              <div className="col-md-12">
                                <br></br>
                                <FormInput
                                  name="meeting_link"
                                  type="text"
                                  label="Meeting Link ( meet/zoom/teams/or any platform you prefer"
                                  placeholder="Enter Meeting Link"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.meeting_link}
                                  error={errors.meeting_link}
                                  touched={touched.meeting_link}
                                  isDisabled={!isAllButtonsVisible}
                                />
                              </div>
                              <div className="col-md-12">
                                <Select
                                  name="categoryIds"
                                  className=""
                                  id="multiple-select-field6"
                                  placeholder="Select Topics"
                                  isMulti
                                  options={categoryList?.map((item) => ({
                                    value: item.id,
                                    label: item.name,
                                  }))}
                                  onChange={(e) => {
                                    const f = e.map((item, index) => {
                                      return item.value;
                                    });
                                    setFieldValue("categoryIds", f);
                                    setcategoryIdsOptions(e);
                                  }}
                                  value={selectedCategoryIds}
                                  isDisabled={!isAllButtonsVisible}
                                />
                                {errors.categoryIds && touched.categoryIds && (
                                  <div className="text-danger">
                                    {errors.categoryIds}
                                  </div>
                                )}
                              </div>
                              <div className="col-md-12">
                                <div className="form-outline mb-4">
                                  <label className="form-label mb-0" htmlFor="">
                                    Image or Banner
                                  </label>
                                  {errors.banner_image &&
                                    touched.banner_image && (
                                      <div className="text-danger">
                                        {errors.banner_image}
                                      </div>
                                    )}
                                  <div className="file-upload">
                                    <div
                                      className="image-upload-wrap"
                                      style={{
                                        display: `${file ? "none" : ""}`,
                                      }}
                                    >
                                      <input
                                        name="image"
                                        className="file-upload-input"
                                        type="file"
                                        accept="image/*"
                                        onChange={handleFileChange}
                                      />

                                      <div className="drag-text d-flex align-items-center justify-content-center flex-column">
                                        <img
                                          src={uploadIcon}
                                          alt="preview-icon"
                                          width="24"
                                          height="24"
                                        />
                                        <h3 className="mb-0">
                                          Upload a file{" "}
                                          <span>or drag and drop</span>
                                        </h3>
                                        <span>
                                          PNG, JPG, GIF, PDF, Upto 5MB
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      className="file-upload-content"
                                      style={{
                                        display: `${file ? "block" : ""}`,
                                      }}
                                    >
                                      <img
                                        className="file-upload-image"
                                        src={file}
                                        alt="your image"
                                      />

                                      <div className="image-title-wrap">
                                        <button
                                          type="button"
                                          onClick={handleRemoveFile}
                                          className="remove-image"
                                        >
                                          <img
                                            src={CloseIcon}
                                            alt="close-icon"
                                            width="18"
                                            height="18"
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {isAllButtonsVisible && (
                                  <>
                                    <div className="col-md-12">
                                      <div className="session-btn-list d-flex align-items-center justify-content-center my-4">
                                        <a
                                          onClick={() => formik.handleSubmit()}
                                          className={`btn btn-save d-flex align-items-center ${
                                            isUpdateButtonDisable
                                              ? "update-button-disable"
                                              : ""
                                          }`}
                                        >
                                          <i className="fa fa-save me-2"></i>{" "}
                                          Save
                                        </a>

                                        <Link
                                          to={values.meeting_link}
                                          target="_blank"
                                          className="btn btn-start d-flex align-items-center"
                                          onClick={() =>
                                            handleSessionStartNotification()
                                          }
                                        >
                                          <i className="fa fa-play me-2"></i>{" "}
                                          Start Session
                                        </Link>

                                        <Link
                                          onClick={() => deleteSession()}
                                          className="btn btn-delete d-flex align-items-center"
                                        >
                                          <i className="fa fa-trash me-2"></i>{" "}
                                          Delete
                                        </Link>

                                        <Link
                                          onClick={() => handleShortLink()}
                                          className="btn btn-share d-flex align-items-center"
                                          data-bs-target="#shareModal"
                                          data-bs-toggle="modal"
                                        >
                                          <i
                                            className="fa fa-share-alt me-2"
                                            style={{
                                              color: "#0e5dd2",
                                              textDecoration: "none",
                                            }}
                                          ></i>{" "}
                                          Share
                                        </Link>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </form>
                        </div>
                      )}
                  </div>
                )}

                {sessionParticipants.length === 0 ? (
                  <>
                    {activeTab == "participants" && (
                      <>
                        <div className="col-md-12 d-flex justify-content-center">
                          <div className="no-data">
                            <img
                              src={NoDataImage}
                              alt="no-data"
                              width="300"
                              height="300"
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {sessionParticipants.length > 0 &&
                      activeTab == "participants" && (
                        <InfiniteScroll
                          dataLength={sessionParticipants?.length}
                          next={handleFetchMoreData}
                          hasMore={hasMore}
                        >
                          {activeTab == "participants" && (
                            <div
                              className="tab-pane container active show p-0"
                              id="participants"
                            >
                              {sessionParticipants?.map(
                                (participants, index) => (
                                  <SessionParticipants
                                    key={index}
                                    participantId={participants.id}
                                    data={participants}
                                    onDelete={(participantId) => {
                                      deleteParticipant(participantId);
                                    }}
                                    isDisabled={isAllButtonsVisible}
                                  />
                                )
                              )}
                            </div>
                          )}
                        </InfiniteScroll>
                      )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <InviteSessionModal />

      <ShareModal shareableLink={shortLink} />
    </Layout>
  );
};

export default SessionDetail;
