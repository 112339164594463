import React from "react";

const SectionHeader = ({ title }) => {
  return (
    <div class="title-header text-center">
      <h2 class="title">{title}</h2>
    </div>
  );
};

export default SectionHeader;
