import React from "react";
import AvatarImage from "../../../assets/images/profile-placeholder.png";
import { Link } from "react-router-dom";
import { handleUserImageError } from "helpers/common_helper";

const TakeSessionCard = ({
  title,
  username,
  description,
  userimage,
  sessionId,
  onView,
  userprofileName,
  sessionStatus,
  datetime,
}) => {
  const handleView = () => {
    onView(sessionId);
  };

  return (
    <div className="card mb-4">
      <div className="card-body py-2 px-4">
        <div className="d-flex align-items-center justify-content-between pb-2 user-r">
          <div className="d-flex align-items-center">
            <div className="me-5 text-center">
              <Link to={`/user-profile/${userprofileName}`}>
                <img
                  src={userimage === null ? AvatarImage : userimage}
                  className="rounded-circle mb-2 img-property-fit"
                  alt="user"
                  width="40"
                  height="40"
                  onError={handleUserImageError}
                />
              </Link>
              <br />
              <Link to={`/user-profile/${userprofileName}`}>
                <h5 className="mb-0">{username?.length > 14 ? `${username?.substring(0, 14)}...` : username}</h5>
              </Link>
            </div>
            <div className="border-left">
              <h6 className="mb-0">{title}</h6>
              <p className="mb-0">
                {description?.length > 75
                  ? description?.substring(0, 75) + "..."
                  : description}
              </p>

              <p>{datetime}</p>
            </div>
          </div>
          <div className="ms-auto d-flex align-items-center">
            <div className="status-label-accepted me-2">{sessionStatus}</div>

            <Link
              className="btn btn-accept"
              data-bs-target="#takeSession"
              data-bs-toggle="modal"
              onClick={handleView}
            >
              View
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TakeSessionCard;
