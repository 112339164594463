import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatePickerField = ({ name, label, value, placeholder, onChange, onBlur, error, touched, minDate, isDisabled }) => {
  return (
    <div className="form-outline mb-4">
      <label className="form-label mb-0">{label}</label>
      <div className="d-flex align-items-center right icon">
        <DatePicker
          name={name}
          className="form-control"
          selected={value || null}
          onChange={onChange}
          onBlur={onBlur}
          placeholderText={placeholder}
          dateFormat="MMMM d, yyyy"
          minDate={minDate}
          disabled={isDisabled}
        />
        <i className="fa fa-calendar"></i>
        {error && touched && <div className="text-danger">{error}</div>}
      </div>
    </div>
  );
}; 

export default DatePickerField;
