import { verifyEmailApi } from "api/authApi";
import FilledButton from "components/ui/Common/FilledButton";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const EmailVerification = () => {
  const params = useParams();

  const [response, setResponse] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const token = params.token;

  const verifyEmail = () => {
    verifyEmailApi({ token: token })
      .then((res) => {
        if (res?.data?.success) {
          setResponse("Email Verified Successfully");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsVerified(true);
      });
  };
  useEffect(() => {
    if (!isVerified) {
      verifyEmail();
    }
  }, [isVerified]);

  return (
    <section className="vh-100 login">
      <div className="container-fluid h-custom">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-md-4">
            <div className="card">
              <div className="card-body p-4">
                <div className="text-center mb-4">
                  <h3 className="text-black">Email Verification</h3>
                  <p>{response}</p>

                  <div className="mt-4">
                    <FilledButton
                      title="Back to Home"
                      handleSubmit={() => (window.location.href = "/")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EmailVerification;
