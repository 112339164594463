import React from "react";
import AvatarImage from "../../../assets/images/profile-placeholder.png";
import { Link } from "react-router-dom";
import { handleUserImageError } from "helpers/common_helper";

const UserRequestCard = ({
  title,
  datetime,
  username,
  designation,
  userimage,
  requestId,
  onAccept,
  onReject,
  status,
  userprofileName
}) => {
  const handleAccept = () => {
    onAccept(requestId);
  };

  const handleReject = () => {
    onReject(requestId);
  };

  return (
    <div className="card mb-4">
      <div className="card-body py-2 px-4">
        
        
        
        <div className="d-flex align-items-center justify-content-between pt-2 user-r">
          <div className="d-flex align-items-center">
            <div className="me-2">
              <Link to={`/user-profile/${userprofileName}`}>
              <img
                src={userimage === null ? AvatarImage : userimage}
                className="rounded-circle img-property-fit"
                alt="user"
                width="25"
                height="25"
                onError={handleUserImageError}
              />
              </Link>
            </div>
            <div>
              <Link to={`/user-profile/${userprofileName}`}>
              <h5 className="mb-0">{username}</h5>
              </Link>
              {/* <span>{designation}</span> */}
            </div>
          </div>
          <div className="d-flex">
            {status === "Pending" && (
              <>
                <div className="me-3">
                  <Link className="btn btn-accept" onClick={handleAccept}>
                    Accept
                  </Link>
                </div>
                <div>
                  <Link className="btn btn-reject" onClick={handleReject}>
                    Reject
                  </Link>
                </div>
              </>
            )}

            {status === "Accepted" && (
              <>
                <div className="me-3 status-label-accepted">
                  Accepted
                </div>
              </>
            )}

            {status === "Rejected" && (
              <>
                <div className="me-3 status-label-rejected">
                  Rejected
                </div>
                <div>
                <Link className="btn btn-accept" onClick={handleAccept}>
                    Accept
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>

        <h4 className="mb-0 mt-0">
          <a>{title}</a>
        </h4>
        <p className="mb-0">{datetime}</p>

      </div>
    </div>
  );
};

export default UserRequestCard;
