import config from "../configurations/config";
import { postMethodCall } from "./apiHandler";

export const getSessionInvitesApi = async (payload) => {
  return await postMethodCall(`${config.backendApiUrl}/api/user/get-session-invites`, payload);
};

export const sendSessionInviteApi = async (payload) => {
    return await postMethodCall(`${config.backendApiUrl}/api/user/send-session-invites`, payload);
  };
