const initialState = {
    start_date: '',
    end_date: '',
    categoryFilter: '',
    languageFilter: '',
    isFormSubmitted: false,
  };
  
  const filterReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_FILTER_VALUES':
        return {
          ...state,
          ...action.payload,
        };
        case 'SET_FORM_SUBMITTED':
      return {
        ...state,
        isFormSubmitted: action.payload,
      };
      default:
        return state;
    }
  };
  
  export default filterReducer;
  