import { getBookmarkListApi } from "api/bookmarkApi";
import { isUserLoggedIn } from "helpers/common_helper";
import Layout from "layouts/Index";
import SidebarMenu from "layouts/SidebarMenu";
import React, { useEffect, useState } from "react";
import NoDataImage from "../../assets/images/nodatafound.gif";
import SessionCardV2 from "pages/Dashboard/SessionCardV2";
import { UTCDateFormat } from "helpers/common_helper";
import { UTCTimeFormat } from "helpers/common_helper";
import SessionDetailModal from "pages/Dashboard/Modal/SessionDetailModal";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

const Bookmark = () => {
  const [bookmarkList, setBookmarkList] = useState([]);
  const [selectedSession, setSelectedSession] = useState(null);
  const [activeData, setActiveData] = useState({
    page: 1,
    limit: 10,
  });
  const [loading, setLoading] = useState(false);

  const isMobileView = useMediaQuery({ query: "(max-width: 767px)" });

  const getBookmarkList = (data) => {
    if (activeData.limit <= 10) {
      setLoading(true);
    }
    getBookmarkListApi(data)
      .then((res) => {
        setBookmarkList(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSessionClick = (sessionId) => {
    setSelectedSession(sessionId);
  };

  const handleFetchMoreData = () => {
    setActiveData({
      ...activeData,
      limit: activeData.limit + 6,
    });
  };

  useEffect(() => {
    getBookmarkList(activeData);
  }, []);


  return (
    <Layout>
      <section className={`${isMobileView ? "main mt-3" : "main mt-5 py-5"}`}>
        <div className="container-fluid container-fluid-p">
          <div className="row">
          {!isMobileView && (
                <div
                className={`col-md-2 pt-5 left-block sticky-sidebar ${
                  isUserLoggedIn() ? "" : "locked"
                }`}
              >
                <SidebarMenu />
              </div>
              )}

            <div className="col-md-10 right-block">
              <div className="content-list">
                <InfiniteScroll
                  dataLength={bookmarkList?.length}
                  next={handleFetchMoreData}
                  hasMore={true}
                >
                  <div className="row">
                    {loading ? (
                      <>
                        <div className="justify-content-center d-flex align-items-center">
                          <div className="loadingio-spinner-rolling-26k4myxj9b9">
                            <div className="ldio-y9y7x49yybs">
                              <div></div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {bookmarkList?.length === 0 ? (
                          <>
                            <div className="col-md-12 d-flex justify-content-center">
                              <div className="no-data">
                                <img
                                  src={NoDataImage}
                                  alt="no-data"
                                  width=""
                                  height=""
                                />
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {bookmarkList?.map((session, index) => (
                              <SessionCardV2
                                title={session?.title}
                                datetime={`${UTCDateFormat(
                                  session?.start_date_time
                                )} ${UTCTimeFormat(session?.start_date_time)}`}
                                language={session?.languages[0]?.languageName}
                                backgroundImage={session?.bannerImageUrl}
                                userImage={session?.user?.profileImageUrl}
                                userName={`${session?.user?.first_name} ${session?.user?.last_name}`}
                                sessionType={session?.sessionTypeName}
                                sessionVisibility={session?.type}
                                handleSessionClick={() =>
                                  handleSessionClick(session.id)
                                }
                                user_name={session?.user?.user_name}
                                session_id={session?.id}
                                sessionTypeColor={session?.sessionTypeColor}
                                participantLimit={session?.participate_limit}
                                participantCount={session?.participant_count}
                              />
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SessionDetailModal session_id={selectedSession} />
    </Layout>
  );
};

export default Bookmark;
