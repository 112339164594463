import React from "react";
import BannerImage from "../../../assets/images/colorbg.jpg";
import { Link } from "react-router-dom";
import { handleImageError } from "helpers/common_helper";

function EventCard({
  startDate,
  endTime,
  title,
  username,
  bannerImage,
  sessionStatus,
  handleSessionClick,
  handleCancelRequest,
  activeTab,
  userprofileName,
  session_id,
}) {
  return (
    <div className="tab-pane container active" id="all">
      <div className="row">
        <div className="col-md-12">
          <div className="card mb-4">
            <div className="card-body py-2 px-4 join-list">
              <div className="">
                <div className="date-time">
                  <span className="me-2">{`${startDate} TO`}</span>
                  <span>{endTime}</span>
                </div>
                <div className="d-flex align-items-center pt-5 py-3">
                  <div className="me-4 join-list-datetime">
                    <img
                      src={bannerImage === null ? BannerImage : bannerImage}
                      className="j-img img-property-fit"
                      alt="bannerimage"
                      width="68"
                      height="68"
                      onError={handleImageError}
                    />
                  </div>
                  <div>
                    <Link
                      to={`/session-info/${session_id}`}
                    >
                      <h5 className="mb-0">
                        {title?.length > 60
                          ? `${title?.substring(0, 60)}...`
                          : title}
                      </h5>
                    </Link>

                    <Link to={`/user-profile/${userprofileName}`}>
                      <span className="name">
                        By{" "}
                        {username?.length > 40
                          ? `${username?.substring(0, 40)}...`
                          : username}
                      </span>
                    </Link>
                    <div>
                      {sessionStatus == "Pending" && (
                        <span style={{ color: "orange" }}>{sessionStatus}</span>
                      )}
                      {sessionStatus == "Accepted" && (
                        <span style={{ color: "green" }}>{sessionStatus}</span>
                      )}
                      {sessionStatus == "Rejected" && (
                        <span style={{ color: "red" }}>{sessionStatus}</span>
                      )}
                      {sessionStatus == "Invited" && (
                        <span style={{ color: "blue" }}>{sessionStatus}</span>
                      )}
                    </div>
                  </div>
                  <div className="ms-auto d-flex align-items-center">
                    {activeTab == "upcoming" && sessionStatus == "Pending" && (
                      <>
                        <Link
                          className="btn btn-send3 me-2"
                          onClick={() => handleCancelRequest()}
                        >
                          Cancel Request
                        </Link>
                      </>
                    )}

                    {activeTab == "upcoming" && sessionStatus == "Accepted" && (
                      <>
                        <Link
                          className="btn btn-send3 me-2"
                          onClick={() => handleCancelRequest()}
                        >
                          Cancel Request
                        </Link>
                      </>
                    )}

                    <button
                      className="btn btn-accept"
                      onClick={()=> window.location.href=`/session-info/${session_id}`}
                    >
                      View
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventCard;
