import { isUserLoggedIn } from "helpers/common_helper";
import config from "../configurations/config";
import { getMethodCall, postFormMethodCall, postMethodCall, putMethodCall } from "./apiHandler";

export const profileDetailsApi = async (payload) => {
  if(isUserLoggedIn()){
    return await getMethodCall(`${config.backendApiUrl}/api/user/userProfile/${payload.username}`, payload);
  }
  return await getMethodCall(`${config.backendApiUrl}/api/user/open/userProfile/${payload.username}`, payload);
};

export const updateProfileApi = async (payload) => {
  return await putMethodCall(`${config.backendApiUrl}/api/user/updateprofile`, payload);
};

export const checkOnboardingApi = async (payload) => {
  return await getMethodCall(`${config.backendApiUrl}/api/user/checkprofileupdated`, payload);
};

export const updateProfileImageApi = async (payload) => {
  return await postFormMethodCall(`${config.backendApiUrl}/api/user/updateprofileimage`, payload);
};

export const getAllUsersApi = async (payload) => {
  return await postMethodCall(`${config.backendApiUrl}/api/user/get-all-users`, payload);
};

export const checkNameAvailableApi = async (payload) => {
  return await postMethodCall(`${config.backendApiUrl}/api/user/is-name-available`, payload);
};