const initialState = {
  isLogged: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "IS_LOGGED_IN":
      return { ...state, isLogged: true };
    default:
      return state;
  }
};

export default authReducer;
