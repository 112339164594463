import ProfileInfo from "components/ui/Common/ProfileInfo";
import { isUserLoggedIn } from "helpers/common_helper";
import Layout from "layouts/Index";
import SidebarMenu from "layouts/SidebarMenu";
import ProfileHeader from "pages/Dashboard/ProfileHeader";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AvatarImage from "../../assets/images/profile-placeholder.png";
import NotificationCard from "components/ui/Common/NotificationCard";
import { getNotificationsApi } from "api/notificationApi";
import { convertDateToFromNow } from "helpers/common_helper";
import InfiniteScroll from "react-infinite-scroll-component";
import NoDataImage from "../../assets/images/nodatafound.gif";
import { useMediaQuery } from "react-responsive";

const Notification = () => {
  const profile = useSelector((state) => state.profile.profile);

  const [notificationList, setNotificationList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeData, setActiveData] = useState({
    page: 1,
    limit: 10,
  });

  const profileTopList = [
    {
      title: "Sessions",
      value: profile?.sessionCount,
    },
    {
      title: "Followers",
      value: profile?.followerCount,
    },
    {
      title: "Following",
      value: profile?.followingCount,
    },
  ];

  const profileTopListUnknown = [
    {
      title: "Sessions",
      value: 0,
    },
    {
      title: "Followers",
      value: 0,
    },
    {
      title: "Following",
      value: 0,
    },
  ];

  const isMobileView = useMediaQuery({ query: "(max-width: 767px)" });

  const getNotificationsList = (data) => {
    if (activeData.limit <= 10) {
      setLoading(true);
    }
    getNotificationsApi(data)
      .then((res) => {
        setNotificationList(res?.data?.notifications?.rows);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getNotificationsList(activeData);
  }, [activeData]);

  const handleFetchMoreData = () => {
    setActiveData({
      ...activeData,
      limit: activeData.limit + 5,
    });
  };

  return (
    <Layout>
      <section className={`${isMobileView ? "main" : "main mt-5 py-5"}`}>
        <div className="container-fluid container-fluid-p">
          <div className="row">
          {!isMobileView && (
                <div
                className={`col-md-2 pt-5 left-block sticky-sidebar ${
                  isUserLoggedIn() ? "" : "locked"
                }`}
              >
                <SidebarMenu />
              </div>
              )}

            <div className="col-md-10 right-block">
              <div className="tab-content mobile-tab-content">
                {loading ? (
                  <>
                    <div className="justify-content-center d-flex align-items-center">
                      <div className="loadingio-spinner-rolling-26k4myxj9b9">
                        <div className="ldio-y9y7x49yybs">
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <InfiniteScroll
                      dataLength={notificationList?.length}
                      next={handleFetchMoreData}
                      hasMore={true}
                    >
                      <div
                        className="tab-pane container active"
                        id="New-Participant-Request"
                      >
                        <div className="row">
                          <div className="col-md-12 pr-0">
                            {/* Card */}
                            {notificationList?.length === 0 ? (
                              <>
                                <div className="col-md-12 d-flex justify-content-center">
                                  <div className="no-data">
                                    <img
                                      src={NoDataImage}
                                      alt="no-data"
                                      width="400"
                                      height="400"
                                    />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                {notificationList &&
                                  notificationList?.map(
                                    (notification, index) => (
                                      <NotificationCard
                                        key={index}
                                        userImage={
                                          notification?.fromUser
                                            ?.profile_image === null
                                            ? AvatarImage
                                            : notification?.fromUser
                                                ?.profile_image
                                        }
                                        title={notification?.title}
                                        message={notification?.message}
                                        datetime={convertDateToFromNow(
                                          notification?.created_at
                                        )}
                                      />
                                    )
                                  )}
                              </>
                            )}

                            {/* Card */}
                          </div>
                        </div>
                      </div>
                    </InfiniteScroll>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Notification;
