import Layout from "layouts/Index";
import React, { useEffect, useState } from "react";
import AvatarImage from "../../assets/images/profile-placeholder.png";
import VMenuImage from "../../assets/images/v-menu.png";
import CustomTab from "components/ui/Common/CustomTab";
import HashtagList from "components/ui/Common/HashtagList";
import SessionItemCard from "components/ui/Common/SessionItemCard";
import TopVerticalList from "components/ui/Common/TopVeticalList";
import EditProfileModal from "pages/Dashboard/Modal/EditProfileModal";
import CreateSessionModal from "pages/Dashboard/Modal/CreateSessionModal";
import { getMySessionspi, removeSessionApi } from "api/sessionApi";
import * as url from "../../helpers/common_helper";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { fetchProfile } from "actions/profileActions";
import { logEvent } from "firebase/analytics";
import analytics from "index";
import NoDataImage from "../../assets/images/nodatafound.gif";
import NoSessionImage from "../../assets/images/no_session_image.png";
import ShareIcon from "../../assets/images/shareicon.svg";
import ShareModal from "components/Utilities/ShareModal";
import createShortDynamicLink from "services/DynamicLink/shortLink";
import { useMediaQuery } from "react-responsive";
import moment from "moment";

const MySession = ({ profile, error, fetchProfile }) => {
  const [activeTab, setActiveTab] = useState("upcoming-session");
  const [shortLink, setShortLink] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [upcomingSessionDetails, setUpcomingSessionDetails] = useState([]);
  const [pastSessionDetails, setPastSessionDetails] = useState([]);
  const navigate = useNavigate();

  const [activeData, setActiveData] = useState({
    page: 1,
    limit: 3,
    search: "",
    type: "upcoming",
  });

  const [activePastData, setActivePastData] = useState({
    page: 1,
    limit: 3,
    search: "",
    type: "past",
  });

  const isMobileView = useMediaQuery({ query: "(max-width: 767px)" });

  const [loading, setLoading] = useState(false);

  const handleReadMoreClick = () => {
    setExpanded(true);
  };

  const handleTabClick = (tabContent) => {
    setActiveTab(tabContent);
  };

  const getMySessionListPagination = (activeData, activePastData) => {
    if (activeTab == "upcoming-session") {
      getMySessionspi(activeData)
        .then((res) => {
          setUpcomingSessionDetails(res?.data?.data?.rows);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (activeTab == "past-session") {
      getMySessionspi(activePastData)
        .then((res) => {
          setPastSessionDetails(res?.data?.data?.rows);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    fetchProfile();
    logEvent(analytics, "my_sessions_visited");
  }, []);

  const getSessionDetails = (activeData) => {
    getMySessionspi(activeData)
      .then((res) => {
        setUpcomingSessionDetails(res?.data?.data?.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSessionPastDetails = (activePastData) => {
    getMySessionspi(activePastData)
      .then((res) => {
        setPastSessionDetails(res?.data?.data?.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const topListData = [
    { value: profile?.sessionCount, label: "Sessions" },
    { value: profile?.followerCount, label: "Followers" },
    { value: profile?.followingCount, label: "Following" },
  ];

  const handleFetchMoreData = () => {
    setActiveData({ ...activeData, limit: activeData.limit + 3 });
    setActivePastData({ ...activePastData, limit: activePastData.limit + 3 });
    getMySessionListPagination(activeData, activePastData);
  };

  useEffect(() => {
    getSessionDetails(activeData);
    getSessionPastDetails(activePastData);
  }, [activeData, activePastData]);

  const deleteSession = (sessionId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "Warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        removeSessionApi({ session_id: sessionId })
          .then((res) => {
            Swal.fire({
              icon: "success",
              text: res.data.message,
              imageAlt: "success image",
            }).then(() => {
              const upcomingdata = {
                page: 1,
                limit: 3,
                search: "",
                type: "upcoming",
              };
              getSessionDetails(upcomingdata);
              setActiveData(upcomingdata);
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  

  const handleShortLink = () => {
    createShortDynamicLink("user-profile", profile?.user_name)
      .then((res) => {
        setShortLink(res?.shortLink);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  

  return (
    <Layout>
      <section className={`${isMobileView ? "main mt-3" : "main mt-5 py-5"}`}>
        <div className="container px-8">
          <ToastContainer />
          <div className="row">
            {loading ? (
              <div className="justify-content-center d-flex align-items-center">
                <div className="loadingio-spinner-rolling-26k4myxj9b9">
                  <div className="ldio-y9y7x49yybs">
                    <div></div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="inner-block">
                  <a onClick={() => navigate("/dashboard")}>
                    <div className="icon-back mb-3">
                      {" "}
                      <i className="fa fa-arrow-left"></i>
                    </div>
                  </a>

                  <div className="card mb-5 mob-card">
                    <div className="card-header text-center bg-white pb-4">
                      <div className="text-center">
                        <div className="user-icon mx-auto">
                          <img
                            src={
                              profile?.profileImageUrl === null
                                ? AvatarImage
                                : profile?.profileImageUrl
                            }
                            className="rounded-circle img-property-fit"
                            alt="user"
                            width=""
                            height=""
                            onError={url.handleUserImageError}
                          />
                        </div>
                        <h5 className="username">{`${profile?.first_name} ${profile?.last_name} (${profile?.user_name})`}</h5>
                      </div>
                      
                      <div className="profile-edit">
                        
                        <a
                          href=""
                          className="d-flex align-items-center"
                          data-bs-target="#editProfile"
                          data-bs-toggle="modal"
                        >
                          <i className="fa fa-edit me-2"></i>{" "}
                          <span className="mob-h">Edit Profile</span>
                        </a>
                      </div>

                      <TopVerticalList statistics={topListData} />

                      {/* <HashtagList
                        hashtags={
                          profile &&
                          profile?.UserCategory?.map((item, index) => {
                            return item?.categoryName;
                          })
                        }
                      /> */}

                      <p className="one-liner mt-3 mb-3">
                        {expanded
                          ? `${profile?.about_me}`
                          : `${profile?.about_me?.substring(0, 80)}...`}
                        {!expanded && (
                          <a
                            href="#"
                            className="read-more-text"
                            onClick={handleReadMoreClick}
                          >
                            Read More
                          </a>
                        )}
                      </p>

                      <div className="btn-list d-flex align-items-center justify-content-center pt-2 pb-2">
                      <div className="my-share-button">
                        <a
                          href=""
                          className="d-flex align-items-center"
                          data-bs-target="#shareModal"
                          data-bs-toggle="modal"
                          onClick={handleShortLink}
                        >
                          <img src={ShareIcon} alt="shareicon" height="" width="" className="img-property-fit me-2"/>{" "}
                          <span className="mob-h">Share</span>
                        </a>
                      </div>
                      </div>
                    </div>
                  </div>

                  <ul className="nav nav-tabs d-flex align-items-center justify-content-center mx-auto">
                    <CustomTab
                      active={activeTab === "upcoming-session"}
                      tabContent="Upcoming Sessions"
                      onClick={() => handleTabClick("upcoming-session")}
                    />
                    <CustomTab
                      active={activeTab === "past-session"}
                      tabContent="Past Sessions"
                      onClick={() => handleTabClick("past-session")}
                    />
                  </ul>

                  <div className="clearfix"></div>
                  <br />

                  <div className="tab-content">
                    {activeTab == "upcoming-session" && (
                      <InfiniteScroll
                        dataLength={upcomingSessionDetails?.length}
                        next={handleFetchMoreData}
                        hasMore={true}
                      >
                        <div
                          className="tab-pane container active"
                          id="upcoming-session"
                        >
                          <div className="content-list">
                            <div className="row">
                              {/* "10 May 2023, 12PM - 12:30PM" */}
                              {upcomingSessionDetails?.length === 0 ? (
                                <>
                                  <div className="col-md-12 d-flex justify-content-center">
                                    <div className="no-data">
                                      <img
                                        src={NoSessionImage}
                                        alt="no-data"
                                        width="300"
                                        height="300"
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {upcomingSessionDetails?.map(
                                    (session, index) => (
                                      <SessionItemCard
                                        key={index}
                                        sessionId={session.id}
                                        P2Image={session.bannerImageUrl}
                                        UserImage={session.user.profileImageUrl}
                                        VMenuImage={VMenuImage}
                                        sessionTitle={session.title}
                                        // sessionDateTime={`${url.UTCDateFormat(
                                        //   session?.start_date_time
                                        // )} ${url.UTCTimeFormat(
                                        //   session?.start_date_time
                                        // )}`}
                                        abbreviation={session?.timezoneAbbreviation}
                                        sessionDateTime={url.formatGlobalDateTimeOwn(session?.start_date_time, session?.timezone)}
                                        userName={`${session.user.first_name} ${session.user.last_name}`}
                                        link={`/session-details/${session.id}`}
                                        isEditable={true}
                                        onDelete={(sessionId) => {
                                          deleteSession(sessionId);
                                        }}
                                        sessionType={session?.sessionTypeName}
                                        sessionTypeColor={
                                          session?.sessionTypeColor
                                        }
                                      />
                                    )
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </InfiniteScroll>
                    )}

                    {activeTab == "past-session" && (
                      <InfiniteScroll
                        dataLength={pastSessionDetails?.length}
                        next={handleFetchMoreData}
                        hasMore={true}
                      >
                        <div
                          className="tab-pane container active"
                          id="past-session"
                        >
                          <div className="content-list">
                            <div className="row">
                              {/* "10 May 2023, 12PM - 12:30PM" */}
                              {pastSessionDetails?.length === 0 ? (
                                <>
                                  <div className="col-md-12 d-flex justify-content-center">
                                    <div className="no-data">
                                      <img
                                        src={NoDataImage}
                                        alt="no-data"
                                        width="300"
                                        height="300"
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {pastSessionDetails?.map((session, index) => (
                                    <SessionItemCard
                                      key={index}
                                      sessionId={session.id}
                                      P2Image={session.bannerImageUrl}
                                      UserImage={session.user.profileImageUrl}
                                      VMenuImage={VMenuImage}
                                      sessionTitle={session.title}
                                     
                                      abbreviation={session?.timezoneAbbreviation}
                                        sessionDateTime={url.formatGlobalDateTimeOwn(session?.start_date_time, session?.timezone)}
                                      userName={`${session.user.first_name} ${session.user.last_name}`}
                                      link={`/session-details/${session.id}`}
                                      isEditable={true}
                                      sessionType={session?.sessionTypeName}
                                      sessionTypeColor={
                                        session?.sessionTypeColor
                                      }
                                    />
                                  ))}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </InfiniteScroll>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
      <EditProfileModal />
      {/* <CreateSessionModal /> */}
      <ShareModal shareableLink={shortLink}/>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile.profile,
    loading: state.profile.loading,
    error: state.profile.error,
  };
};

const mapDispatchToProps = {
  fetchProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(MySession);
