import React from 'react';

const FilledButton = ({ title, handleSubmit, isDisabled }) => {
  const handleClick = (event) => {
    event.preventDefault();
    handleSubmit(); // Call the handleSubmit function to submit the form
  };

  return (
    <button className="btn btn-login btn-lg btn-block w-100" onClick={handleClick} disabled={isDisabled}>
      {title}
    </button>
  );
};


export default FilledButton