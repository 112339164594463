import config from "../configurations/config";
import { postMethodCall } from "./apiHandler";

export const getJoinListApi = async (payload) => {
  return await postMethodCall(`${config.backendApiUrl}/api/user/getAllJoinList`, payload);
};

export const getJoinListV1Api = async (payload) => {
  return await postMethodCall(`${config.backendApiUrl}/api/user/v1/getAllJoinList`, payload);
};
