import React from 'react'

const FormInput = ({ name, type, label, placeholder, onChange, onBlur, value, error, touched, isDisabled }) => {
  return (
    <div className="form-outline mb-4">
    <label className="form-label mb-0" htmlFor={name}>
      {label}
    </label>
    <input
      name={name}
      type={type}
      id={name}
      className="form-control form-control-lg"
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      disabled={isDisabled ? true : false}
      style={{ color: `${isDisabled ? "rgb(152 149 149)" : "#000"}`}}
      autoComplete='off'
    />
    {error && touched && <div className="text-danger">{error}</div>}
  </div>
  )
}

export default FormInput;