import React, { useEffect, useState } from "react";
import SideMenuData from "../data/side-menu.json";
import { Link, useLocation } from "react-router-dom";
import { getRequestTakeSessionCountApi } from "api/sessionApi";
import { useSelector } from "react-redux";
import { unreadNotificationCountApi } from "api/notificationApi";
import { readNotificationApi } from "api/notificationApi";
import { useMediaQuery } from "react-responsive";
import { getUserInfo } from "helpers/common_helper";
import { isUserLoggedIn } from "helpers/common_helper";

const SidebarMenu = () => {
  const location = useLocation();
  const userRequestCountApiTrigger = useSelector(
    (state) => state.userRequest.userRequestCountApiTriggered
  );

  const userName = getUserInfo()?.user_name;


  const isMobileView = useMediaQuery({ query: "(max-width: 767px)" });

  const [count, setCount] = useState(0);
  const [notificationCount, setNotificationCount] = useState(0);

  const getTakeAndRequestSessionCount = () => {
    getRequestTakeSessionCountApi()
      .then((res) => {
        setCount(res?.data?.data?.totalCount);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getNotificationCount = () => {
    unreadNotificationCountApi()
      .then((res) => {
        setNotificationCount(res?.data?.unreadNotificationCount);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const readNotification = () => {
    if (location.pathname === "/notifications") {
      readNotificationApi()
        .then((res) => {
          console.log("res", res);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  const isLogged = isUserLoggedIn();

  useEffect(() => {
    getTakeAndRequestSessionCount();
    getNotificationCount();
    readNotification();
  }, [userRequestCountApiTrigger]);


  return (
    <>
      {!isMobileView && (
        <>
          <div className="sidebar-menu">
            <ul>
              {SideMenuData.map((sideMenu, index) => (
                <li key={index}>
                  <Link
                    to={sideMenu.path}
                    className={`${sideMenu.className} ${
                      location.pathname === sideMenu.path ? "active" : ""
                    }`}
                  >
                    <i className={sideMenu.icon + " text-main me-2"}></i>{" "}
                    {sideMenu.title}
                    {sideMenu.path === "/user-requests" && count > 0 && (
                      <span className="count text-center">{count}</span>
                    )}
                    {sideMenu.path === "/notifications" &&
                      notificationCount > 0 && (
                        <span className="count text-center ms-2">
                          {notificationCount}
                        </span>
                      )}
                      
                      {sideMenu.path === "/my-sessions" && (
                        
                        isLogged ? sideMenu.path = `/user-profile/${userName}` : sideMenu.path = "/login"
                      )}

                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </>
  );
};

export default SidebarMenu;
