import axios from "axios";
import { setAuthorizationToken } from "./authorization";

const token = localStorage.getItem("_lltoken");



export const postMethodCall = async (
  url,
  data,
  contentType = "application/json"
) => {
  try {
    const apiResponse = await axios({
      method: "post",
      url,
      data,
      headers: {
        "Content-Type": contentType,
        "Authorization": `Bearer ${token}`
      },
    });
    return { status: true, data: apiResponse.data };
  } catch (err) {
    return { status: false, apiResponse: err };
  }
};

export const postFormMethodCall = async (
  url,
  data,
  contentType = "multipart/form-data"
) => {
  try {
    const apiResponse = await axios({
      method: "post",
      url,
      data,
      headers: {
        "Content-Type": contentType,
        "Authorization": `Bearer ${token}`
      },
    });
    return { status: true, data: apiResponse.data };
  } catch (err) {
    return { status: false, apiResponse: err };
  }
};

export const putMethodCall = async (
  url,
  data,
  contentType = "application/json"
) => {
  try {
    const apiResponse = await axios({
      method: "put",
      url,
      data,
      headers: {
        "Content-Type": contentType,
        "Authorization": `Bearer ${token}`
      },
    });
    return { status: true, data: apiResponse.data };
  } catch (err) {
    return { status: false, apiResponse: err };
  }
};

export const patchMethodCall = async (
  url,
  data,
  contentType = "application/json"
) => {
  try {
    const apiResponse = await axios({
      method: "patch",
      url,
      data,
      headers: {
        "Content-Type": contentType,
        "Authorization": `Bearer ${token}`
      },
    });
    return { status: true, data: apiResponse.data };
  } catch (err) {
    return { status: false, apiResponse: err };
  }
};

export const deleteMethodCall = async (
  url,
  data,
  contentType = "application/json"
) => {
  try {
    const apiResponse = await axios({
      method: "delete",
      url,
      data,
      headers: {
        "Content-Type": contentType,
        "Authorization": `Bearer ${token}`
      },
    });
    return { status: true, data: apiResponse.data };
  } catch (err) {
    return { status: false, apiResponse: err };
  }
};

export const getMethodCall = async (url, data, contentType = "application/json") => {
  try {
    const apiResponse = await axios({
      method: "get",
      url: url,
      data: data,
      headers:{
        "Content-Type": contentType,
        "Authorization": `Bearer ${token}`
      }
    });
    return { status: true, data: apiResponse.data };
  } catch (err) {
    return { status: false, apiResponse: err };
  }
};

