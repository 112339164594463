import React, { useEffect, useState } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CloseIcon from "../../../assets/images/icon_close.png";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getCategoriesApi } from "api/categoryApi";
import { getLanguageApi } from "api/sessionApi";
import { formattedFilterDateTime } from "helpers/common_helper";
import { useDispatch, useSelector } from "react-redux";
import { setFilterValues, setFormSubmitted } from "actions/filterActions";
import { getTodaysDate } from "helpers/common_helper";

const FilterModal = () => {
  const dispatch = useDispatch();

  const [interestList, setInterestList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);


  const getCategoryList = () => {
    getCategoriesApi()
      .then((res) => {
        setInterestList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLanguageList = () => {
    getLanguageApi()
      .then((res) => {
        setLanguageList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formik = useFormik({
    initialValues: {
      start_date: "",
      end_date: "",
      categoryFilter: "",
      languageFilter: "",
    },
    validationSchema: Yup.object({
      start_date: Yup.date().nullable(),
      end_date: Yup.date().nullable(),
      categoryFilter: Yup.array().nullable(),
      languageFilter: Yup.array().nullable(),
    }),
    onSubmit: (values) => {
      console.log(values);
      dispatch(setFilterValues(values));
      dispatch(setFormSubmitted(true));

      
    },
  });

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = formik;

  const handleClearAll = () => {
    formik.resetForm();
    setSelectedInterests([]);
    setSelectedLanguages([]);
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    dispatch(setFilterValues({}));
    dispatch(setFormSubmitted(false));
  };

  useEffect(() => {
    getCategoryList();
    getLanguageList();

  }, []);

  return (
    <div className="modal fade newmodal" id="filterModal">
      <div className="modal-dialog modal-sm modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header text-center">
            <button type="button" className="btn-close" data-bs-dismiss="modal">
              <img src={CloseIcon} alt="" width="" height="" />
            </button>
            <h4 className="text-center">Filter</h4>
          </div>
          <div className="modal-body px-5 py-4">
            <form>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-outline mb-4">
                    <label className="form-label mb-0">
                      Prefered Date (Optional)
                    </label>
                    <div className="d-flex align-items-center right icon">
                      <DatePicker
                        name="start_date"
                        className="form-control"
                        selected={selectedStartDate || null}
                        onChange={(date) => {
                          const formattedDate = formattedFilterDateTime(date);
                          setFieldValue("start_date", formattedDate);
                          setSelectedStartDate(date);
                        }}
                        onBlur={handleBlur}
                        placeholderText="Select Date"
                        dateFormat="MMMM d, yyyy"
                        minDate={getTodaysDate()}
                      />
                      <i className="fa fa-calendar"></i>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-outline mb-4">
                    <label className="form-label mb-0">Date (Optional)</label>
                    <div className="d-flex align-items-center right icon">
                      <DatePicker
                        name="end_date"
                        className="form-control"
                        selected={selectedEndDate || null}
                        onChange={(date) => {
                          const formattedDate = formattedFilterDateTime(date);
                          setFieldValue("end_date", formattedDate);
                          setSelectedEndDate(date);
                        }}
                        onBlur={handleBlur}
                        placeholderText="Select Date"
                        dateFormat="MMMM d, yyyy"
                        minDate={getTodaysDate()}
                      />
                      <i className="fa fa-calendar"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-outline mb-4">
                  <label className="form-label mb-0">
                    Select Topic or Subject
                  </label>
                  <Select
                    name="categoryFilter"
                    className=""
                    id="multiple-select-field3"
                    placeholder="Select your interests"
                    isMulti
                    options={interestList?.map((item) => {
                      return {
                        value: item.id,
                        label: item.name,
                      };
                    })}
                    onChange={(selectedOption) => {
                      const selectedValues = selectedOption?.map((item) => {
                        return item.value;
                      });
                      setFieldValue("categoryFilter", selectedValues);
                      setSelectedInterests(selectedOption);
                    }}
                    onBlur={handleBlur}
                    value={selectedInterests || ""}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-outline mb-4">
                  <label className="form-label mb-0">Select Languages</label>
                  <Select
                    name="languageFilter"
                    className=""
                    id="multiple-select-field4"
                    placeholder="Select your languages"
                    isMulti
                    options={languageList?.map((item) => {
                      return {
                        value: item.id,
                        label: item.name,
                      };
                    })}
                    onChange={(selectedOption) => {
                      const selectedValues = selectedOption?.map((item) => {
                        return item.value;
                      });
                      setFieldValue("languageFilter", selectedValues);
                      setSelectedLanguages(selectedOption);
                    }}
                    value={selectedLanguages || ""}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <div className="text-end">
              <Link className="btn btn-clear me-3" onClick={() => handleClearAll()}>
                Clear All{" "}
              </Link>

              <a onClick={() => handleSubmit()} className="btn btn-apply">
                Apply
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterModal;
