import LeftSideBanner from "components/ui/Authentication/LeftSideBanner";
import Heading from "components/ui/Common/Heading";
import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import Label from "components/ui/Common/Label";
import Input from "components/ui/Common/Input";
import FilledButton from "components/ui/Common/FilledButton";
import OutlinedButton from "components/ui/Common/OutlinedButton";
import GoogleLogo from "../../assets/images/google.png";
import { Link } from "react-router-dom";
import { registerApi } from "api/authApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useGoogleLogin } from "@react-oauth/google";
import getUserInfo from "services/GoogleAuth/authResponse";
import PrivacyPolicyModal from "pages/PrivacyPolicy/Modal/PrivacyPolicyModal";
import { getCurrentTimeZone } from "helpers/common_helper";

const Register = () => {
  const [pageType, setPageType] = useState("");
  const [loading, setLoading] = useState(false);

  const timezone = getCurrentTimeZone();

  const [selectedUserType, setSelectedUserType] = useState(null);
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    // country_phone_code: Yup.string().required("Country is required"),
    // mobile_number: Yup.number().required("Mobile number is required"),
    password: Yup.string().min(6).required("Password is required"),
    is_agree_termscondition: Yup.boolean()
      .oneOf([true], "You must agree to the Terms and Privacy Policy")
      .required("You must agree to the Terms and Privacy Policy"),
    is_brand: Yup.boolean().required("Please select user type"),
    brand_name: Yup.string().test(
      "brand_name",
      "Brand name is required",
      function (value) {
        const { is_brand } = this.parent;
        if (is_brand == 1 && (!value || value === "")) {
          return false;
        } else {
          return true;
        }
      }
    ),
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      // country_phone_code: "",
      // mobile_number: "",
      password: "",
      is_agree_termscondition: false,
      login_type: "Email",
      google_id: "",
      is_brand: null,
      brand_name: "",
      timezone: timezone,
    },
    validationSchema,
    onSubmit: (values) => {
      registerApi(values).then((res) => {
        console.log(res?.data);

        if (res?.data?.success) {
          localStorage.setItem("_lltoken", res?.data?.token);
          localStorage.setItem("_lluser", JSON.stringify(res?.data?.data));
          localStorage.setItem("_lltimezone", res?.data?.data?.timezone);

          toast.success(res?.data?.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          setTimeout(() => {
            window.location.href = "/dashboard";
          }, 1500);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
      console.log(values);
    },
  });

  const handleGoogleSignUp = useGoogleLogin({
    onSuccess: (codeResponse) => {
      console.log(codeResponse);
      getUserInfo(codeResponse?.access_token).then((res) => {
        console.log(res);
        setLoading(true);
        registerApi({
          first_name: res?.given_name,
          last_name: res?.family_name,
          email: res?.email,
          login_type: "Google",
          google_id: res?.id,
          timezone: timezone,
        }).then((res) => {
          console.log(res?.data);

          if (res?.data?.success) {
            localStorage.setItem("_lltoken", res?.data?.token);
            localStorage.setItem("_lluser", JSON.stringify(res?.data?.data));
            localStorage.setItem("_lltimezone", res?.data?.data?.timezone);
            setLoading(false);

            toast.success(res?.data?.message, {
              position: "top-right",
              autoClose: 700,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });

            window.location.href = "/dashboard";
          } else {
            toast.error(res?.data?.message, {
              position: "top-right",
              autoClose: 700,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        });
      });
    },

    onError: (error) => {
      console.log("Login failed", error);
    },
  });

  const handleChangeUserOption = (e) => {
    setSelectedUserType(e.target.value);
    if (e.target.value === "Brand") {
      formik.setFieldValue("is_brand", 1);
    } else {
      formik.setFieldValue("is_brand", 0);
    }
  };

  return (
    <>
      <section className="vh-100 login">
        <div className="container-fluid h-custom">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-md-6 col-lg-6 col-xl-4">
              <LeftSideBanner />
            </div>

            {loading ? (
              <>
                <div className="justify-content-center d-flex align-items-center">
                  <div className="loadingio-spinner-rolling-26k4myxj9b9">
                    <div className="ldio-y9y7x49yybs">
                      <div></div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="col-md-6 col-lg-6 col-xl-4 offset-xl-1">
                  <form className="login-form py-5 px-5">
                    <Heading title="Sign up" />
                    <p>Please enter your details to sign up.</p>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-outline mb-3 me-2">
                          <Label title="First Name" />
                          <Input
                            id="first_name"
                            name="first_name"
                            type="text"
                            placeholder=""
                            field={formik.getFieldProps("first_name")}
                            form={formik}
                            autocomplete="off"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-outline mb-3 me-2">
                          <Label title="Last Name" />
                          <Input
                            id="last_name"
                            name="last_name"
                            type="text"
                            placeholder=""
                            field={formik.getFieldProps("last_name")}
                            form={formik}
                            autocomplete="off"
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-outline mb-3">
                          <Label title="Email" />
                          <Input
                            id="email"
                            name="email"
                            type="email"
                            placeholder=""
                            field={formik.getFieldProps("email")}
                            form={formik}
                            autocomplete="off"
                          />
                        </div>
                      </div>

                      {/* <div className="col-xs-2 col-sm-2 col-md-2">
                  <div className="form-outline mb-3 me-2 ">
                    <Label title="Country" />
                    <Input
                      id="country_phone_code"
                      name="country_phone_code"
                      type="text"
                      placeholder="Country"
                      field={formik.getFieldProps("country_phone_code")}
                      form={formik}
                    />
                  </div>
                </div>

                <div className="col-xs-10 col-sm-10 col-md-10">
                  <div className="form-outline mb-3">
                    <Label title="Mobile Number" />
                    <Input
                      id="mobile_number"
                      name="mobile_number"
                      type="text"
                      placeholder="Mobile Number"
                      field={formik.getFieldProps("mobile_number")}
                      form={formik}
                    />
                  </div>
                </div> */}

                      <div className="col-md-12">
                        <div className="form-outline mb-3">
                          <label
                            className="form-label me-2"
                            for="form3Example3"
                          >
                            I am a
                          </label>

                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio1"
                              value="Individual"
                              onChange={handleChangeUserOption}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio1"
                            >
                              Individual
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio2"
                              value="Brand"
                              onChange={handleChangeUserOption}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio2"
                            >
                              Brand
                            </label>
                          </div>

                          {formik.touched.is_brand && formik.errors.is_brand ? (
                            <div className="text-danger">
                              {formik.errors.is_brand}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {selectedUserType == "Brand" && (
                        <>
                          <div className="col-md-12">
                            <div className="form-outline mb-3">
                              <Label title="Brand" />
                              <Input
                                id="brand_name"
                                name="brand_name"
                                type="text"
                                placeholder=""
                                field={formik.getFieldProps("brand_name")}
                                form={formik}
                                autocomplete="off"
                              />
                            </div>
                          </div>
                        </>
                      )}

                      <div className="col-md-12">
                        <div className="form-outline mb-3">
                          <Label title="Password" />
                          <Input
                            type="password"
                            id="password"
                            name="password"
                            placeholder=""
                            field={formik.getFieldProps("password")}
                            form={formik}
                            autocomplete="off"
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={
                              formik.values.is_agree_termscondition || false
                            }
                            id="is_agree_termscondition"
                            name="is_agree_termscondition"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            checked={
                              formik.values.is_agree_termscondition || false
                            }
                          />
                          <label className="form-check-label" htmlFor="">
                            <span className="text-agree">
                              I agree to the{" "}
                              <a
                                href="!#"
                                onClick={() => setPageType("TermsAndCondition")}
                                data-bs-target="#privacyPolicyModal"
                                data-bs-toggle="modal"
                              >
                                Terms
                              </a>{" "}
                              and
                              <a
                                href="!#"
                                data-bs-target="#privacyPolicyModal"
                                data-bs-toggle="modal"
                                onClick={() => setPageType("PrivacyPolicy")}
                              >
                                {" "}
                                Privacy Policy
                              </a>
                            </span>
                          </label>
                          {formik.touched.is_agree_termscondition &&
                          formik.errors.is_agree_termscondition ? (
                            <div className="text-danger">
                              {formik.errors.is_agree_termscondition}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="text-center mt-4 pt-2">
                      <FilledButton
                        title="Sign Up"
                        handleSubmit={formik.handleSubmit}
                      />
                      <p className="or py-3 mb-0">Or</p>
                      <OutlinedButton
                        path={"#!"}
                        title="Signup with Google"
                        image={GoogleLogo}
                        handleClick={handleGoogleSignUp}
                      />

                      <p className="small mt-4 mb-0">
                        Already have an account?{" "}
                        <Link to="/login" className="text-primary-bold">
                          Login
                        </Link>
                      </p>
                    </div>
                  </form>
                </div>
              </>
            )}
          </div>
        </div>
        <ToastContainer />
      </section>
      <PrivacyPolicyModal pageType={pageType} />
    </>
  );
};

export default Register;
