import React from 'react'
import { toast } from 'react-toastify';

const ConfirmationAlert = ({ message, onConfirm }) => {
    const handleConfirm = () => {
        onConfirm();
        toast.dismiss();
      };
    
      const handleCancel = () => {
        toast.dismiss();
      };

  return (
    <div>
      <p>{message}</p>
      <button className='btn btn-cancel-request-confirm' onClick={handleConfirm}>Yes</button>
      <button className='btn' onClick={handleCancel}>No</button>
    </div>
  )
}

export default ConfirmationAlert;