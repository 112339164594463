import ProfileInfo from "components/ui/Common/ProfileInfo";
import Layout from "layouts/Index";
import SidebarMenu from "layouts/SidebarMenu";
import ProfileHeader from "pages/Dashboard/ProfileHeader";
import React, { useEffect, useState } from "react";
import CustomTab from "components/ui/Common/CustomTab";
import EventCard from "components/ui/Common/EventCard";
import RequestSessionModal from "pages/Dashboard/RequestSessionModal";
import { connect } from "react-redux";
import { fetchProfile } from "actions/profileActions";
import { UTCDateFormatWithDay } from "helpers/common_helper";
import { UTCTimeFormat } from "helpers/common_helper";
import { capitalizeFirstWord } from "helpers/common_helper";
import InfiniteScroll from "react-infinite-scroll-component";
import SessionDetailModal from "pages/Dashboard/Modal/SessionDetailModal";
import { getJoinListV1Api } from "api/jointListApi";
import { cancelJoinRequestApi } from "api/sessionApi";
import { ToastContainer, toast } from "react-toastify";
import ConfirmationAlert from "components/Utilities/ConfirmationAlert";
import { logEvent } from "firebase/analytics";
import analytics from "index";
import NoDataImage from "../../assets/images/nodatafound.gif";
import { useMediaQuery } from "react-responsive";
import { isUserLoggedIn } from "helpers/common_helper";
import SessionDetailModalV2 from "pages/Dashboard/Modal/SessionDetailModalV2";

const MyJoinList = ({ profile, error, fetchProfile }) => {
  const [activeTab, setActiveTab] = useState("upcoming");
  const [selectedSession, setSelectedSession] = useState("");
  const [activeData, setActiveData] = useState({
    page: 1,
    limit: 10,
    search: "",
    type: "Upcoming",
  });
  const [loading, setLoading] = useState(false);
  const [joinList, setJoinList] = useState([]);

  const isMobileView = useMediaQuery({ query: "(max-width: 767px)" });

  const handleTabClick = (tabContent) => {
    setActiveTab(tabContent);
    setActiveData({
      ...activeData,
      type: capitalizeFirstWord(tabContent),
    });
  };

  const getJointList = (data) => {
    if (activeData.limit <= 10) {
      setLoading(true);
    }

    getJoinListV1Api(data)
      .then((res) => {
        setJoinList(res?.data?.data?.rows);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getJointList(activeData);
    fetchProfile();
    logEvent(analytics, "my_join_list_opened");
  }, [activeData]);

  const handleFetchMoreData = () => {
    setActiveData({ ...activeData, limit: activeData.limit + 5 });
  };

  const profileTopList = [
    {
      title: "Sessions",
      value: profile?.sessionCount,
    },
    {
      title: "Followers",
      value: profile?.followerCount,
    },
    {
      title: "Following",
      value: profile?.followingCount,
    },
  ];

  const handleCancelJoinRequest = (requestId) => {
    cancelJoinRequestApi({ requestId: requestId })
      .then((res) => {
        if (res?.data?.success) {
          toast.success(res?.data?.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          getJointList(activeData);
        } else {
          toast.warning(res?.data?.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showConfirmationAlert = (requestId) => {
    toast(
      <ConfirmationAlert
        message="Are you sure you want to delete this session?"
        onConfirm={() => handleCancelJoinRequest(requestId)}
      />,
      {
        position: "top-center",
        autoClose: false,
        closeOnClick: false,
        closeButton: false,
      }
    );
  };

  return (
    <Layout>
      <section className={`${isMobileView ? "main mt-3" : "main mt-5 py-5"}`}>
        <div className="container-fluid container-fluid-p">
          <div className="row">
          {!isMobileView && (
                <div
                className={`col-md-2 pt-5 left-block sticky-sidebar ${
                  isUserLoggedIn() ? "" : "locked"
                }`}
              >
                <SidebarMenu />
              </div>
              )}

            <div className="col-md-10 p-0 right-block">
              <div className="mb-4">
                <ul className="nav nav-tabs d-flex align-items-center justify-content-center mx-auto">
                  {/* <CustomTab
                    active={activeTab === "all"}
                    tabContent="All"
                    onClick={() => handleTabClick("all")}
                  /> */}

                  <CustomTab
                    active={activeTab === "upcoming"}
                    tabContent="Upcoming"
                    onClick={() => handleTabClick("upcoming")}
                  />

                  {/* <CustomTab
                    active={activeTab === "request"}
                    tabContent="Request"
                    onClick={() => handleTabClick("request")}
                  /> */}

                  <CustomTab
                    active={activeTab === "past"}
                    tabContent="Past"
                    onClick={() => handleTabClick("past")}
                  />
                </ul>

                <div className="clearfix"></div>
                <br />

                <div className="tab-content">
                  {loading ? (
                    <>
                      <div className="justify-content-center d-flex align-items-center">
                        <div className="loadingio-spinner-rolling-26k4myxj9b9">
                          <div className="ldio-y9y7x49yybs">
                            <div></div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <InfiniteScroll
                        dataLength={joinList?.length}
                        next={handleFetchMoreData}
                        hasMore={true}
                      >
                        {joinList && joinList.length > 0 ? (
                          joinList?.map((item, index) => (
                            <EventCard
                              key={index}
                              startDate={UTCDateFormatWithDay(
                                item?.start_date_time
                              )}
                              endTime={UTCTimeFormat(item?.end_date_time)}
                              bannerImage={item?.bannerImageUrl}
                              title={item?.title}
                              username={item?.sessionCreatorName}
                              sessionStatus={item?.sessionParticipant?.status}
                              
                              userprofileName={item?.sessionCreatorUserName}
                              handleCancelRequest={() =>
                                showConfirmationAlert(
                                  item?.sessionParticipant?.id
                                )
                              }
                              session_id={item?.id}
                              activeTab={activeTab}
                            />
                          ))
                        ) : (
                          <div className="col-md-12 d-flex justify-content-center">
                            <div className="no-data">
                              <img
                                src={NoDataImage}
                                alt="no-data"
                                width="400"
                                height="400"
                              />
                            </div>
                          </div>
                        )}
                      </InfiniteScroll>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </section>
      {/* <CreateSessionModal/> */}
      <RequestSessionModal />
      <SessionDetailModalV2 session_id={selectedSession} />
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile.profile,
    loading: state.profile.loading,
    error: state.profile.error,
  };
};

const mapDispatchToProps = {
  fetchProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyJoinList);
