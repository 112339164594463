import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from 'store';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDgSPJ5EW68tWZ--B-X9xLVzNvdAhOSn0c",
  authDomain: "letslearn-429f9.firebaseapp.com",
  projectId: "letslearn-429f9",
  storageBucket: "letslearn-429f9.appspot.com",
  messagingSenderId: "599095144830",
  appId: "1:599095144830:web:b9b5370c2f78f5437e832f",
  measurementId: "G-Y7SMJXVGQS"
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId='216721696651-6uhj72s5he57ecjtnno1beajed07jr1b.apps.googleusercontent.com'>
    <BrowserRouter>
    <Provider store={store}>
    <App />
    </Provider>
    </BrowserRouter>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default analytics;



