import { isUserLoggedIn } from "helpers/common_helper";
import config from "../configurations/config";
import { getMethodCall, postFormMethodCall, postMethodCall, putMethodCall, deleteMethodCall} from "./apiHandler";

export const getSessionsApi = async (payload) => {
  if(isUserLoggedIn()){
    return await postMethodCall(`${config.backendApiUrl}/api/user/getallsessions`, payload);
  }
    return await postMethodCall(`${config.backendApiUrl}/api/user/open/getallsessions`, payload);
  };


  export const getTimezoneApi = async (payload) => {
    return await postMethodCall(`${config.backendApiUrl}/api/common/get-time-zones`, payload);
  };

  export const getTimezoneDetailApi = async (payload) => {
    return await postMethodCall(`${config.backendApiUrl}/api/common/get-time-zone`, payload);
  };

  export const getLanguageApi = async (payload) => {
    return await postMethodCall(`${config.backendApiUrl}/api/common/get-languages`, payload);
  };

  export const createSessionApi = async (payload) => {
    return await postFormMethodCall(`${config.backendApiUrl}/api/user/createsession`, payload);
  };

  export const updateSessionApi = async (payload) => {
    return await postFormMethodCall(`${config.backendApiUrl}/api/user/updatesession`, payload);
  };

  export const getSessionDetailApi = async (payload) => {
    if(isUserLoggedIn()){
      return await getMethodCall(`${config.backendApiUrl}/api/user/getsessiondetails/${payload.session_id}`, payload);
    }
    return await getMethodCall(`${config.backendApiUrl}/api/user/open/getsessiondetails/${payload.session_id}`, payload);
  };

  export const getMySessionspi = async (payload) => {
    if(isUserLoggedIn()){
      return await postMethodCall(`${config.backendApiUrl}/api/user/usersessions`, payload);
    }
    return await postMethodCall(`${config.backendApiUrl}/api/user/open/usersessions`, payload);
  };

  export const sendSessionRequestApi = async (payload) => {
    return await postMethodCall(`${config.backendApiUrl}/api/user/sendjoinrequest/${payload.session_id}`, payload);
  };

  export const getRequestedSessionsApi = async (payload) => {
    return await postMethodCall(`${config.backendApiUrl}/api/user/getAllUserRequest`, payload);
  };

  export const acceptRejectRequestApi = async (payload) => {
    return await putMethodCall(`${config.backendApiUrl}/api/user/acceptRejectUserRequest`, payload);
  };

  export const getTakeSessionsApi = async (payload) => {
    return await postMethodCall(`${config.backendApiUrl}/api/user/getAllRequestSession`, payload);
  };

  export const getRequestSessionDetailApi = async (payload) => {
    return await getMethodCall(`${config.backendApiUrl}/api/user/getrequestsessiondetail/${payload.session_id}`, payload);
  };

  export const sendTakeSessionStatusApi = async (payload) => {
    return await putMethodCall(`${config.backendApiUrl}/api/user/ignoreMaybeLaterRequest`, payload);
  };

  export const getRequestTakeSessionCountApi = async (payload) => {
    return await getMethodCall(`${config.backendApiUrl}/api/user/getrequesttakesessioncount`, payload);
  };


  export const getSessionParticipantApi = async (payload) => {
    return await postMethodCall(`${config.backendApiUrl}/api/user/getsessionparticipants`, payload);
  };

  export const removeSessionApi = async (payload) => {
    return await deleteMethodCall(`${config.backendApiUrl}/api/user/deletesession/${payload.session_id}`, payload);
  };

  export const removeParticipant = async (payload) => {
    return await deleteMethodCall(`${config.backendApiUrl}/api/user/deleteparticipant/${payload.participantId}`, payload);
  };

  export const cancelJoinRequestApi = async (payload) => {
    return await deleteMethodCall(`${config.backendApiUrl}/api/user/canceljoinrequest/${payload.requestId}`, payload);
  };

  export const getSessionTypesApi = async (payload) => {
    return await getMethodCall(`${config.backendApiUrl}/api/common/session-types`, payload);
  };

  export const sendSessionStartNotificationApi = async (payload) => {
    return await postMethodCall(`${config.backendApiUrl}/api/user/send-session-started-notification`, payload);
  };

  