import { handleStoryImageError } from "helpers/common_helper";
import React from "react";
import { Link } from "react-router-dom";

const StoryCard = ({ image, title, detail, storyId }) => {
  let trimmedDetail = detail;

  if (detail?.length > 265) {
    trimmedDetail = detail?.substring(0, 265);
  }

  const textStyle = {
    color: 'green',
    cursor: 'pointer',
  };

  const anchorStyle = {
    textDecoration: 'none',
  }

  return (
    <div className="col-md-4 col-10 col-sm-2">
      <Link to={`/story-details/${storyId}`} style={{ textDecoration: 'none', color:'black'}}>
      <div className="card-transparent story-card">
        <img
          src={image === null || image === "" ? "assets/images/cardimg3.svg" : image}
          className="card-img-top"
          alt="img2"
          onError={handleStoryImageError}
        />
        <div className="card-body">
          <h5 className="card-title mt-4">{title}</h5>
          {/* Add Readmore text when content is greater than 271 char */}
          

          {/* <p className="card-text mt-3">{detail?.length > 270 ? `${detail?.substring(0,270)}<span style="color: green;">..Read More</span>` : detail }</p> */}
          <p className="card-text mt-3">{trimmedDetail}{detail && detail?.length > 265 && (<Link to="/" style={anchorStyle}><span style={textStyle}> ...Read More</span></Link>)}</p>
        </div>
      </div>
      </Link>
    </div>
  );
};

export default StoryCard;
