import React from "react";
import EditMenu from "./EditMenu";
import { Link } from "react-router-dom";
import AvatarImage from "../../../assets/images/profile-placeholder.png";
import BannerImage from "../../../assets/images/colorbg.jpg";
import { handleImageError } from "helpers/common_helper";
import { handleUserImageError } from "helpers/common_helper";

function SessionItemCard({
  sessionId,
  P2Image,
  UserImage,
  VMenuImage,
  sessionTitle,
  sessionDateTime,
  userName,
  link,
  isEditable,
  onDelete,
  handleSessionClick,
  sessionType,
  sessionTypeColor,
  abbreviation,
})
 {
  return (
    <div className="col-md-4">
      <div className="session-item mb-4">
        <div className="card px-2 pt-3">
          <div className="card-header bg-white text-center">
            <div className="img-wrapper">
              <Link to={!isEditable ? `/session-info/${sessionId}` : link}
             
              >
                <img
                  src={P2Image === null ? BannerImage : P2Image}
                  alt=""
                  width=""
                  height=""
                  className="img-property-fit"
                  onError={handleImageError}
                />
              </Link>

              
              <div className="user-wrapper">
                <Link to={!isEditable ? "javascript:void(0)" : link}>
                  <img
                    src={UserImage === null ? AvatarImage : UserImage}
                    className="mb-2 img-property-fit"
                    alt="user"
                    width=""
                    height=""
                    onError={handleUserImageError}
                  />
                  <h5>{userName?.length > 33 ? `${userName?.substring(0, 34)}...` : userName }</h5>
                </Link>
              </div>
            </div>
            {isEditable && (
              <EditMenu
                menuImage={VMenuImage}
                sessionId={sessionId}
                onDelete={(sessionId) => {
                  onDelete(sessionId);
                }}
              />
            )}
          </div>
          <div className="card-body">
            <Link
              to={!isEditable ? `/session-info/${sessionId}` : link}
             
            >
             
              <h4 className="m-0">{sessionTitle?.length > 33 ? `${sessionTitle?.substring(0, 34)}...` : sessionTitle}</h4>
            </Link>
            <div className="d-flex">
          <p className="mb-0">{`${sessionDateTime} (${abbreviation})`}</p>
          <p className="mb-0 ms-auto session-type-badge" style={{ background: sessionTypeColor }}>{sessionType?.length > 8 ? `${sessionType?.substring(0,9)}..`: sessionType }</p>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SessionItemCard;
