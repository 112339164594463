import Heading from "components/ui/Common/Heading";
import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import FilledButton from "components/ui/Common/FilledButton";
import { ToastContainer, toast } from "react-toastify";
import { resetPasswordApi } from "api/authApi";

const UpdatePassword = () => {
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
      resetPasswordApi(values)
        .then((res) => {
          if (res?.data?.success) {
            toast.success(res?.data?.message, {
              position: "top-right",
              autoClose: 700,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            
            setTimeout(() => {
              window.location.href = "/login";
            }, 1500);
          } else {
            toast.error(res?.data?.message, {
              position: "top-right",
              autoClose: 700,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });

  return (
    <section className="vh-100 login">
      <div className="container-fluid h-custom">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-md-6 col-lg-6 col-xl-4">
            <form
              className="login-form py-5 px-5"
              onSubmit={formik.handleSubmit}
            >
              <Heading title="Reset Password" />

              <p>Please enter email to change password.</p>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-outline mb-3">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <div className="input-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder=""
                        {...formik.getFieldProps("email")}
                        className="form-control form-control-lg"
                      />
                    </div>
                    {formik.touched.email && formik.errors.email ? (
                      <div className="text-danger">{formik.errors.email}</div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="text-center mt-2 pt-2">
                <FilledButton
                  title="Send Email"
                  handleSubmit={formik.handleSubmit}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default UpdatePassword;
