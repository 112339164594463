import axios from "axios";

const getUserInfo = async (token) => {
  try {
    const response = await axios.get(
      `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      }
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export default getUserInfo;
