import React from 'react'

const Input = ({ field, form, ...props }) => {
  return (
    <div>
    <input
      {...field}
      {...props}
      className={form.errors[field.name] && form.touched[field.name] ? 'form-control form-control-lg is-invalid' : 'form-control form-control-lg'}
    />
    {form.errors[field.name] && form.touched[field.name] && (
      <div className="invalid-feedback">{form.errors[field.name]}</div>
    )}
  </div>
  )
}

export default Input