import React from "react";
import Logo from "../../../assets/images/logo_universal-manch.svg";
import LoginImage from "../../../assets/images/universal_manch_banner.svg";

const LeftSideBanner = () => {
  return (
    <>
      <div className="logo text-center mb-5">
        <img src={Logo} className="" alt="logo" width="264" />
      </div>
      <div className="text-center login-img">
        <img src={LoginImage} className="img-fluid" alt="loginImage" />
      </div>
      </>
  );
};

export default LeftSideBanner;
