import React from 'react';

function CustomTab({ active, tabContent, onClick, count }) {
  return (
    <li className="nav-item">
      <a
        className={`nav-link ${active ? 'active' : ''}`}
        data-bs-toggle="tab"
        href={tabContent}
        onClick={onClick}
      >
        {tabContent}
        {count && <span className="count2">{count}</span>}
      </a>
    </li>
  );
}

export default CustomTab;
