import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CloseIcon from "../../../assets/images/icon_close.png";
import uploadIcon from "../../../assets/images/img.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormInput from "components/ui/Common/FormInput";
import DatePickerField from "components/ui/Common/DatePickerField";
import { getTimezoneApi } from "api/sessionApi";
import { getLanguageApi } from "api/sessionApi";
import { getCategoriesApi } from "api/categoryApi";
import moment from "moment";
import { createSessionApi } from "api/sessionApi";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { getTodaysDate } from "helpers/common_helper";
import { getSessionTypesApi } from "api/sessionApi";
import { sessionVisibilityType } from "data/selectoptions";
import { logEvent } from "firebase/analytics";
import analytics from "index";
import { getAllUsersApi } from "api/profileApi";
import AvatarImage from "../../../assets/images/profile-placeholder.png";
import { handleUserImageError } from "helpers/common_helper";
import TimezoneSelect from "elements/TimezoneSelect";
import { useSelector } from "react-redux";
import { getCurrentTimeZone } from "helpers/common_helper";
import { sessionVenueType } from "data/selectoptions";

const CreateSessionModal = () => {
  const [file, setFile] = useState(null);
  const [activeData, setActiveData] = useState({
    page: 1,
    limit: 10,
    search: "",
  });

  const profile = useSelector((state) => state.profile.profile);

  const [timezoneList, setTimezoneList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedTimezone, setSelectedTimezone] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedStartTime, setSelectedStartTime] = useState(null);
  const [selectedEndTime, setSelectedEndTime] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isButtonDisable, setButtonDisable] = useState(false);
  const [sessionTypeList, setSessionTypeList] = useState([]);
  const [selectedSessionType, setSelectedSessionType] = useState([]);
  const [selectedVisibility, setSelectedVisibility] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedVenueType, setSelectedVenueType] = useState([]);

  const getLanguageList = (data) => {
    getLanguageApi(data)
      .then((res) => {
        setLanguageList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCategoryList = (data) => {
    getCategoriesApi(data)
      .then((res) => {
        setCategoryList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSessionTypesList = () => {
    getSessionTypesApi()
      .then((res) => {
        setSessionTypeList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const defaultTimezone = () => {
    const timezoneId = profile?.timezone_id;

    setSelectedTimezone({
      value: timezoneId,
      label: `System Default ${getCurrentTimeZone()}`,
    });
    setFieldValue("timezone_id", "233");
  };

  const getAllUsersList = (data) => {
    getAllUsersApi(data)
      .then((res) => {
        setUsersList(res?.data?.data?.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    // alert();
    window.$("#createSession").on("shown.bs.modal", function () {
      getLanguageList(activeData);
      getCategoryList(activeData);
      getSessionTypesList();
      getAllUsersList(activeData);
      defaultTimezone();
      logEvent(analytics, "create_session_opened");
    });
  }, []);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Session Title is required"),
    //date: Yup.string().required("Session Date is required"),
    date: Yup.date().required("Session date is required"),
    start_time: Yup.string()
      .required("Start Time is required")
      .test(
        "is-greater",
        "Start time is must be greater than current time",
        function (value) {
          const { date } = this.parent;

          // const fifteenMinuteBefore = moment().subtract(15, "minutes");

          // if (moment(date).isSame(moment(), "day")) {
          //   return moment(value, "HH:mm:ss").isAfter(fifteenMinuteBefore);
          // }

          const isGreaterThanCurrentTime = moment(value, "HH:mm:ss").isAfter(
            moment()
          );

          if (moment(date).isSame(moment(), "day")) {
            return isGreaterThanCurrentTime;
          }

          return true;
        }
      ),
    end_time: Yup.string()
      .required("End Time is required")
      .test(
        "is-greater",
        "End Time is must be greater than Start Time",
        function (value) {
          const start = this.parent.start_time;

          return start < value;
        }
      ),
    timezone_id: Yup.string().required("Timezone is required"),
    participate_limit: Yup.number()
      .typeError("Enter number only")
      .required("Max Participate Limit is required")
      .min(1, "Max Participate Limit must be at least 1")
      .max(100, "Max Participate Limit must be at most 100"),
    languageIds: Yup.array().min(1, "Select at least one Language"),
    about: Yup.string().required("About Session is required"),
    meeting_link: Yup.string()
      .required("Meeting Link is required")
      .url("Enter valid URL"),
    categoryIds: Yup.array()
      .min(1, "Select at least one Category")
      .required("Category is required"),
    banner_image: Yup.mixed()
      .required("Please select an image")
      .test(
        "fileType",
        "Invalid image file type. Please choose a JPEG, PNG, or GIF file.",
        (value) => {
          if (value) {
            const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
            return allowedTypes.includes(value.type);
          }
          return true;
        }
      ),
    session_type_id: Yup.string().required("Session Type is required"),
    visibility: Yup.string().required("Visibility is required"),
    participantIds: Yup.array().test(
      "is-required",
      "Select at least one user",

      function (value) {
        const { visibility } = this.parent;
        if (visibility === "INVITE_ONLY") {
          return value?.length > 0;
        }
        return true;
      }
    ),
    type: Yup.string().required("Type is required"),
  });
  const formik = useFormik({
    initialValues: {
      title: "",
      date: "",
      start_time: "",
      end_time: "",
      timezone_id: "",
      participate_limit: "",
      languageIds: [],
      about: "",
      meeting_link: "",
      categoryIds: [],
      banner_image: null,
      session_type_id: "",
      visibility: "",
      participantIds: [],
      type: "",
    },
    validationSchema,
    onSubmit: (values) => {
      setButtonDisable(true);

      const data = new FormData();
      data.append("title", values.title);
      data.append("date", values.date);
      data.append("start_time", values.start_time);
      data.append("end_time", values.end_time);
      data.append("timezone_id", values.timezone_id);
      data.append("participate_limit", values.participate_limit);
      for (let i = 0; i < values.languageIds.length; i++) {
        data.append("languageIds", values.languageIds[i]);
      }
      for (let i = 0; i < values.categoryIds.length; i++) {
        data.append("categoryIds", values.categoryIds[i]);
      }
      data.append("about", values.about);
      data.append("meeting_link", values.meeting_link);
      data.append("banner_image", values.banner_image);
      data.append("session_type_id", values.session_type_id);
      data.append("visibility", values.visibility);
      for (let i = 0; i < values.participantIds.length; i++) {
        data.append("participantIds", values.participantIds[i]);
      }

      createSessionApi(data)
        .then((res) => {
          if (res?.data?.success) {
            setIsSubmitted(true);
            formik.resetForm();
            toast.success(res?.data?.message, {
              position: "top-right",
              autoClose: 700,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            //window.location.href = "/dashboard";
          } else {
            toast.error(res?.data?.message, {
              position: "top-right",
              autoClose: 700,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
          setButtonDisable(false);
        })
        .catch((err) => {
          setButtonDisable(false);
          console.log(err);
        });
    },
  });

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = formik;

  const handleFileChange = (e) => {
    e.preventDefault();
    setFile(URL.createObjectURL(e.currentTarget.files[0]));
    setFieldValue("banner_image", e.currentTarget.files[0]);
  };

  const handleRemoveFile = () => {
    setFile(null);
    setFieldValue("banner_image", null);
  };

  useEffect(() => {
    if (isSubmitted) {
      setInterval(() => {
        window.$("#createSession").modal("hide");
      }, 1500);
    } else if (!isSubmitted) {
      window.$(document).ready(function () {
        window.$("#createSession").modal({
          backdrop: "static",
          keyboard: false,
        });
      });
    }

    setSelectedVenueType({
      value: Object.keys(sessionVenueType)[0],
      label: sessionVenueType[Object.keys(sessionVenueType)[0]],
    })
    setFieldValue("type", Object.keys(sessionVenueType)[0]);

  }, [isSubmitted]);

  const handleTextAreaChange = (event) => {
    setFieldValue("about", event.target.value);
    adjustTextAreaHeight(event.target);
  };

  const adjustTextAreaHeight = (textarea) => {
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleSelectSearch = (selectedOption) => {
    const selectedValue = selectedOption?.value;
    setSelectedTimezone(selectedOption);
    setFieldValue("timezone_id", selectedValue);
  };

  useEffect(() => {
    defaultTimezone();
  }, [profile])
  


  return (
    <>
      <div className="modal fade newmodal" id="createSession">
        <ToastContainer />
        <div className="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header text-center">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              >
                <img src={CloseIcon} alt="" width="" height="" />
              </button>
              <h4 className="text-center">Create Session</h4>
            </div>
            <div className="modal-body px-5 py-5">
              <form
                onSubmit={formik.handleSubmit}
                // onSubmit={e => {
                //   e.preventDefault()
                //   validationSchema.handleSubmit()
                //   return false
                // }}
              >
                <div className="row">
                  <div className="col-md-6">
                    <FormInput
                      name="title"
                      type="text"
                      label="Session Title"
                      placeholder="Enter Session Title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                      error={errors.title}
                      touched={touched.title}
                    />
                  </div>
                  <div className="col-md-6">
                    <DatePickerField
                      name="date"
                      label="Session Date"
                      value={selectedDate || ""}
                      onChange={(date) => {
                        const formattedDate = moment(date).format("YYYY-MM-DD");
                        setSelectedDate(date);
                        setFieldValue("date", formattedDate);
                      }}
                      // error={errors.date}
                      touched={touched.date}
                      placeholder="Select Date"
                      onBlur={handleBlur}
                      minDate={getTodaysDate()}
                      //min={moment().format("YYYY-MM-DD")}
                    />

                    {errors.date && touched.date && (
                      <div
                        className="text-danger"
                        style={{ marginTop: "-20px" }}
                      >
                        {errors.date}
                      </div>
                    )}
                  </div>
                  <div className="col-md-3">
                    <div className="form-outline mb-4">
                      <label className="form-label mb-0" htmlFor="">
                        Start Time
                      </label>
                      <div className="ui calendar timepicker">
                        <div className="d-flex align-items-center time right icon">
                          <DatePicker
                            name="start_time"
                            className="form-control"
                            selected={selectedStartTime || ""}
                            onChange={(date) => {
                              const formattedTime =
                                moment(date).format("HH:mm:ss");
                              setSelectedStartTime(date);
                              setFieldValue("start_time", formattedTime);
                            }}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            dateFormat="h:mm aa"
                            placeholderText="Select Time"
                          />

                          <i className="fa fa-clock-o"></i>
                        </div>
                        {errors.start_time && touched.start_time && (
                          <div className="text-danger">{errors.start_time}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-outline mb-4">
                      <label className="form-label mb-0" htmlFor="">
                        End Time
                      </label>
                      <div className="ui calendar timepicker">
                        <div className="d-flex align-items-center time right icon">
                          <DatePicker
                            name="end_time"
                            className="form-control"
                            selected={selectedEndTime || ""}
                            onChange={(date) => {
                              const formattedTime =
                                moment(date).format("HH:mm:ss");
                              setSelectedEndTime(date);
                              setFieldValue("end_time", formattedTime);
                            }}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            dateFormat="h:mm aa"
                            placeholderText="Select Time"
                          />
                          <i className="fa fa-clock-o"></i>
                        </div>
                        {errors.end_time && touched.end_time && (
                          <div className="text-danger">{errors.end_time}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-outline mb-4">
                      <label className="form-label mb-0" htmlFor="">
                        Select Time Zone
                      </label>
                      {/* <Select
                        name="timezone_id"
                        className=""
                        id="multiple-select-field5"
                        placeholder="Select Time Zone"
                        options={timezoneList?.map((item) => ({
                          value: item.id,
                          label: `GMT ${item.utc_offset} - ${item.timezone}`,
                        }))}
                        onChange={handleSelectSearch}
                        value={selectedTimezone || []}
                      /> */}
                      <TimezoneSelect
                        timezoneList={timezoneList}
                        handleSelectTimezone={handleSelectSearch}
                        selectedTimezone={selectedTimezone || []}
                      />
                      {errors.timezone_id && touched.timezone_id && (
                        <div className="text-danger">{errors.timezone_id}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <FormInput
                      name="participate_limit"
                      type="text"
                      label="Max Participate Limit(1-100)"
                      placeholder="Enter Max Participate Limit"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.participate_limit}
                      error={errors.participate_limit}
                      touched={touched.participate_limit}
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="form-outline mb-4">
                      <label className="form-label mb-0" htmlFor="">
                        Language for the session (Choose from list)
                      </label>
                      <Select
                        name="languageIds"
                        className=""
                        id="multiple-select-field6"
                        placeholder="Select Languages"
                        options={languageList?.map((item) => ({
                          value: item.id,
                          label: item.name,
                        }))}
                        onChange={(selectedOption) => {
                          const selectedValue = selectedOption?.value;
                          setSelectedLanguage(selectedOption);
                          setFieldValue("languageIds", [selectedValue]);
                        }}
                        value={selectedLanguage || []}
                      />
                      {errors.languageIds && touched.languageIds && (
                        <div className="text-danger">{errors.languageIds}</div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-outline mb-4">
                      <label className="form-label mb-0" htmlFor="">
                        Session Type (Choose from list)
                      </label>
                      <Select
                        name="session_type_id"
                        className=""
                        id="multiple-select-field6"
                        placeholder="Select Session Type"
                        options={sessionTypeList?.map((item) => ({
                          value: item.id,
                          label: item.name,
                        }))}
                        onChange={(selectedOption) => {
                          const selectedValue = selectedOption?.value;
                          setSelectedSessionType(selectedOption);
                          setFieldValue("session_type_id", selectedValue);
                        }}
                        value={selectedSessionType || []}
                      />
                      {errors.session_type_id && touched.session_type_id && (
                        <div className="text-danger">
                          {errors.session_type_id}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-outline mb-4">
                      <label className="form-label mb-0" htmlFor="">
                        Visibility
                      </label>
                      <Select
                        name="visibility"
                        className=""
                        id="multiple-select-field6"
                        placeholder="Select Session Visibility"
                        options={Object.keys(sessionVisibilityType).map(
                          (item) => ({
                            value: item,
                            label: sessionVisibilityType[item],
                          })
                        )}
                        onChange={(selectedOption) => {
                          const selectedValue = selectedOption?.value;
                          setSelectedVisibility(selectedOption);
                          setFieldValue("visibility", selectedValue);
                        }}
                        value={selectedVisibility || []}
                      />
                      {errors.visibility && touched.visibility && (
                        <div className="text-danger">{errors.visibility}</div>
                      )}
                    </div>
                  </div>

                  {selectedVisibility?.value === "INVITE_ONLY" && (
                    <div className="col-md-12">
                      <div className="form-outline mb-4">
                        <label className="form-label mb-0" htmlFor="">
                          Select Invitee (Choose from list)
                        </label>
                        <Select
                          name="participantIds"
                          className=""
                          id="multiple-select-field6"
                          placeholder="Select Users"
                          isMulti
                          options={usersList?.map((item) => ({
                            value: item.id,
                            label: (
                              <div className="d-flex align-items-center">
                                <img
                                  src={
                                    item.profileImageUrl === null
                                      ? AvatarImage
                                      : item.profileImageUrl
                                  }
                                  alt=""
                                  className="rounded-circle img-property-fit"
                                  width="30"
                                  height="30"
                                  onError={handleUserImageError}
                                />
                                <span className="ms-2">{`${item.first_name} ${item.last_name} (${item.user_name})`}</span>
                              </div>
                            ),
                          }))}
                          onChange={(selectedOption) => {
                            const selectedValue = selectedOption.map(
                              (item) => item.value
                            );
                            setSelectedUsers(selectedOption);
                            setFieldValue("participantIds", selectedValue);
                          }}
                          value={selectedUsers || []}
                        />
                        {errors.participantIds && touched.participantIds && (
                          <div className="text-danger">
                            {errors.participantIds}
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="col-md-6">
                    <div className="form-outline mb-4">
                      <label className="form-label mb-0" htmlFor="">
                        Type
                      </label>
                      <Select
                        name="type"
                        className=""
                        id="multiple-select-field6"
                        placeholder="Select Session Visibility"
                        options={Object.keys(sessionVenueType).map((item) => ({
                          value: item,
                          label: sessionVenueType[item],
                        }))}
                        onChange={(selectedOption) => {
                          const selectedValue = selectedOption?.value;
                          setSelectedVenueType(selectedOption);
                          setFieldValue("type", selectedValue);
                        }}
                        value={selectedVenueType || []}
                        isDisabled={true}
                      />
                      {errors.type && touched.type && (
                        <div className="text-danger">{errors.type}</div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <label className="form-label mb-0" htmlFor="">
                      About the Session
                    </label>
                    <textarea
                      style={{ resize: "none" }}
                      className="form-control"
                      name="about"
                      type="textarea"
                      label="About the Session"
                      placeholder="Enter About the Session"
                      onChange={handleTextAreaChange}
                      onBlur={handleBlur}
                      value={values.about}
                      // error={errors.about}
                      touched={touched.about}
                    />
                    {errors.about && touched.about && (
                      <div className="text-danger">{errors.about}</div>
                    )}
                  </div>
                  {/* <div className="col-md-12">
                <label className="form-label mb-0" htmlFor="">
                    About the Session
                  </label>
                <CKEditor
                    editor={ ClassicEditor }
                    data="<p>Hello from CKEditor 5!</p>"
                   
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        console.log(data);
                        setFieldValue("about", data);
                    } }
                    
                   
                />
                </div> */}
                  <div className="col-md-12">
                    <br></br>
                    <FormInput
                      name="meeting_link"
                      type="text"
                      label="Meeting Link ( meet/zoom/teams/or any platform you prefer"
                      placeholder="Enter Meeting Link"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.meeting_link}
                      error={errors.meeting_link}
                      touched={touched.meeting_link}
                    />
                  </div>
                  <div className="col-md-12">
                    <div className="form-outline mb-4">
                      <label className="form-label mb-0" htmlFor="">
                        Topics
                      </label>
                      <Select
                        name="categoryIds"
                        className=""
                        id="multiple-select-field6"
                        placeholder="Select Topics"
                        isMulti
                        options={categoryList?.map((item) => ({
                          value: item.id,
                          label: item.name,
                        }))}
                        onChange={(selectedOption) => {
                          const selectedValue = selectedOption.map(
                            (item) => item.value
                          );
                          setSelectedCategory(selectedOption);
                          setFieldValue("categoryIds", selectedValue);
                        }}
                        value={selectedCategory || []}
                      />
                      {errors.categoryIds && touched.categoryIds && (
                        <div className="text-danger">{errors.categoryIds}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-outline mb-4">
                      <label className="form-label mb-0" htmlFor="">
                        Image or Banner
                      </label>
                      {errors.banner_image && touched.banner_image && (
                        <div className="text-danger">{errors.banner_image}</div>
                      )}
                      <div className="file-upload">
                        <div
                          className="image-upload-wrap"
                          style={{ display: `${file ? "none" : ""}` }}
                        >
                          <input
                            name="banner_image"
                            className="file-upload-input"
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                          />

                          <div className="drag-text d-flex align-items-center justify-content-center flex-column">
                            <img
                              src={uploadIcon}
                              alt="preview-icon"
                              width="24"
                              height="24"
                            />
                            <h3 className="mb-0">
                              Upload a file <span>or drag and drop</span>
                            </h3>
                            <span>PNG, JPG, GIF, PDF, Upto 5MB</span>
                          </div>
                        </div>
                        <div
                          className="file-upload-content"
                          style={{ display: `${file ? "block" : ""}` }}
                        >
                          <img
                            className="file-upload-image"
                            src={file}
                            alt="your image"
                          />

                          <div className="image-title-wrap">
                            <button
                              type="button"
                              onClick={handleRemoveFile}
                              className="remove-image"
                            >
                              <img
                                src={CloseIcon}
                                alt="close-icon"
                                width="18"
                                height="18"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                onClick={() => formik.handleSubmit()}
                disabled={isButtonDisable}
                className="btn btn-login btn-lg btn-block w-100 btn-submit"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateSessionModal;
