import React, { useEffect, useState } from "react";
import CloseIcon from "../../../assets/images/icon_close.png";
import { resendVerificationEmailApi } from "api/authApi";
import { ToastContainer, toast } from "react-toastify";

const VerifyEmailModal = ({ email }) => {
  const [verifyemail, setVerifyEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const sendVerificationEmail = () => {
    resendVerificationEmailApi({ email: verifyemail })
      .then((res) => {
        if (res?.data?.success) {
          toast.success(res?.data?.message, {
            position: "top-right",
              autoClose: 700,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light",
          });
            setIsSubmitted(true);
        }else{
            toast.error(res?.data?.message, {
                position: "top-right",
                autoClose: 700,
                hideProgressBar: false,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }

      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    window.$("#verifyEmail").on("shown.bs.modal", function () {
      if (email) {
        setVerifyEmail(email);
      }
    });
  }, [email]);

  useEffect(() => {
    if(isSubmitted){
        setInterval(() => {
            window.$("#verifyEmail").modal("hide");
          }, 1500);
    }
  }, [isSubmitted])
  

  return (
    <div className="modal fade newmodal" id="verifyEmail">
      <div className="modal-dialog modal-dialog-centered modal-sm">
        <div className="modal-content">
          <div className="modal-header text-center">
            <button type="button" className="btn-close" data-bs-dismiss="modal">
              <img src={CloseIcon} alt="closeicon" width="" height="" />
            </button>
            <h4 className="text-center">Verify Email</h4>
          </div>

          <div className="modal-body">
            <div className="text-center">
              <p className="mb-4">
                Please verify your email address to continue.
              </p>
              <button className="btn bg-dark text-white me-3" data-bs-dismiss="modal">
                OK
              </button>

              <button className="btn bg-primary1 text-white" onClick={()=> sendVerificationEmail()}>
                Resend Email
              </button>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default VerifyEmailModal;
