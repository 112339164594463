import React, { useEffect, useState } from "react";
import CloseIcon from "../../../assets/images/icon_close.png";
import { getCMSPages } from "api/cmsApi";
import { logEvent } from "firebase/analytics";
import analytics from 'index';

const PrivacyPolicyModal = ({ pageType }) => {
  const [pageContent, setPageContent] = useState({});

  const getPageContent = (pageType) => {
    getCMSPages({ pageType: pageType })
      .then((res) => {
        setPageContent(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getPageContent(pageType);
    logEvent(analytics, 'privacy_policy_opened');
  }, [pageType]);

  return (
    <div className="modal fade newmodal" id="privacyPolicyModal">
      <div
        className="modal-dialog modal-sm modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header text-center">
            <button type="button" className="btn-close" data-bs-dismiss="modal">
              <img src={CloseIcon} alt="" width="" height="" />
            </button>
            <h4 className="text-center">{pageContent?.name}</h4>
          </div>
          <div className="modal-body px-5 py-4" dangerouslySetInnerHTML={{ __html: pageContent?.content }}>
          
          </div>
          <div className="modal-footer justify-content-center">
            <button
              type="button"
              className="btn btn-login"
              data-bs-dismiss="modal"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyModal;
