import React, { useState } from "react";
import { Link } from "react-router-dom";

const CategoryTab = ({
  categories,
  handleCategoryChange,
  selectedCategory,
  handleClearCategory,
  isAllActive,
}) => {

 
  return (
    <ul className="filter-list-custom">
      <li>
        <Link className={isAllActive ? "active" : ""} onClick={()=> handleClearCategory()}>All</Link>
      </li>
      {categories &&
        categories.map((category, index) => (
          <li key={index}>
            <Link
              to={category.link}
              className={
                Array.isArray(selectedCategory) &&
                selectedCategory.includes(category.id)
                  ? "active"
                  : ""
              }
              onClick={() => handleCategoryChange(category.id)}
            >
              {category.name}
            </Link>
          </li>
        ))}
    </ul>
  );
};

export default CategoryTab;
