import React, { useEffect, useState } from "react";
import StoryLayout from "./layout";
import Header from "../components/Header";
import StoryImageOne from "../../../assets/images/story1.svg";
import AdBannerOne from "../../../assets/images/ad-banner1.svg";
import AdBannerTwo from "../../../assets/images/ad-banner2.svg";
import MoreStoryImage from "../../../assets/images/more-story-image.svg";
import StorySessionCard from "../components/StorySessionCard";
import FacebookIcon from "../../../assets/images/footer-facebook.svg";
import TwitterIcon from "../../../assets/images/footer-twitter.svg";
import LinkedinIcon from "../../../assets/images/footer-linkedin.svg";
import YoutubeIcon from "../../../assets/images/footer-youtube.svg";
import CalendarIcon from "../../../assets/images/calendar-icon.svg";
import { Link, useParams } from "react-router-dom";
import { getStoryApi } from "api/storyApi";
import moment from "moment";
import { getStoriesApi } from "api/storyApi";
import { getSessionsApi } from "api/sessionApi";
import { formatGlobalDateTime } from "helpers/common_helper";
import { getSystemTimezoneAbbr } from "helpers/common_helper";
import SessionDetailModal from "pages/Dashboard/Modal/SessionDetailModal";

const Stories = () => {
  const params = useParams();
  const storyId = params.id;
  const [storyDetails, setStoryDetails] = useState({});
  const [storyList, setStoryList] = useState([]);
  const [sessionList, setSessionList] = useState([]);
  const [selectedSession, setSelectedSession] = useState(0);
  const [activeData, setActiveData] = useState({
    page: 1,
    limit: 4,
    search: "",
    except_story_id: storyId,
    filter: {
      languageFilter: "",
      categoryFilter: "",
      start_date: "",
      end_date: "",
    },
  });

  useEffect(() => {
    const existingLinks = document.querySelectorAll('link[rel="stylesheet"]');
    existingLinks.forEach((link) => link.remove());

    const cssFiles = [
      "/assets/css/home.css",
      "/assets/css/bootstrap-latest/css/bootstrap.min.css",
      "https://use.fontawesome.com/releases/v5.8.1/css/all.css",
      "/assets/css/semantic.min.css",
    ];
    cssFiles.forEach((cssFile) => {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = cssFile;
      document.head.appendChild(link);
    });

    return () => {
      cssFiles.forEach((cssFile) => {
        const link = document.querySelector(`link[href="${cssFile}"]`);
        if (link) {
          link.remove();
        }
      });
    };
  }, []);

  const getStoryDetails = () => {
    getStoryApi({ story_id: storyId })
      .then((res) => {
        setStoryDetails(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getStoryList = (data) => {
    getStoriesApi(data)
      .then((res) => {
        setStoryList(res?.data?.data?.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSessionsList = (data) => {
    getSessionsApi(data)
      .then((res) => {
        setSessionList(res?.data?.data?.sessions);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSessionClick = (sessionId) => {
    setSelectedSession(sessionId);
  };

  useEffect(() => {
    getStoryDetails();
    getStoryList(activeData);
    getSessionsList(activeData);
  }, []);

  return (
    <>
      <div className="container-fluid main-header">
        <Header />
      </div>
      <hr className="m-0 p-0" />
      <div className="container-fluid story-bg d-flex justify-content-center align-items-center">
        <div className="container-lg">
          <h1 className="text-center text-white">{storyDetails?.title}</h1>
        </div>
      </div>

      <section>
        <div className="container-lg">
          <div className="row full-col">
            <div className="col-md-8 d-flex justify-content-center">
              {/* Image */}
              <div className="img-fluid">
                <img
                  src={
                    storyDetails?.bannerImageUrl === (null || undefined)
                      ? StoryImageOne
                      : storyDetails?.bannerImageUrl
                  }
                  alt="Story"
                  className="story-image-one img-property-fit"
                />

                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12 d-flex align-items-center">
                        <div className="text-black">
                          <h2>Details</h2>
                        </div>

                        <div className="text-black ms-auto">
                          <div className="d-flex align-items-center me-5">
                            <div className="me-3">
                              {/* <i
                                className="fa fa-calendar"
                                style={{ fontSize: "30px" }}
                              ></i> */}
                              <img src={CalendarIcon} alt="calendar-icon" />
                            </div>
                            <div>
                              <p className="mb-0">Updated</p>
                              <p className="mb-0">
                                {moment(storyDetails?.created_at).format(
                                  "DD MMM, YYYY"
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-12">
                    <div className="description-text" dangerouslySetInnerHTML={{ __html : storyDetails?.detail_html }}>
                      
                    </div>
                  </div>

                  {storyDetails?.youtube_link !== null &&
                    storyDetails?.youtube_link !== undefined &&
                    storyDetails?.youtube_link !== "" && (
                      <div className="col-md-6">
                        <iframe
                          src={storyDetails?.youtube_link}
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowfullscreen
                          className="youtube-player"
                        ></iframe>
                      </div>
                    )}
                </div>

                {storyDetails?.username !== null &&
                  storyDetails?.username !== undefined &&
                  storyDetails?.username !== "" && (
                    <div className="row mt-3 mb-5">
                      <div className="col-md-12">
                        <div className="d-flex justify-content-start">
                          <button
                            className="btn more-stories-button"
                            onClick={() =>
                              (window.location.href = `/user-profile/${storyDetails?.username}`)
                            }
                          >
                            <h3>{`Visit ${storyDetails?.first_name}'s Profile`}</h3>
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <div className="col-md-4 ad-col">
              <div className="row justify-content-center">
                <div className="col-md-12">
                  <img
                    src={AdBannerOne}
                    alt="Ad Banner"
                    className="img-fluid"
                  />

                  <img
                    src={AdBannerTwo}
                    alt="Adbanner"
                    className="img-fluid mt-3"
                  />
                </div>
              </div>
            </div>

            {storyList && storyList.length > 0 && (
              <div className="row mt-3">
              <div className="col-md-12 mb-4">
                <div className="d-flex more-heading">
                  <h6 className="more-story-header">More Stories</h6>
                </div>
              </div>

              <div className="col-md-12">
                <div className="row">
                  {storyList &&
                    storyList.map((item, index) => (
                      <div className="col-md-3" key={index}>
                        <Link to={`/story-details/${item?.id}`}>
                          <div className="card more-stories-card">
                            <img
                              src={
                                item?.bannerImageUrl === (null || undefined)
                                  ? MoreStoryImage
                                  : item?.bannerImageUrl
                              }
                              alt="storyimages"
                              className="card-img-top more-story-images img-property-fit"
                            />
                            <div className="card-body">
                              <p className="card-title text-center">
                                {item?.title}
                              </p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            )}
            

            {sessionList && sessionList.length > 0 && (
               <div className="row mt-5">
               <div className="col-md-12 mb-4">
                 <div className="d-flex justify-content-start">
                   <h6 className="more-story-header">
                     Session you may interested
                   </h6>
                 </div>
               </div>
 
               <div className="col-md-12">
                 <div className="row">
                   {sessionList &&
                     sessionList?.map((session, index) => (
                       <StorySessionCard
                         title={session?.title}
                         datetime={formatGlobalDateTime(
                           session?.start_date_time
                         )}
                         language={session?.languages[0]?.languageName}
                         backgroundImage={session?.bannerImageUrl}
                         userImage={session?.user?.profileImageUrl}
                         userName={`${session?.user?.first_name} ${session?.user?.last_name}`}
                         sessionType={session?.sessionTypeName}
                         sessionVisibility={session?.type}
                         handleSessionClick={() =>
                           handleSessionClick(session.id)
                         }
                         user_name={session?.user?.user_name}
                         session_id={session?.id}
                         sessionTypeColor={session?.sessionTypeColor}
                         participantLimit={session?.participate_limit}
                         participantCount={session?.participant_count}
                         timezoneAbbrevation={getSystemTimezoneAbbr()}
                       />
                     ))}
                 </div>
               </div>
             </div>
            )}
           
          </div>
        </div>
      </section>
      <SessionDetailModal session_id={selectedSession}/>

      <footer className="d-flex flex-wrap justify-content-between align-items-center footer-section">
        <div className="col-md-6 d-flex align-items-center">
          <a
            href="/"
            className="mb-3 me-2 mb-md-0 text-body-secondary text-decoration-none lh-1"
          >
            {/* <svg className="bi" width="30" height="24">
              <use xlink:href="#bootstrap"></use>
            </svg> */}
          </a>
          <span className="mb-3 mb-md-0 text-white">
            © Copyright 2023{" "}
            <span className="footer-text ps-2">iLoma Technology Pvt. Ltd.</span>
            All Rights Reserved
          </span>
        </div>

        {/* <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
          <li className="ms-3">
            <a className="text-body-secondary" href="javascript:void(0)">
              <img
                src={FacebookIcon}
                alt="facebookicon"
                height="20px"
                width="20px"
              />
            </a>
          </li>
          <li className="ms-3">
            <a className="text-body-secondary" href="javascript:void(0)">
              <img
                src={TwitterIcon}
                alt="twittericon"
                height="20px"
                width="20px"
              />
            </a>
          </li>
          <li className="ms-3">
            <a className="text-body-secondary" href="javascript:void(0)">
              <img
                src={LinkedinIcon}
                alt="linkedinicon"
                height="22px"
                width="22px"
              />
            </a>
          </li>

          <li className="ms-3 me-5">
            <a className="text-body-secondary" href="javascript:void(0)">
              <img
                src={YoutubeIcon}
                alt="youtubeicon"
                height="22px"
                width="22px"
              />
            </a>
          </li>
        </ul> */}
      </footer>
    </>
  );
};

export default Stories;
