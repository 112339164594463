import { handleUserImageError } from "helpers/common_helper";
import React from "react";

const NotificationCard = ({ userImage, title, message, datetime }) => {
  return (
    <div className="card mb-4">
      <div className="card-body py-2 px-4">
        <div className="d-flex align-items-center justify-content-between pt-2 user-r">
          <div className="d-flex align-items-center">
            <div className="me-2">
              <img
                src={userImage}
                className="rounded-circle img-property-fit"
                alt="user"
                width="25"
                height="25"
                onError={handleUserImageError}
              />
            </div>
            <div className="ms-2">
              <h4 className="mb-0 mt-0">{title}</h4>
              <p>{message}</p>
            </div>
          </div>
          <div className="d-flex">
            <div>
              <p className="mb-0">{datetime}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationCard;
