import { isUserLoggedIn } from "helpers/common_helper";
import Layout from "layouts/Index";
import SidebarMenu from "layouts/SidebarMenu";
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { changePasswordApi } from "api/authApi";
import { useMediaQuery } from "react-responsive";

const ChangePassword = () => {

  const isMobileView = useMediaQuery({ query: "(max-width: 767px)" });


  const validationSchema = Yup.object().shape({
    current_password: Yup.string().required("Current Password is required"),
    new_password: Yup.string().min(6).required("New Password is required"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("new_password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      current_password: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      changePasswordApi(values)
        .then((res) => {
          if (res?.data?.success) {
            toast.success(res?.data?.message, {
              position: "top-right",
              autoClose: 700,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });

            formik.resetForm();
          } else {
            toast.error(res?.data?.message, {
              position: "top-right",
              autoClose: 700,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });

  return (
    <Layout>
      <section className="main mt-5 py-5">
        <div className="container-fluid container-fluid-p">
          <div className="row">
          {!isMobileView && (
                <div
                className={`col-md-2 pt-5 left-block sticky-sidebar ${
                  isUserLoggedIn() ? "" : "locked"
                }`}
              >
                <SidebarMenu />
              </div>
              )}

            <div className="col-md-10 right-block">
              <div className="tab-content d-flex justify-content-center">
                <div className={`card  ${isMobileView ? "w-100": "w-50"}`}>
                  <div className="card-body">
                    <h4 className="card-title text-center">Change Password</h4>
                    <form onSubmit={formik.handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="current_password" className="mb-2">
                          Current Password
                        </label>
                        <input
                          type="password"
                          className="form-control mb-3"
                          id="current_password"
                          name="current_password"
                          placeholder="Enter Current Password"
                          {...formik.getFieldProps("current_password")}
                        />
                        {formik.touched.current_password &&
                        formik.errors.current_password ? (
                          <div className="text-danger">
                            {formik.errors.current_password}
                          </div>
                        ) : null}

                        <label htmlFor="new_password" className="mb-2">
                          New Password
                        </label>
                        <input
                          type="password"
                          className="form-control mb-3"
                          id="new_password"
                          name="new_password"
                          placeholder="Enter New Password"
                          {...formik.getFieldProps("new_password")}
                        />

                        {formik.touched.new_password &&
                        formik.errors.new_password ? (
                          <div className="text-danger">
                            {formik.errors.new_password}
                          </div>
                        ) : null}

                        <label htmlFor="confirm_password" className="mb-2">
                          Confirm Password
                        </label>
                        <input
                          type="password"
                          className="form-control mb-3"
                          id="confirm_password"
                          name="confirm_password"
                          placeholder="Enter Confirm Password"
                          {...formik.getFieldProps("confirm_password")}
                        />

                        {formik.touched.confirm_password &&
                        formik.errors.confirm_password ? (
                          <div className="text-danger">
                            {formik.errors.confirm_password}
                          </div>
                        ) : null}
                      </div>

                      <div className="d-flex justify-content-center">
                        <button
                          type="submit"
                          className="btn bg-primary1 btn-block w-50 mt-4 mb-3"
                          style={{ color: "#ffffff" }}
                          onClick={formik.handleSubmit}
                        >
                          Change Password
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <ToastContainer />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ChangePassword;
