import LeftSideBanner from "components/ui/Authentication/LeftSideBanner";
import FilledButton from "components/ui/Common/FilledButton";
import Heading from "components/ui/Common/Heading";
import Input from "components/ui/Common/Input";
import Label from "components/ui/Common/Label";
import React, { useState } from "react";
import GoogleLogo from "../../assets/images/google.png";
import OutlinedButton from "components/ui/Common/OutlinedButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { loginApi } from "api/authApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useGoogleLogin } from "@react-oauth/google";
import getUserInfo from "services/GoogleAuth/authResponse";
import { getCurrenGMTOffset } from "helpers/common_helper";
import { getCurrentTimeZone } from "helpers/common_helper";

const Login = () => {
  const timezone = getCurrentTimeZone();
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string().min(6).required("Password is required"),
    // login_type: Yup.string().required("Login type is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      login_type: "Email",
      google_id: "",
    },
    validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      loginApi(values)
        .then((res) => {
          if (res?.data?.success) {
            localStorage.setItem("_lltoken", res?.data?.token);
            localStorage.setItem("_lluser", JSON.stringify(res?.data?.data));
            localStorage.setItem("_lltimezone", res?.data?.data?.timezone);
            setLoading(false);

            toast.success(res?.data?.message, {
              position: "top-right",
              autoClose: 700,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            window.location.href = "/dashboard";
          } else {
            toast.error(res?.data?.message, {
              position: "top-right",
              autoClose: 700,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      getUserInfo(codeResponse?.access_token).then((response) => {
        console.log(response);
        setLoading(true);
        loginApi({
          first_name: response?.given_name,
          last_name:
            response?.family_name === (undefined || null)
              ? response?.given_name
              : response?.family_name,
          email: response?.email,
          password: "",
          login_type: "Google",
          google_id: response?.id,
          timezone: timezone,
        })
          .then((resp) => {
            if (resp?.data?.success) {
              localStorage.setItem("_lltoken", resp?.data?.token);
              localStorage.setItem("_lluser", JSON.stringify(resp?.data?.data));
              localStorage.setItem("_lltimezone", resp?.data?.data?.timezone);
              setLoading(false);

              toast.success(resp?.data?.message, {
                position: "top-right",
                autoClose: 700,
                hideProgressBar: false,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });

              window.location.href = "/dashboard";
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  return (
    <section className="vh-100 login">
      <div className="container-fluid h-custom">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-md-6 col-lg-6 col-xl-4">
            <LeftSideBanner />
          </div>

          {loading ? (
            <>
              <div className="justify-content-center d-flex align-items-center">
                <div className="loadingio-spinner-rolling-26k4myxj9b9">
                  <div className="ldio-y9y7x49yybs">
                    <div></div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col-md-6 col-lg-6 col-xl-4 offset-xl-1">
                <form
                  className="login-form py-5 px-5"
                  onSubmit={formik.handleSubmit}
                >
                  <Heading title="Sign in" />
                  <p>Please enter your credential to login.</p>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-outline mb-4">
                        <Label title="Email" />
                        <Input
                          id="email"
                          name="email"
                          type="email"
                          placeholder=""
                          field={formik.getFieldProps("email")}
                          form={formik}
                          autocomplete="off"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-outline mb-4">
                        <Label title="Password" />
                        <Input
                          type="password"
                          id="password"
                          name="password"
                          placeholder=""
                          field={formik.getFieldProps("password")}
                          form={formik}
                          autocomplete="off"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="text-end">
                    <Link to={`/update-password`} className="text-forgot">
                      Forgot password?
                    </Link>
                  </div>

                  <div className="text-center mt-2 pt-2">
                    <FilledButton
                      title="Login"
                      handleSubmit={formik.handleSubmit}
                    />
                    <p className="or py-3 mb-0">Or</p>

                    <OutlinedButton
                      path={"#!"}
                      title="Login with Google"
                      image={GoogleLogo}
                      handleClick={handleGoogleLogin}
                    />

                    <p className="small mt-4 mb-0">
                      Don't have an account?{" "}
                      <Link to="/register" className="text-primary-bold">
                        Sign Up
                      </Link>
                    </p>
                    <p className="mt-4">
                      <Link to={`/dashboard`}>Skip for now</Link>
                    </p>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default Login;
