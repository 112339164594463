import React, { useState } from "react";
import DotImage from "../assets/images/dot.png";
import FooterLink from "components/ui/Common/FooterLink";
import InstagramImage from "../assets/images/instagram.png";
import FooterSocial from "components/ui/Common/FooterSocial";
import FooterLinks from '../data/footer-links.json';
import FacebookImage from "../assets/images/facebook.png";
import TwitterImage from "../assets/images/twitter.png";
import PrivacyPolicyModal from "pages/PrivacyPolicy/Modal/PrivacyPolicyModal";

const Footer = () => {

  const [pageType, setPageType] = useState("PrivacyPolicy");

  const getYear = () => {
    return new Date().getFullYear();
  };

  return (
    <>
    <footer>
      <div className="container-fluid  container-fluid-p">
        <div className="row d-flex align-items-center ">
          <div className="col-md-6">
            Copyright &copy; {getYear()} iLoma Technology. All Right Reserved
          </div>
        
        <div className="col-md-6">
          <ul className="d-flex align-items-end links justify-content-end">
            {FooterLinks.map((footerLink, index) => (
                <FooterLink
                key={index}
                  title={footerLink.title}
                    image={DotImage}
                    link={footerLink.link}
                    handleClick={() => setPageType(footerLink?.link)}
                />
            ))}
          </ul>
        </div>
        {/* <div className="col-md-4">
          <ul className="d-flex align-items-center social-links justify-content-end social">
            <FooterSocial image={InstagramImage} link="javascript:void(0)" />
            <FooterSocial image={FacebookImage} link="javascript:void(0)" />
            <FooterSocial image={TwitterImage} link="javascript:void(0)" />
          </ul>
        </div> */}
        </div>
      </div>
    </footer>
    <PrivacyPolicyModal pageType={pageType}/>
    </>
  );
};

export default Footer;
