import { getAllUsersApi } from "api/profileApi";
import React, { useEffect, useState } from "react";
import CloseIcon from "../../../assets/images/icon_close.png";
import AvatarImage from "../../../assets/images/profile-placeholder.png";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { sendSessionInviteApi } from "api/sessionInviteApi";

const InviteSessionModal = () => {
  const params = useParams();
  const sessionId = params.id;
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeData, setActiveData] = useState({
    page: 1,
    limit: 10,
    search: "",
  });

  const getUsersList = (data) => {
    setLoading(true);
    getAllUsersApi(data)
      .then((res) => {
        setUsers(res?.data?.data?.rows);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createSessionInvite = (data) => {
    sendSessionInviteApi(data)
      .then((res) => {
        if (res?.data?.success) {
          toast.success(res?.data?.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setTimeout(() => {
            window.$("#inviteModal").modal("hide");
          }, 1500);
          setSelectedUsers([]);
          setSearchTerm("");
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    window.$("#inviteModal").on("shown.bs.modal", function () {
      getUsersList(activeData);
    });
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
    getUsersList({ ...activeData, search: e.target.value });
  };

  const handleUserSelect = (userId) => {
    setSelectedUsers((prevSelectedUsers) => {
      if (prevSelectedUsers?.includes(userId)) {
        return prevSelectedUsers?.filter((id) => id !== userId);
      }
      return [...prevSelectedUsers, userId];
    });
  };

  const handleSendInvites = () => {
    createSessionInvite({ sessionId: sessionId, participantIds: selectedUsers });
  };

  return (
    <div className="modal fade newmodal" id="inviteModal">
      <div
        className="modal-dialog modal-sm modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header text-center">
            <button type="button" className="btn-close" data-bs-dismiss="modal">
              <img src={CloseIcon} alt="" width="" height="" />
            </button>
            <h4 className="text-center">Invite Users</h4>
          </div>
          <div className="modal-body px-5 py-4">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search users..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
            {loading ? (
              <>
                <div className="justify-content-center d-flex align-items-center">
                  <div className="loadingio-spinner-rolling-26k4myxj9b9">
                    <div className="ldio-y9y7x49yybs">
                      <div></div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <ul className="list-group">
                  {users?.map((user) => (
                    <li
                      key={user.id}
                      className="list-group-item d-flex justify-content-between align-items-center"
                      onClick={() => handleUserSelect(user.id)}
                      style={{ cursor: "pointer" }}
                    >
                      <span>
                        <span className="me-2">
                          <img src={AvatarImage} height="30px" width="30px" />
                        </span>
                        <span>
                          {user.first_name} {user.last_name}
                        </span>
                      </span>
                      {selectedUsers?.includes(user.id) ? (
                        <i
                          className="fa fa-check"
                          style={{ color: "green" }}
                        ></i>
                      ) : (
                        <i className="fa fa-times" style={{ color: "red" }}></i>
                      )}
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
          <div className="modal-footer justify-content-center">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSendInvites}
            >
              Send Invites
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default InviteSessionModal;
