import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getCategoriesApi } from "api/categoryApi";
import { getLanguageApi } from "api/sessionApi";
import { updateProfileApi } from "api/profileApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const InterestModal = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [interestList, setInterestList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [activeData, setActiveData] = useState({
    page: 1,
    limit: 10,
    search: "",
  });
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);

  useEffect(() => {
    if (isSubmitted) {
      window.$("#myinterest").modal("hide");
    } else if (!isSubmitted) {
      window.$(document).ready(function () {
        window.$("#myinterest").modal({
          backdrop: "static",
          keyboard: false,
        });
      });
    }
  }, [isSubmitted]);

  const getInterestList = (data) => {
    getCategoriesApi(data)
      .then((res) => {
        setInterestList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLanguageList = (data) => {
    getLanguageApi(data)
      .then((res) => {
        setLanguageList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    window.$("#myinterest").on("shown.bs.modal", function () {
      getInterestList(activeData);
      getLanguageList(activeData);
    });
  }, [activeData]);

  const formik = useFormik({
    initialValues: {
      categoryIds: "",
      languageIds: "",
      about_me: "",
    },
    validationSchema: Yup.object({
      categoryIds: Yup.array()
        .min(2, "Select at least two interests")
        .required("Select at least two interests"),
      languageIds: Yup.array()
        .min(1, "Select at least two languages")
        .required("Select at least two languages"),
      about_me: Yup.string()
        .min(100, "Please enter at least 100 characters")
        .required("Tell us more about yourself is required"),
    }),
    onSubmit: (values) => {
      updateProfileApi(values)
        .then((res) => {
          if (res?.data?.success) {
            toast.success(res?.data?.message, {
              position: "top-right",
              autoClose: 700,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            localStorage.setItem("_isOnboarded", true);
          } else {
            toast.error(res?.data?.message, {
              position: "top-right",
              autoClose: 700,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
      setIsSubmitted(true);
    },
  });

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = formik;

  const modalRef = useRef(null);

  const handleModalClick = (e) => {
    if (e.target === modalRef.current) {
      e.stopPropagation();
    }
  };

  return (
    <div
      className="modal fade"
      id="myinterest"
      data-backdrop="static"
      data-keyboard="false"
      ref={modalRef}
      onClick={handleModalClick}
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body px-5 py-5">
            <form onSubmit={handleSubmit}>
              <div className="form-outline mb-4">
                <label className="form-label mb-0">
                  Select your interests
                  <small>(Minimum 2, you can edit in profile later)</small>
                </label>
                <Select
                  name="categoryIds"
                  className=""
                  id="multiple-select-field"
                  isMulti
                  placeholder="Select your interests"
                  options={interestList?.map((item) => {
                    return {
                      value: item.id,
                      label: item.name,
                    };
                  })}
                  onChange={(selectedOptions) => {
                    const selectedValues = selectedOptions?.map((item) => {
                      return item.value;
                    });
                    setSelectedInterests(selectedOptions);
                    setFieldValue("categoryIds", selectedValues);
                  }}
                  value={selectedInterests || []}
                  onBlur={handleBlur}
                />
                {errors.categoryIds && touched.categoryIds ? (
                  <div className="text-danger">{errors.categoryIds}</div>
                ) : null}
              </div>
              <div className="form-outline mb-4">
                <label className="form-label mb-0">
                  Select languages you know
                  <small>(Minimum 1, you can edit in profile later)</small>
                </label>
                <Select
                  name="languageIds"
                  className=""
                  id="multiple-select-field2"
                  isMulti
                  placeholder="Select languages you know"
                  options={languageList?.map((item) => {
                    return {
                      value: item.id,
                      label: item.name,
                    };
                  })}
                  onChange={(selectedOptions) => {
                    const selectedValues = selectedOptions?.map((item) => {
                      return item.value;
                    });
                    setSelectedLanguages(selectedOptions);
                    setFieldValue("languageIds", selectedValues);
                  }}
                  value={selectedLanguages || []}
                />
                {errors.languageIds && touched.languageIds ? (
                  <div className="text-danger">{errors.languageIds}</div>
                ) : null}
              </div>
              <div className="form-outline mb-4">
                <div className="row align-items-center">
                  <div className="col">
                    <label className="form-label mb-0">
                      Tell us about yourself<small>(Min 100 characters)</small>
                    </label>
                  </div>
                  {values.about_me && (
                    <div className="col-auto character-count">
                      {values.about_me.length}/100
                    </div>
                  )}
                </div>
                <textarea
                  name="about_me"
                  className="form-control"
                  placeholder="Write something here"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.about_me || ""}
                  autoComplete="off"
                ></textarea>

                {errors.about_me && touched.about_me ? (
                  <div className="text-danger">{errors.about_me}</div>
                ) : null}
              </div>
              <button
                onClick={handleSubmit}
                className="btn btn-login btn-lg btn-block w-100 btn-submit"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default InterestModal;
