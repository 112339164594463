import React from "react";

const HeroBanner = () => {
  return (
    <div class="col-md-6 col-sm-3 col-11 mb-0 mt-1">
      <div class="side-line">
        <img src="assets/images/curly_line.svg" alt="curly_line" />
      </div>
      <div class="hero-div-one">
        <p class="hero-text">
          Global Manch(Stage) <br />
          Where people can
          <br />
          <span class="connect-text">
            <span class="connect">connect,</span> learn, represent
          </span>
        </p>

        <div class="d-grid gap-2 d-md-flex justify-content-md-start">
          <button type="button" class="btn btn-join-us" onClick={()=> {window.location.href = "/login"}}>
            Join us
          </button>
        </div>
      </div>
    </div>
  );
};

export default HeroBanner;
