import React, { useEffect, useState } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CloseIcon from "../../assets/images/icon_close.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getLanguageApi } from "api/sessionApi";
import { getCategoriesApi } from "api/categoryApi";
import moment from "moment";
import { postRequestSessionApi } from "api/requestSessionApi";
import { ToastContainer, toast } from "react-toastify";
import TimezoneSelect from "elements/TimezoneSelect";
import { useSelector } from "react-redux";
import { getCurrentTimeZone } from "helpers/common_helper";

const RequestSessionModal = ({ userID }) => {
  const [languageList, setLanguageList] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [interestList, setInterestList] = useState([]);
  const [selectedInterest, setSelectedInterest] = useState([]);
  const [selectedPreferredDate, setSelectedPreferredDate] = useState(null);
  const [selectedOptionalDate, setSelectedOptionalDate] = useState(null);
  const [selectedStartTime, setSelectedStartTime] = useState(null);
  const [selectedEndTime, setSelectedEndTime] = useState(null);
  const [selectedTimezone, setSelectedTimezone] = useState([]);
  const [timezoneList, setTimezoneList] = useState([]);

  const profile = useSelector((state) => state.profile.profile);

  const [activeData, setActiveData] = useState({
    page: 1,
    limit: 10,
    search: "",
  });

  const sessionLanguages = [
    { value: "1", label: "English" },
    { value: "2", label: "Hindi" },
  ];

  const defaultTimezone = () => {
    const timezoneId = profile?.timezone_id;

    setSelectedTimezone({
      value: timezoneId,
      label: `System Default ${getCurrentTimeZone()}`,
    });
    setFieldValue("timezone_id", timezoneId);
  };

  const getLanguageList = (data) => {
    getLanguageApi()
      .then((res) => {
        setLanguageList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getInterestList = (data) => {
    getCategoriesApi()
      .then((res) => {
        setInterestList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formik = useFormik({
    initialValues: {
      toUserId: "",
      title: "",
      preferedDate: "",
      optionalDate: "",
      start_time: "",
      end_time: "",
      description: "",
      categoryId: "",
      languageId: "",
      timezone_id: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Session Title is required"),
      languageId: Yup.string().required("Language is required"),
      categoryId: Yup.string().required("Category is required"),
      preferedDate: Yup.date()
        .min(new Date(), "Date must be greater than today").required("Date is required"),
      optionalDate: Yup.date().min(
        Yup.ref("preferedDate"),
        "Date must be greater than preferred date"
      ).required("Optional date is required"),
      start_time: Yup.string().test(
        "start_time",
        "Start time must be less than end time",
        function (value) {
          return value < this.parent.end_time;
        }
      ).required("Start Time is required"),
      end_time: Yup.string().test(
        "end_time",
        "End time must be greater than start time",
        function (value) {
          return value > this.parent.start_time;
        }
      ),
      description: Yup.string().required("Session details is required"),
      timezone_id: Yup.string().required("Timezone is required"),
    }),
    onSubmit: (values) => {
      

      let data = new FormData();
      data.append("toUserId", userID);
      data.append("title", values.title);
      data.append("preferedDate", values.preferedDate);
      data.append("optionalDate", values.optionalDate);
      data.append("start_time", values.start_time);
      data.append("end_time", values.end_time);
      data.append("description", values.description);
      data.append("categoryId", values.categoryId);
      data.append("languageId", values.languageId);
      data.append("timezone_id", values.timezone_id);

      

      postRequestSessionApi(data)
        .then((res) => {
          console.log(res?.data);
          if (res?.data?.success) {
            toast.success(res?.data?.message, {
              position: "top-right",
              autoClose: 700,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            formik.resetForm();
            setSelectedPreferredDate(null);
            setSelectedOptionalDate(null);
            setSelectedStartTime(null);
            setSelectedEndTime(null);
            setSelectedLanguage([]);
            setSelectedInterest([]);
            setTimeout(() => {
            window.$("#requestSession").modal("hide");
            }, 2500);
          }else {
            toast.error(res?.data?.message, {
              position: "top-right",
              autoClose: 700,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = formik;

  const handleClearAll = () => {
    formik.resetForm();
  };

  useEffect(() => {
    window.$("#requestSession").on("shown.bs.modal", function () {
      getLanguageList(activeData);
      getInterestList(activeData);
      defaultTimezone();
    });
  }, []);

  const handleSelectSearch = (selectedOption) => {
    const selectedValue = selectedOption?.value;
    setSelectedTimezone(selectedOption);
    setFieldValue("timezone_id", selectedValue);
  };

  useEffect(() => {
    setFieldValue("toUserId", userID);
  }, [userID]);
  

  return (
    <div className="modal fade newmodal" id="requestSession">
      <div className="modal-dialog modal-md modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header text-center">
            <button type="button" className="btn-close" data-bs-dismiss="modal">
              <img src={CloseIcon} alt="" width="" height="" />
            </button>
            <h4 className="text-center">Request Session</h4>
          </div>
          <div className="modal-body px-5 py-5">
            <form>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-outline mb-4">
                    <label className="form-label mb-0" htmlFor="">
                      Session Title
                    </label>
                    <input
                      name="title"
                      type="text"
                      id=""
                      className="form-control"
                      placeholder="Session Title"
                      value={values.title || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                    />
                    {errors.title && touched.title && (
                      <div className="text-danger">{errors.title}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-outline mb-4">
                    <label className="form-label mb-0" htmlFor="">
                      Language for the session (Choose from list)
                    </label>

                    <Select
                      name="languageId"
                      options={languageList?.map((item) => {
                        return {
                          value: item.id,
                          label: item.name,
                        };
                      })}
                      className=""
                      onChange={(selectedOption) => {
                        setFieldValue("languageId", selectedOption?.value);

                        setSelectedLanguage(selectedOption);
                      }}
                      onBlur={handleBlur}
                      value={selectedLanguage || []}
                    />
                    {errors.languageId && touched.languageId && (
                      <div className="text-danger">{errors.languageId}</div>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-outline mb-4">
                    <label className="form-label mb-0" htmlFor="">
                      Category for the session (Choose from list)
                    </label>

                    <Select
                      name="categoryId"
                      options={interestList?.map((item) => {
                        return {
                          value: item.id,
                          label: item.name,
                        };
                      })}
                      className=""
                      onChange={(selectedOption) => {
                        setFieldValue("categoryId", selectedOption?.value);

                        setSelectedInterest(selectedOption);
                      }}
                      onBlur={handleBlur}
                      value={selectedInterest || []}
                    />
                    {errors.categoryId && touched.categoryId && (
                      <div className="text-danger">{errors.categoryId}</div>
                    )}
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-outline mb-4">
                    <label className="form-label mb-0" htmlFor="">
                      Preferred Date
                    </label>
                    <div className="ui calendar datepicker">
                      <div className="d-flex align-items-center right icon">
                        <DatePicker
                          name="preferedDate"
                          className="form-control"
                          selected={selectedPreferredDate || ""}
                          onChange={(date) => {
                            const formattedDate =
                              moment(date).format("YYYY-MM-DD");
                            setFieldValue("preferedDate", formattedDate);
                            setSelectedPreferredDate(date);
                          }}
                          placeholderText="Select Date"
                          dateFormat="MMMM d, yyyy"
                        />
                        <i className="fa fa-calendar"></i>
                        {errors.preferedDate && touched.preferedDate && (
                          <div className="text-danger" style={{ position:'absolute', marginTop: '5rem'}}>
                            {errors.preferedDate}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-outline mb-4">
                    <label className="form-label mb-0" htmlFor="">
                      Optional Date
                    </label>
                    <div className="ui calendar datepicker">
                      <div className="d-flex align-items-center right icon">
                        <DatePicker
                          name="optionalDate"
                          className="form-control"
                          selected={selectedOptionalDate || ""}
                          onChange={(date) => {
                            const formattedDate =
                              moment(date).format("YYYY-MM-DD");
                            setFieldValue("optionalDate", formattedDate);
                            setSelectedOptionalDate(date);
                          }}
                          placeholderText="Select Date"
                          dateFormat="MMMM d, yyyy"
                        />
                        <i className="fa fa-calendar"></i>
                        {errors.optionalDate && touched.optionalDate && (
                          <div className="text-danger" style={{ position:'absolute', marginTop: '5rem'}}>
                            {errors.optionalDate}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-outline mb-4">
                    <label className="form-label mb-0" htmlFor="">
                      Start Time
                    </label>
                    <div className="ui calendar timepicker">
                      <div className="d-flex align-items-center time right icon">
                        <DatePicker
                          name="start_time"
                          className="form-control"
                          selected={selectedStartTime || ""}
                          onChange={(date) => {
                            const formattedTime =
                              moment(date).format("HH:mm:ss");
                            setFieldValue("start_time", formattedTime);
                            setSelectedStartTime(date);
                          }}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          dateFormat="h:mm aa"
                          placeholderText="Select Time"
                        />
                        <i className="fa fa-clock-o"></i>
                        {errors.start_time && touched.start_time && (
                          <div className="text-danger" style={{ position:'absolute', marginTop: '5rem'}}>{errors.start_time}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-outline mb-4">
                    <label className="form-label mb-0" htmlFor="">
                      End Time
                    </label>
                    <div className="ui calendar timepicker">
                      <div className="d-flex align-items-center time right icon">
                        <DatePicker
                          name="end_time"
                          className="form-control"
                          selected={selectedEndTime || ""}
                          onChange={(date) => {
                            const formattedTime =
                              moment(date).format("HH:mm:ss");
                            setFieldValue("end_time", formattedTime);
                            setSelectedEndTime(date);
                          }}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          dateFormat="h:mm aa"
                          placeholderText="Select Time"
                        />
                        <i className="fa fa-clock-o"></i>
                        {errors.end_time && touched.end_time && (
                          <div className="text-danger" style={{ position:'absolute', marginTop: '5rem'}}>{errors.end_time}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                    <div className="form-outline mb-4">
                      <label className="form-label mb-0" htmlFor="">
                        Select Time Zone
                      </label>
                      
                      <TimezoneSelect
                        timezoneList={timezoneList}
                        handleSelectTimezone={handleSelectSearch}
                        selectedTimezone={selectedTimezone || []}
                      />
                      {errors.timezone_id && touched.timezone_id && (
                        <div className="text-danger">{errors.timezone_id}</div>
                      )}
                    </div>
                  </div>

                <div className="col-md-12">
                  <div className="form-outline mb-4">
                    <label className="form-label mb-0 mt-2" htmlFor="">
                      Session Details
                    </label>
                    <textarea
                      className="form-control"
                      name="description"
                      value={values.description || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                    ></textarea>
                    {errors.description && touched.description && (
                      <div className="text-danger">{errors.description}</div>
                    )}
                  </div>
                </div>

                <a
                  onClick={handleSubmit}
                  className="btn btn-login btn-lg btn-block w-100 btn-submit"
                >
                  Submit
                </a>
              </div>
            </form>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default RequestSessionModal;
