const initialState = {
    isDashboardApiTriggered: false,
  };
  
  const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_DASHBOARD_API_TRIGGERED":
        return { ...state, isDashboardApiTriggered: action.payload };
      default:
        return state;
    }
  };
  
  export default dashboardReducer;